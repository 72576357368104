<div appPanel (closePanelEmitter)="close()"></div>

<div class="modal_content -large">
  <button class="mat-modal-closer" mat-dialog-close>
    <svg-icon
      src="assets/images/svg-icons/icon-times-light.svg"
      [svgStyle]="{ 'width.px': '18' }"
    ></svg-icon>
  </button>

  <div class="modal-inside">
    <div class="modal-header">
      <div class="modal-header-title">
        <svg-icon
          src="assets/images/svg-icons/icon-challenge.svg"
          [svgStyle]="{ 'width.px': '32' }"
        ></svg-icon>
        <span [innerHTML]="'challenge.lance' | translate"></span>
      </div>
    </div>

    <mat-horizontal-stepper [linear]="true">
      <mat-step [stepControl]="challengeFormStep1">
        <div class="modal-subtitle">
          {{ 'challenge.parametre' | translate }}
        </div>

        <div class="block-scrollable-list">
          <div class="scrollable_content">
            <div class="list-grid">
              <ng-container *ngFor="let specialty of filteredSpecialties">
                <app-discipline-challenge
                  [specialty]="specialty"
                  (click)="toggleDisciplineSelected(specialty)"
                  [selected]="specialty.id == challengeFormStep1.value.specialty?.id"
                ></app-discipline-challenge>
              </ng-container>
            </div>

            <div
              *ngIf="filteredSpecialties === undefined"
              class="h-[300px] flex justify-center items-center"
            >
              <app-loader-initial [smallLoader]="true"></app-loader-initial>
            </div>
          </div>
        </div>

        <div class="block--friends-list-search mt-8">
          <div class="row-form-nnki -nowrap">
            <mat-form-field [floatLabel]="'auto'" class="form-field-nnki width100">
              <mat-label>{{ 'rechercher.matiere' | translate }}</mat-label>
              <input matInput type="text" [formControl]="specialtySearchControl" />
            </mat-form-field>
            <button
              matRipple
              [matRippleCentered]="false"
              [matRippleUnbounded]="true"
              [matRippleRadius]="50"
              class="btn-nnki btn-nnki-primary btn-nnki-icon"
            >
              <svg-icon
                src="assets/images/svg-icons/icon-search-bold.svg"
                [svgStyle]="{ 'width.px': '20' }"
              ></svg-icon>
            </button>
          </div>
        </div>

        <form class="form-nnki" [formGroup]="challengeFormStep1" #ngForm="ngForm">
          <div *ngIf="!challengeFormStep1.valid && ngForm.submitted" class="error-tooltip">
            {{ 'transversal.matiere' | translate }}
          </div>

          <div class="modal-buttons">
            <button
              matRipple
              [matRippleCentered]="false"
              [matRippleUnbounded]="true"
              [matRippleRadius]="50"
              matStepperNext
              class="btn-nnki btn-nnki-primary btn-nnki-uppercase"
            >
              {{ 'reset.suivant' | translate }}
            </button>
          </div>

          <div class="h-[60px] sm:hidden"></div>
        </form>
      </mat-step>

      <mat-step [stepControl]="challengeFormStep2">
        <div class="modal-subtitle">
          {{ 'challenge.qui' | translate }}
        </div>

        <app-choose-friends
          [usersSelected]="modalData?.usersSelected"
          [scrollableListHeight]="'h-[calc(100vh-430px)]'"
        ></app-choose-friends>

        <form
          class="form-nnki"
          [formGroup]="challengeFormStep2"
          (ngSubmit)="createChallenge()"
          #ngForm2="ngForm"
        >
          <div *ngIf="!challengeFormStep2.valid && ngForm2.submitted" class="error-tooltip">
            {{ 'challenge.aumoinsun' | translate }}
          </div>

          <div class="modal-buttons">
            <button
              matRipple
              [matRippleCentered]="false"
              [matRippleUnbounded]="true"
              [matRippleRadius]="50"
              class="btn-nnki btn-nnki-primary btn-nnki-uppercase"
              *ngIf="!isCreating"
            >
              {{ 'challenge.lancer' | translate }}
            </button>

            <div class="spinner_100_container" *ngIf="isCreating">
              <app-loader-dots></app-loader-dots>
            </div>
          </div>

          <div class="h-[60px] sm:hidden"></div>
        </form>
      </mat-step>
    </mat-horizontal-stepper>
  </div>
</div>
