import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import {
  APP_INITIALIZER,
  LOCALE_ID,
  ModuleWithProviders,
  NgModule,
  Optional,
  SkipSelf,
} from '@angular/core';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { ToastrModule } from 'ngx-toastr';

import { environment } from '../../environments/environment';

import { AuthInterceptor } from './interceptors/auth.interceptor';
import { StartupService } from './services/startup.service';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { RouterState, StoreRouterConnectingModule } from '@ngrx/router-store';
import { metaReducers, reducers } from './state/core.reducers';
import { EffectsModule } from '@ngrx/effects';
import { AuthEffects } from './state/auth-state/auth.effects';
import { GlobalEntitiesEffects } from './state/global-entities-state/global-entities.effects';
import { SetHeadersInterceptor } from './interceptors/set-headers.interceptor';
import { LottieModule } from 'ngx-lottie';

function initConfiguration(startupService: StartupService) {
  return () => startupService.load();
}

export function playerFactory() {
  return import(/* webpackChunkName: 'lottie-web' */ 'lottie-web');
}

@NgModule({
  imports: [
    HttpClientModule,
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-right',
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    AngularSvgIconModule.forRoot(),
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
        strictActionSerializability: true,
        strictStateSerializability: true,
      },
    }),
    EffectsModule.forRoot([AuthEffects, GlobalEntitiesEffects]),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production }),
    StoreRouterConnectingModule.forRoot({
      stateKey: 'router',
      routerState: RouterState.Minimal,
    }),
    LottieModule.forRoot({ player: playerFactory }),
  ],
  declarations: [],
  providers: [
    StartupService,
    {
      provide: APP_INITIALIZER,
      useFactory: initConfiguration,
      multi: true,
      deps: [StartupService],
    },
    // nnkitodo [v2later] voir si ceci est vraiment utile et à quoi ça sert
    {
      provide: LOCALE_ID,
      useValue: environment.locale,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SetHeadersInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
  ],
})

// nnkitodo [v2later] voir si ceci est vraiment utile et à quoi ça sert
export class CoreModule {
  constructor(@Optional() @SkipSelf() private parent: CoreModule) {
    if (parent) {
      throw new Error('CoreModule is already loaded. Import it in the AppModule only');
    }
  }

  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [],
    };
  }
}

// nnkitodo [v2later] supprimer quand plus de traduction
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}
