import moment from 'moment';
import { environment } from '../../../../environments/environment';
import { API_DATETIME_FORMAT } from '../../constants/date-format';
import { ExamContentSessionResult } from '../api-results/exam-content-session.api-result';
import { SessionStatus } from '../enums/session.enum';
import { ExamContent, ExamContentStatistic } from './exam-content.entity';
import { FolderSession } from './folder-session.entity';
import { IsolatedQuestionsSession } from './isolated-questions-session.entity';
import { GlobalSession } from '../interfaces/global-session.interface';
import { Exam } from './exam.entity';
import { ExamSession } from './exam-session.entity';
import { ExamContentStatisticResult } from '../api-results/exam-content.api-result';
import { RankedSession } from '../interfaces/ranked-session.interface';
import { EcosSession } from './ecos-session.entity';

export class ExamContentSession implements GlobalSession, RankedSession {
  id: string;

  createdAt: Date;
  closedAt: Date;
  status: SessionStatus;
  isFirstUserSession: boolean;

  grade: number;
  rank: number;
  universityCityRank: number;
  universityRank: number;
  graduationNameRank: number;

  totalParticipantsInStudentGraduationName: number;
  totalParticipantsInStudentUniversity: number;
  totalParticipantsInStudentUniversityCity: number;

  timerActivatedAt: Date;
  initialTimerDuration: number;
  remainingTime: number;

  examContent: ExamContent;

  examSession: ExamSession;
  folderSessions: FolderSession[];
  isolatedQuestionsSession: IsolatedQuestionsSession;
  ecosSession: EcosSession;

  constructor(result: ExamContentSessionResult) {
    this.id = result.id;

    if (result.createdAt) this.createdAt = new Date(result.createdAt);
    if (result.closedAt) this.closedAt = new Date(result.closedAt);
    this.status = result.status;
    this.isFirstUserSession = result.isFirstUserSession;

    this.grade = result.grade;
    this.rank = result.myRank ? result.myRank : result.studentRank;
    this.universityCityRank = result.myUniversityCityRank
      ? result.myUniversityCityRank
      : result.studentUniversityCityRank;
    this.universityRank = result.myUniversityRank
      ? result.myUniversityRank
      : result.studentUniversityRank;
    this.graduationNameRank = result.myGraduationNameRank
      ? result.myGraduationNameRank
      : result.studentGraduationNameRank;

    this.totalParticipantsInStudentGraduationName = result.totalParticipantsInStudentGraduationName;
    this.totalParticipantsInStudentUniversity = result.totalParticipantsInStudentUniversity;
    this.totalParticipantsInStudentUniversityCity = result.totalParticipantsInStudentUniversityCity;

    if (result.timerActivatedAt) this.timerActivatedAt = new Date(result.timerActivatedAt);
    this.initialTimerDuration = result.initialTimerDuration;
    this.remainingTime = result.remainingTime;

    if (result.examContent) this.examContent = new ExamContent(result.examContent);

    if (result.examSession) this.examSession = new ExamSession(result.examSession);
    if (result.folderSessions) {
      this.folderSessions = result.folderSessions
        .map((elt) => new FolderSession(elt))
        .sort((a, b) => (a.folder.priority < b.folder.priority ? -1 : 1));

      this.folderSessions.forEach((folderSession) => {
        folderSession.setIndex(this);
      });
    }

    if (result.isolatedQuestionsSession)
      this.isolatedQuestionsSession = new IsolatedQuestionsSession(result.isolatedQuestionsSession);

    if (result.ecosSession) this.ecosSession = new EcosSession(result.ecosSession, this.id);

    if (!this.initialTimerDuration) {
      this.initialTimerDuration = this.remainingTime;
    }
  }

  toResult(): ExamContentSessionResult {
    return {
      id: this.id,
      createdAt: moment(this.createdAt).format(API_DATETIME_FORMAT),
      closedAt: moment(this.closedAt).format(API_DATETIME_FORMAT),
      status: this.status,
      isFirstUserSession: this.isFirstUserSession,
      grade: this.grade,
      studentRank: this.rank,
      myRank: this.rank,
      myUniversityCityRank: this.universityCityRank,
      studentUniversityCityRank: this.universityCityRank,
      myUniversityRank: this.universityRank,
      studentUniversityRank: this.universityRank,
      myGraduationNameRank: this.graduationNameRank,
      studentGraduationNameRank: this.graduationNameRank,
      totalParticipantsInStudentGraduationName: this.totalParticipantsInStudentGraduationName,
      totalParticipantsInStudentUniversity: this.totalParticipantsInStudentUniversity,
      totalParticipantsInStudentUniversityCity: this.totalParticipantsInStudentUniversityCity,
      timerActivatedAt: moment(this.timerActivatedAt).format(API_DATETIME_FORMAT),
      initialTimerDuration: this.initialTimerDuration,
      remainingTime: this.remainingTime,
      examContent: this.examContent?.toResult(),
      examSession: this.examSession?.toResult(),
      folderSessions: this.folderSessions?.map((elt) => elt.toResult()),
      isolatedQuestionsSession: this.isolatedQuestionsSession?.toResult(),
      ecosSession: this.ecosSession?.toResult(),
    };
  }

  get questionsSession() {
    return this.isolatedQuestionsSession;
  }

  get student() {
    return this.examSession.student;
  }

  get graduationName() {
    return this.examSession.graduationName;
  }

  get university() {
    return this.examSession.university;
  }
}
