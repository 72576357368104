import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { timer, BehaviorSubject, Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import * as AuthSelectors from '../state/auth-state/auth.selectors';
import { HttpConversationService } from './http/http-conversation.service';
import { Student } from '../../shared/models/entities/student.entity';
import { Conversation } from '../../shared/models/entities/conversation.entity';

@Injectable({
  providedIn: 'root',
})
export class ConversationService {
  checkTimeSubscription: Subscription;
  totalUnreadConversationMessages$ = new BehaviorSubject<number>(null);

  hideMobileNewConversationButton$ = new BehaviorSubject<boolean>(false);
  newConversation$ = new BehaviorSubject<Conversation>(null);

  constructor(
    private router: Router,
    private store: Store,
    private httpConversationService: HttpConversationService
  ) {}

  init() {
    this.store.select(AuthSelectors.isLogged).subscribe((isLogged) => {
      if (isLogged) {
        this.checkTimeSubscription = timer(0, 5 * 60 * 1000).subscribe(() => {
          this.updateMessagesUnread();
        });
      } else {
        if (this.checkTimeSubscription) {
          this.checkTimeSubscription.unsubscribe();
        }
        this.totalUnreadConversationMessages$.next(null);
      }
    });
  }

  updateMessagesUnread() {
    this.httpConversationService.countTotalUnreadConversationMessages().subscribe((res) => {
      this.totalUnreadConversationMessages$.next(res.totalUnreadConversationMessages);
    });
  }

  createTemporaryConversation(users: Student[]) {
    this.newConversation$.next(
      new Conversation({
        id: undefined,
        members: users.map((user) => user.toResult()),
        createdBy: undefined,
        createdAt: undefined,
        lastConversationMessage: undefined,
        totalUnreadConversationMessages: 0,
      })
    );
    this.router.navigate(['/app/mailbox/messaging']);
  }

  createTemporaryConversationWithoutLoggedStudent(students: Student[]) {
    this.store
      .select(AuthSelectors.loggedStudent)
      .pipe(first())
      .subscribe((loggedStudent) => {
        this.createTemporaryConversation(
          students.filter((student) => student.id !== loggedStudent.id)
        );
      });
  }
}
