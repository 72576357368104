import { Store } from '@ngrx/store';
import { Student } from '../../shared/models/entities/student.entity';
import { EventEmitter, Injectable } from '@angular/core';
import * as AuthSelectors from '../state/auth-state/auth.selectors';
import { finalize, first, of } from 'rxjs';
import { HttpFollowService } from './http/http-follow.service';
import {
  FollowPayload,
  HandleFollowRequestPayload,
} from '../../shared/models/payloads/follow.payload';
import { ErrorService } from './error.service';
import { FollowStatus } from '../../shared/models/enums/follow.enum';
import { Follow, FollowedStudent } from '../../shared/models/entities/follow.entity';

@Injectable({
  providedIn: 'root',
})
export class FollowService {
  reloadFollowNumbers = new EventEmitter();

  constructor(
    private store: Store,
    private errorService: ErrorService,
    private httpFollowService: HttpFollowService
  ) {}

  follow(student: Student) {
    this.store
      .select(AuthSelectors.loggedStudent)
      .pipe(first())
      .subscribe((loggedStudent) => {
        if (loggedStudent.id !== student.id) {
          student.followIsLoading = true;
          this.httpFollowService
            .follow(new FollowPayload(student.id))
            .pipe(
              finalize(() => {
                student.followIsLoading = false;
              })
            )
            .subscribe({
              next: (result) => {
                student.followedByMe = new Follow(result);
                this.reloadFollowNumbers.emit();
              },
              error: (error) => {
                this.errorService.toastError(error);
              },
            });
        }
      });
  }

  unfollow(student: Student) {
    this.store
      .select(AuthSelectors.loggedStudent)
      .pipe(first())
      .subscribe((loggedStudent) => {
        if (loggedStudent.id !== student.id) {
          student.followIsLoading = true;
          this.httpFollowService
            .unfollow(new FollowPayload(student.id))
            .pipe(
              finalize(() => {
                student.followIsLoading = false;
              })
            )
            .subscribe({
              next: () => {
                student.followedByMe = null;
                this.reloadFollowNumbers.emit();
              },
              error: (error) => {
                this.errorService.toastError(error);
              },
            });
        }
      });
  }

  deleteFollow(student: Student) {
    this.store
      .select(AuthSelectors.loggedStudent)
      .pipe(first())
      .subscribe((loggedStudent) => {
        if (loggedStudent.id !== student.id) {
          student.followIsLoading = true;
          this.httpFollowService
            .rejectFollowRequest(new HandleFollowRequestPayload(student.id))
            .pipe(
              finalize(() => {
                student.followIsLoading = false;
              })
            )
            .subscribe({
              next: () => {
                student.followingMe = new Follow({
                  status: FollowStatus.DECLINED,
                });
                this.reloadFollowNumbers.emit();
              },
              error: (error) => {
                this.errorService.toastError(error);
              },
            });
        }
      });
  }

  approveFollowRequest(student: Student) {
    this.store
      .select(AuthSelectors.loggedStudent)
      .pipe(first())
      .subscribe((loggedStudent) => {
        if (loggedStudent.id !== student.id) {
          student.followIsLoading = true;
          this.httpFollowService
            .approveFollowRequest(new HandleFollowRequestPayload(student.id))
            .pipe(
              finalize(() => {
                student.followIsLoading = false;
              })
            )
            .subscribe({
              next: () => {
                student.followingMe = new Follow({
                  status: FollowStatus.APPROVED,
                });
                this.reloadFollowNumbers.emit();
              },
              error: (error) => {
                this.errorService.toastError(error);
              },
            });
        }
      });
  }

  rejectFollowRequest(student: Student) {
    this.store
      .select(AuthSelectors.loggedStudent)
      .pipe(first())
      .subscribe((loggedStudent) => {
        if (loggedStudent.id !== student.id) {
          student.followIsLoading = true;
          this.httpFollowService
            .rejectFollowRequest(new HandleFollowRequestPayload(student.id))
            .pipe(
              finalize(() => {
                student.followIsLoading = false;
              })
            )
            .subscribe({
              next: () => {
                student.followingMe = new Follow({
                  status: FollowStatus.DECLINED,
                });
                this.reloadFollowNumbers.emit();
              },
              error: (error) => {
                this.errorService.toastError(error);
              },
            });
        }
      });
  }
}
