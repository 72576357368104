import { environment } from '../../../../environments/environment';
import { API_DATETIME_FORMAT } from '../../constants/date-format';
import { CourseResult, CourseStatisticResult } from '../api-results/course.api-result';
import { CourseContentType } from '../enums/course-content.enums';
import { CoursePublicationStatus } from '../enums/publication-status.enums';
import { CourseFormValue, UpdateCoursePayload } from '../payloads/course.payload';
import { Chapter, ChapterFrontEnd } from './chapter.entity';
import { CourseContent } from './course-content.entity';
import { CoursePicture } from './course-picture.entity';
import { Rate } from './rate.entity';
import { Specialty } from './specialty.entity';
import { Writer } from './writer.entity';
import moment from 'moment';

export class Course {
  id: string;
  title: string;
  ecniNumber: number;

  author: Writer;
  createdAt: Date;
  updatedBy: Writer;
  updatedAt: Date;

  publicationStatus: CoursePublicationStatus;
  specialties: Specialty[];

  appearanceYears: number[];
  modificationReason: string;

  averageRating: number;

  lessonContents: CourseContent[];
  mindmapContents: CourseContent[];
  pictures: CoursePicture[];

  previousCourse: Course;
  nextCourse: Course;

  courseStatistic: CourseStatistic;

  // --- STUDENT ONLY ---

  lastCourseContentCreatedAt: Date;
  hasNewCourseContent: boolean;

  totalExamContentCourse: number;
  totalCourseContents: number;
  isBookmarked: boolean;
  isAssimilated: boolean;

  studentNote: string;
  studentRate: Rate;

  totalAnsweredQuestions: number;
  successRate: number;
  totalDuration: number;

  totalAnchoredQuestions: number;
  totalAnchoringInProgressQuestions: number;
  totalRejectedQuestions: number;

  totalCorrectlyAnsweredQuestions: number;
  totalPartiallyCorrectlyAnsweredQuestions: number;
  totalWronglyAnsweredQuestions: number;

  // -- FRONTEND ONLY ---
  chapters: ChapterFrontEnd[];

  constructor(result: CourseResult) {
    this.id = result.id;
    this.title = result.title;
    this.ecniNumber = result.ecniNumber;

    if (result.author) this.author = new Writer(result.author);
    if (result.createdAt) this.createdAt = new Date(result.createdAt);
    if (result.updatedBy) this.updatedBy = new Writer(result.updatedBy);
    if (result.updatedAt) this.updatedAt = new Date(result.updatedAt);

    this.publicationStatus = result.publicationStatus;
    if (result.specialties) {
      this.specialties = result.specialties.map((elt) => new Specialty(elt));
    }
    this.appearanceYears = result.appearanceYears;
    this.modificationReason = result.modificationReason;

    this.averageRating = result.averageRating;

    if (!result.contents) result.contents = [];
    if (result.contents) {
      this.lessonContents = result.contents
        .filter((elt) => elt.type === CourseContentType.LESSON)
        .map((elt) => new CourseContent(elt))
        .sort((a, b) => {
          if (a.priority === b.priority) return b.year - a.year;
          else return a.priority - b.priority;
        });

      this.mindmapContents = result.contents
        .filter((elt) => elt.type === CourseContentType.MINDMAP)
        .map((elt) => new CourseContent(elt))
        .sort((a, b) => a.priority - b.priority);
    }
    if (result.pictures) {
      this.pictures = result.pictures.map((elt) => new CoursePicture(elt));
    }

    if (result.previousCourse) {
      this.previousCourse = new Course(result.previousCourse);
    }
    if (result.nextCourse) {
      this.nextCourse = new Course(result.nextCourse);
    }

    if (result.courseStatistic) {
      this.courseStatistic = new CourseStatistic(result.courseStatistic);
    }

    if (result.lastCourseContentCreatedAt) {
      this.lastCourseContentCreatedAt = new Date(result.lastCourseContentCreatedAt);
      // nnkitodo [v2later date] plus réactif
      const sixMonthsAgo = new Date();
      sixMonthsAgo.setMonth(sixMonthsAgo.getMonth() - 6);
      this.hasNewCourseContent = this.lastCourseContentCreatedAt > sixMonthsAgo;
    } else if (result.contents?.length) {
      this.lastCourseContentCreatedAt = new Date(
        Math.max(
          ...this.lessonContents.map((content) => content.createdAt?.getTime()),
          ...this.mindmapContents.map((content) => content.createdAt?.getTime())
        )
      );
    } else {
      this.lastCourseContentCreatedAt = this.updatedAt ? this.updatedAt : this.createdAt;
    }

    this.totalExamContentCourse = result.totalExamContentCourse;
    this.totalCourseContents = result.totalCourseContents;
    this.isBookmarked = result.isBookmarked;
    this.isAssimilated = result.isAssimilated;
    this.studentNote = result.studentNote?.content;
    if (result.studentRate) {
      this.studentRate = new Rate(result.studentRate);
    }

    if (result.totalAnsweredQuestions !== undefined) {
      this.totalAnsweredQuestions = result.totalAnsweredQuestions;
    } else if (
      result.totalCorrectlyAnsweredQuestions !== undefined &&
      result.totalPartiallyCorrectlyAnsweredQuestions !== undefined &&
      result.totalWronglyAnsweredQuestions !== undefined
    ) {
      this.totalAnsweredQuestions =
        result.totalCorrectlyAnsweredQuestions +
        result.totalPartiallyCorrectlyAnsweredQuestions +
        result.totalWronglyAnsweredQuestions;
    }
    this.successRate = Math.max(result.successRate, 0);
    this.totalDuration = result.totalDuration;

    this.totalAnchoredQuestions = result.totalAnchoredQuestions;
    this.totalAnchoringInProgressQuestions = result.totalAnchoringInProgressQuestions;
    this.totalRejectedQuestions = result.totalRejectedQuestions;

    this.totalCorrectlyAnsweredQuestions = result.totalCorrectlyAnsweredQuestions;
    this.totalPartiallyCorrectlyAnsweredQuestions = result.totalPartiallyCorrectlyAnsweredQuestions;
    this.totalWronglyAnsweredQuestions = result.totalWronglyAnsweredQuestions;
  }

  get isSDD() {
    return !!this.specialties?.find((specialty) => specialty.name === 'Sémiologie');
  }

  // nnkitodo [v2later] ajouter bool sur course ou créer entité spéciale pour sdd. Checker tous les endpoints qui renvoient des courses pour afficher la bonne info et faire ça plus proprement
  get displayPrefix() {
    if (this.isSDD) {
      return `SDD `;
    } else if (this.specialties) {
      return `Item `;
    } else {
      return `Item `;
    }
  }

  get displayName() {
    return `${this.displayPrefix}${this.ecniNumber} - ${this.title}`;
  }

  get displayNumber() {
    return `${this.displayPrefix}${this.ecniNumber}`;
  }

  get progress() {
    if (!this.courseStatistic?.totalIsolatedQuestions) return 0;
    return (this.totalAnsweredQuestions / this.courseStatistic?.totalIsolatedQuestions) * 100;
  }

  get totalTrainingQuestions() {
    return this.courseStatistic?.totalIsolatedQuestions;
  }

  get icon() {
    return this.specialties?.[0].icon;
  }

  get totalProcessedQuestions() {
    return (
      this.totalAnchoredQuestions +
      this.totalAnchoringInProgressQuestions +
      this.totalRejectedQuestions
    );
  }

  toFormValue(): CourseFormValue {
    return {
      ecniNumber: this.ecniNumber,
      title: this.title,
      specialties: this.specialties,
      appearanceYears: this.appearanceYears,
      modificationReason: this.modificationReason,
    };
  }

  toResult(): CourseResult {
    return {
      id: this.id,
      title: this.title,
      ecniNumber: this.ecniNumber,
      author: this.author?.toResult(),
      createdAt: moment(this.createdAt).format(API_DATETIME_FORMAT),
      updatedBy: this.updatedBy?.toResult(),
      updatedAt: moment(this.updatedAt).format(API_DATETIME_FORMAT),
      publicationStatus: this.publicationStatus,
      specialties: this.specialties?.map((elt) => elt.toResult()),
      appearanceYears: this.appearanceYears,
      modificationReason: this.modificationReason,
      averageRating: this.ecniNumber,
      contents: [
        ...(this.lessonContents ? this.lessonContents?.map((elt) => elt.toResult()) : []),
        ...(this.mindmapContents ? this.mindmapContents?.map((elt) => elt.toResult()) : []),
      ],
      pictures: this.pictures?.map((elt) => elt.toResult()),
      previousCourse: this.previousCourse?.toResult(),
      nextCourse: this.nextCourse?.toResult(),
      courseStatistic: this.courseStatistic?.toResult(),
      lastCourseContentCreatedAt: moment
        .utc(this.lastCourseContentCreatedAt)
        .format(API_DATETIME_FORMAT),
      totalExamContentCourse: this.totalExamContentCourse,
      totalCourseContents: this.totalCourseContents,
      isBookmarked: this.isBookmarked,
      isAssimilated: this.isAssimilated,
      studentNote: {
        content: this.studentNote,
      },
      studentRate: this.studentRate?.toResult(),
      totalAnsweredQuestions: this.totalAnsweredQuestions,
      successRate: this.successRate,
      totalDuration: this.totalDuration,
      totalAnchoredQuestions: this.totalAnchoredQuestions,
      totalAnchoringInProgressQuestions: this.totalAnchoringInProgressQuestions,
      totalRejectedQuestions: this.totalRejectedQuestions,
      totalCorrectlyAnsweredQuestions: this.totalCorrectlyAnsweredQuestions,
      totalPartiallyCorrectlyAnsweredQuestions: this.totalPartiallyCorrectlyAnsweredQuestions,
      totalWronglyAnsweredQuestions: this.totalWronglyAnsweredQuestions,
    };
  }

  static updatedEntityToResult(payload: UpdateCoursePayload): Partial<CourseResult> {
    return {
      title: payload.title,
      ecniNumber: payload.ecniNumber,
      appearanceYears: payload.appearanceYears,
      modificationReason: payload.modificationReason,
    };
  }
}

export class CourseStatistic {
  totalAnsweredFeedbacks: number;
  totalFeedbacks: number;
  totalFolders: number;
  totalIsolatedQuestions: number;
  totalViews: number;

  constructor(result: CourseStatisticResult) {
    this.totalAnsweredFeedbacks = result.totalAnsweredFeedbacks;
    this.totalFeedbacks = result.totalFeedbacks;
    this.totalFolders = result.totalFolders;
    this.totalIsolatedQuestions = result.totalIsolatedQuestions;
    this.totalViews = result.totalViews;
  }

  toResult(): CourseStatisticResult {
    return {
      totalAnsweredFeedbacks: this.totalAnsweredFeedbacks,
      totalFeedbacks: this.totalFeedbacks,
      totalFolders: this.totalFolders,
      totalIsolatedQuestions: this.totalIsolatedQuestions,
      totalViews: this.totalViews,
    };
  }
}
