import { environment } from '../../../../environments/environment';
import { API_DATETIME_FORMAT } from '../../constants/date-format';
import {
  ExamContentResult,
  ExamContentStatisticResult,
} from '../api-results/exam-content.api-result';
import { ExamDifficulty } from '../enums/exam.enums';
import { ExamContentFormValue, UpdateExamContentPayload } from '../payloads/exam-content.payload';
import { SpecialtyQueryData } from '../payloads/specialty.payload';
import { Course } from './course.entity';
import { ExamContentCourse } from './exam-content-course.entity';
import { ExamContentQuestion } from './exam-content-question.entity';
import { Exam } from './exam.entity';
import { Folder } from './folder.entity';
import { Specialty } from './specialty.entity';
import { Writer } from './writer.entity';
import moment from 'moment';
import { uniqBy } from 'lodash';
import { ExamContentSession } from './exam-content-session.entity';
import { SessionStatus } from '../enums/session.enum';
import { Rate } from './rate.entity';

export class ExamContent {
  id: string;
  title: string;
  priority: number;

  difficulty: ExamDifficulty;

  author: Writer;
  createdAt: Date;
  updatedBy: Writer;
  updatedAt: Date;

  timerDuration: number;

  mainSpecialty: Specialty;
  otherSpecialties: Specialty[];
  speakerWord: string;

  averageRating: number;

  totalIsolatedQuestions: number;
  totalFolderQuestions: number;
  maxGrade: number;
  isolatedQuestionsMaxGrade: number;
  ecosMaxGrade: number;

  isPaid: boolean;

  examContentCourse: ExamContentCourse;
  folders: Folder[];
  courses: Course[];

  exam: Exam;

  studentRate: Rate;

  examContentStatistic: ExamContentStatistic;

  // nnkitodo [v2later] eurk
  isSelected: boolean;

  examContentQuestions: ExamContentQuestion[];

  constructor(result: ExamContentResult) {
    this.id = result.id;
    this.title = result.title;
    this.priority = result.priority;

    this.difficulty = result.difficulty;

    if (result.author) this.author = new Writer(result.author);
    if (result.createdAt) this.createdAt = new Date(result.createdAt);
    if (result.updatedBy) this.updatedBy = new Writer(result.updatedBy);
    if (result.updatedAt) this.updatedAt = new Date(result.updatedAt);

    this.timerDuration = result.timerDuration;

    if (result.mainSpecialty) this.mainSpecialty = new Specialty(result.mainSpecialty);
    this.speakerWord = result.speakerWord;

    this.averageRating = result.averageRating;
    this.totalIsolatedQuestions = result.totalIsolatedQuestions;
    this.totalFolderQuestions = result.totalFolderQuestions;
    this.maxGrade = result.maxGrade;
    this.isolatedQuestionsMaxGrade = result.isolatedQuestionsMaxGrade;
    this.ecosMaxGrade = result.ecosMaxGrade;

    this.isPaid = result.isPaid;

    if (result.examContentCourse)
      this.examContentCourse = new ExamContentCourse(result.examContentCourse);
    if (result.folders)
      this.folders = result.folders
        .map((elt) => new Folder(elt))
        .sort((a, b) => (a.priority > b.priority ? 1 : -1));
    if (result.courses) this.courses = result.courses.map((elt) => new Course(elt));

    if (result.exam) this.exam = new Exam(result.exam);
    if (result.studentRate) this.studentRate = new Rate(result.studentRate);

    if (result.examContentStatistic) {
      this.examContentStatistic = new ExamContentStatistic(result.examContentStatistic);
    }

    this.isSelected = result.isSelected;

    if (result.examContentQuestions) {
      this.examContentQuestions = result.examContentQuestions
        .map((elt) => new ExamContentQuestion(elt))
        .sort((a, b) => (a.priority > b.priority ? 1 : -1));
      this.examContentQuestions.forEach(
        (examContentQuestion, index) => (examContentQuestion.index = index)
      );

      if (this.courses === undefined) {
        this.courses = [];
        this.examContentQuestions.forEach((examContentQuestion) => {
          examContentQuestion.question.notions.forEach((notion) => {
            if (notion.course) {
              this.courses.push(notion.course);
            }
          });
        });
      }
    }

    if (this.courses) {
      this.courses = uniqBy(this.courses, 'id').sort((a: Course, b: Course) =>
        a.ecniNumber > b.ecniNumber ? 1 : -1
      );

      this.otherSpecialties = [];
      this.courses.forEach((course) => {
        course.specialties?.forEach((specialty) => {
          if (specialty.id !== this.mainSpecialty?.id) {
            this.otherSpecialties.push(specialty);
          }
        });
      });
      this.otherSpecialties = uniqBy(this.otherSpecialties, 'id').sort(
        (a: Specialty, b: Specialty) => (a.name > b.name ? 1 : -1)
      );
    }
  }

  // nnkitodo [v2later] centraliser avec le code de l'exam
  get difficultyIcon() {
    if (this.difficulty === ExamDifficulty.EASY) return 'icon-classification-easy';
    if (this.difficulty === ExamDifficulty.MEDIUM) return 'icon-classification-moderate';
    if (this.difficulty === ExamDifficulty.DIFFICULT) return 'icon-classification-hard';
  }

  get specialties() {
    return [...(this.mainSpecialty ? [this.mainSpecialty] : []), ...this.otherSpecialties];
  }

  get lcaFiles() {
    return this.folders.map((folder) => folder.lca).filter((lca) => !!lca);
  }

  get statistic() {
    return this.examContentStatistic;
  }

  get icon() {
    if (this.mainSpecialty?.icon && !this.exam.type.icon) {
      return this.mainSpecialty.icon;
    } else {
      return this.exam.icon;
    }
  }

  toFormValue(): ExamContentFormValue {
    return {
      title: this.title,
      timerDurationInMinutes: this.timerDuration / 60,
      mainSpecialty: this.mainSpecialty,
      speakerWord: this.speakerWord,
      // nnkitodo [v2later] plus propre
      courses: undefined,
      courseVideo: undefined,
      correctionVideo: undefined,
      subject: undefined,
      instructions: undefined,
      picture: undefined,
      preparationTime: undefined,
      patientPictogram: undefined,
      patientName: undefined,
      patientAge: undefined,
      socioEconomicStatus: undefined,
      consultationReasons: undefined,
      totalActionsAuthorized: undefined,
    };
  }

  toResult(): ExamContentResult {
    return {
      id: this.id,
      title: this.title,
      priority: this.priority,
      difficulty: this.difficulty,
      author: this.author?.toResult(),
      createdAt: moment(this.createdAt).format(API_DATETIME_FORMAT),
      updatedBy: this.updatedBy?.toResult(),
      updatedAt: moment(this.updatedAt).format(API_DATETIME_FORMAT),
      timerDuration: this.timerDuration,
      mainSpecialty: this.mainSpecialty?.toResult(),
      speakerWord: this.speakerWord,
      averageRating: this.averageRating,
      totalIsolatedQuestions: this.totalIsolatedQuestions,
      totalFolderQuestions: this.totalFolderQuestions,
      maxGrade: this.maxGrade,
      isolatedQuestionsMaxGrade: this.isolatedQuestionsMaxGrade,
      ecosMaxGrade: this.ecosMaxGrade,
      isPaid: this.isPaid,
      examContentCourse: this.examContentCourse?.toResult(),
      folders: this.folders?.map((elt) => elt.toResult()),
      courses: this.courses?.map((elt) => elt.toResult()),
      exam: this.exam?.toResult(),
      studentRate: this.studentRate?.toResult(),
      examContentStatistic: this.examContentStatistic?.toResult(),
      isSelected: this.isSelected,
      examContentQuestions: this.examContentQuestions?.map((elt) => elt.toResult()),
    };
  }

  static updatedEntityToResult(
    payload: UpdateExamContentPayload,
    formValue: ExamContentFormValue
  ): Partial<ExamContentResult> {
    return {
      title: payload.title,
      timerDuration: payload.timerDuration,
      mainSpecialty: formValue.mainSpecialty?.toResult(),
      speakerWord: payload.speakerWord,
    };
  }
}

export class ExamContentStatistic {
  totalParticipants: number;

  constructor(result: ExamContentStatisticResult) {
    this.totalParticipants = result.totalParticipants;
  }

  toResult(): ExamContentStatisticResult {
    return {
      totalParticipants: this.totalParticipants,
    };
  }
}
