import { ValidatorFn, AbstractControl, ValidationErrors, FormGroup } from '@angular/forms';

export function dateAfterValidator(date: Date): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) return null;
    return control.value < date ? { dateNotAfter: { date } } : null;
  };
}

export function atLeastOneSupportValidator(): ValidatorFn {
  return (form: FormGroup): ValidationErrors | null => {
    const isWebsiteSupported: boolean = form.get('isWebsiteSupported').value;
    const isMailSupported: boolean = form.get('isMailSupported').value;
    const isPushSupported: boolean = form.get('isPushSupported').value;

    if (!isWebsiteSupported && !isMailSupported && !isPushSupported) {
      return { noSupport: true };
    }
    return null;
  };
}

export function confirmWithTextInputValidator(text: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (control.value.trim() !== text) return { textInputNotTheSame: true };
    return null;
  };
}

export function arrayMaxLength(maxLength: number): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (control.value?.length > maxLength) return { arrayMaxLength: maxLength };
    return null;
  };
}
