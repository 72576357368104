import { SpecialtyResult, UpdateSpecialtyIconResult } from '../api-results/specialty.api-result';
import { SpecialtyFormValue, UpdateSpecialtyPayload } from '../payloads/specialty.payload';
import { ChallengeSpecialtyScore } from './challenge-score.entity';

export class Specialty {
  id: string;
  name: string;
  icon: string;
  // nnkitodo [v2later] il faudrait scinder ce champ en 2 car pour l'instant c'est training+lessons, mais pour l'affichage des cards specialties on voudrait juste le count published on lessons
  totalCourses: number;
  totalCoursesIsolatedQuestions: number;

  challengeSpecialtyScores: ChallengeSpecialtyScore[];

  totalAnsweredQuestions: number;
  successRate: number;
  totalDuration: number;

  totalAnchoredQuestions: number;
  totalAnchoringInProgressQuestions: number;
  totalRejectedQuestions: number;

  totalCorrectlyAnsweredQuestions: number;
  totalPartiallyCorrectlyAnsweredQuestions: number;
  totalWronglyAnsweredQuestions: number;

  constructor(result: SpecialtyResult) {
    this.id = result.id;
    this.name = result.name;
    this.icon = result.icon;
    this.totalCourses = result.totalCourses;
    this.totalCoursesIsolatedQuestions = result.totalCoursesIsolatedQuestions;

    if (result.challengeSpecialtyScores)
      this.challengeSpecialtyScores = result.challengeSpecialtyScores.map(
        (elt) => new ChallengeSpecialtyScore(elt)
      );

    if (result.totalAnsweredQuestions !== undefined) {
      this.totalAnsweredQuestions = result.totalAnsweredQuestions;
    } else if (
      result.totalCorrectlyAnsweredQuestions !== undefined &&
      result.totalPartiallyCorrectlyAnsweredQuestions !== undefined &&
      result.totalWronglyAnsweredQuestions !== undefined
    ) {
      this.totalAnsweredQuestions =
        result.totalCorrectlyAnsweredQuestions +
        result.totalPartiallyCorrectlyAnsweredQuestions +
        result.totalWronglyAnsweredQuestions;
    }
    this.successRate = Math.max(result.successRate, 0);
    this.totalDuration = result.totalDuration;

    this.totalAnchoredQuestions = result.totalAnchoredQuestions;
    this.totalAnchoringInProgressQuestions = result.totalAnchoringInProgressQuestions;
    this.totalRejectedQuestions = result.totalRejectedQuestions;

    this.totalCorrectlyAnsweredQuestions = result.totalCorrectlyAnsweredQuestions;
    this.totalPartiallyCorrectlyAnsweredQuestions = result.totalPartiallyCorrectlyAnsweredQuestions;
    this.totalWronglyAnsweredQuestions = result.totalWronglyAnsweredQuestions;
  }

  get displayName() {
    return this.name;
  }

  get progress() {
    if (!this.totalCoursesIsolatedQuestions) return 0;
    return (this.totalAnsweredQuestions / this.totalCoursesIsolatedQuestions) * 100;
  }

  get totalTrainingQuestions() {
    return this.totalCoursesIsolatedQuestions;
  }

  get totalProcessedQuestions() {
    return (
      this.totalAnchoredQuestions +
      this.totalAnchoringInProgressQuestions +
      this.totalRejectedQuestions
    );
  }

  toFormValue(): SpecialtyFormValue {
    return {
      name: this.name,
      icon: {
        file: null,
        path: this.icon,
      },
    };
  }

  toResult(): SpecialtyResult {
    return {
      id: this.id,
      name: this.name,
      icon: this.icon,
      totalCourses: this.totalCourses,
      totalCoursesIsolatedQuestions: this.totalCoursesIsolatedQuestions,
      challengeSpecialtyScores: this.challengeSpecialtyScores?.map((elt) => elt.toResult()),
      totalAnsweredQuestions: this.totalAnsweredQuestions,
      successRate: this.successRate,
      totalDuration: this.totalDuration,
      totalAnchoredQuestions: this.totalAnchoredQuestions,
      totalAnchoringInProgressQuestions: this.totalAnchoringInProgressQuestions,
      totalRejectedQuestions: this.totalRejectedQuestions,
      totalCorrectlyAnsweredQuestions: this.totalCorrectlyAnsweredQuestions,
      totalPartiallyCorrectlyAnsweredQuestions: this.totalPartiallyCorrectlyAnsweredQuestions,
      totalWronglyAnsweredQuestions: this.totalWronglyAnsweredQuestions,
    };
  }

  static updatedEntityToResult(
    payload: UpdateSpecialtyPayload,
    updateIconResult: UpdateSpecialtyIconResult
  ): Partial<SpecialtyResult> {
    return {
      name: payload.name,
      icon: updateIconResult.icon,
    };
  }
}
