import { Component, HostBinding, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { ModalService } from '../../../core/services/modal.service';

export interface ModalUserProfileData {
  studentId: string;
}

@Component({
  selector: 'app-modal-user-profile',
  templateUrl: './modal-user-profile.component.html',
  styleUrls: ['./modal-user-profile.component.scss'],
})
export class ModalUserProfileComponent implements OnInit, OnDestroy {
  @HostBinding('attr.id') modalId = 'modalUserProfile';

  constructor(
    public dialogRef: MatDialogRef<ModalUserProfileComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: ModalUserProfileData,
    private modalService: ModalService
  ) {}

  ngOnInit(): void {
    this.modalService.addModalToStack(this.dialogRef);
  }

  ngOnDestroy() {
    this.modalService.removeModalFromStack(this.dialogRef);
  }

  close() {
    this.dialogRef.close();
  }
}
