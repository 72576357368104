import { createSelector } from '@ngrx/store';
import { LoadingState } from '../../../shared/models/interfaces/call-state.interface';
import { Student } from '../../../shared/models/entities/student.entity';
import { AppState } from '../core.reducers';
import { Writer } from '../../../shared/models/entities/writer.entity';
import { StudentSetting } from '../../../shared/models/entities/student-setting.entity';
import { OfferTier } from '../../../shared/models/enums/offer.enums';
import { SubscriptionTier } from '../../../shared/models/enums/subscription.enums';
import { Subscription } from '../../../shared/models/entities/subscription.entity';

export const selectAuth = (state: AppState) => state.auth;

export const initializing = createSelector(selectAuth, (state) => state.initializing);
export const token = createSelector(selectAuth, (state) => state.token);
export const refreshToken = createSelector(selectAuth, (state) => state.refreshToken);
export const rawStudent = createSelector(selectAuth, (state) => state.student);
export const isLogged = createSelector(selectAuth, (state) => {
  if (state.student) {
    return true;
  } else {
    return false;
  }
});
export const loggedStudent = createSelector(selectAuth, (state) => {
  if (state.student) {
    return new Student(state.student);
  } else {
    return undefined;
  }
});
export const isReadyToRegisterWithSSO = createSelector(selectAuth, (state) => {
  if (state.token && state.refreshToken && !state.student) {
    return true;
  } else {
    return false;
  }
});

export const loggedWriter = createSelector(selectAuth, (state) => {
  if (state.writer) {
    return new Writer(state.writer);
  } else {
    return undefined;
  }
});

export const loginLoading = createSelector(
  selectAuth,
  (state) => state.loginState === LoadingState.LOADING
);
export const pathToReachAfterLogin = createSelector(
  selectAuth,
  (state) => state.pathToReachAfterLogin
);

export const authIsClean = createSelector(selectAuth, (state) => {
  return !state.student && !state.token && !state.refreshToken;
});

// nnkitodo [v2later] ne plus utiliser ce selector mais celui du dessous
export const studentSettings = createSelector(selectAuth, (state) => {
  if (state.studentSettings) {
    return state.studentSettings['hydra:member'].map((elt) => new StudentSetting(elt));
  } else {
    return undefined;
  }
});

export const studentSetting = (name: string) =>
  createSelector(studentSettings, (settings) => settings?.find((setting) => setting.name === name));

export const activeSubscriptions = createSelector(selectAuth, (state) =>
  state.activeSubscriptions['hydra:member']
    .map((subscription) => new Subscription(subscription))
    .sort((a, b) => (a.endAt < b.endAt ? -1 : 1))
);

export const isPremiumPlus = createSelector(selectAuth, (state) => {
  if (state.activeSubscriptions) {
    if (
      state.activeSubscriptions['hydra:member'].find(
        (subscription) => subscription.offer.tier === OfferTier.PREMIUM_PLUS
      )
    ) {
      return true;
    } else {
      return false;
    }
  } else {
    return undefined;
  }
});

export const isElite = createSelector(selectAuth, (state) => {
  if (state.activeSubscriptions) {
    if (
      state.activeSubscriptions['hydra:member'].find(
        (subscription) =>
          subscription.offer.tier === OfferTier.PREMIUM_PLUS ||
          subscription.offer.tier === OfferTier.ELITE
      )
    ) {
      return true;
    } else {
      return false;
    }
  } else {
    return undefined;
  }
});

export const currentSubscription = createSelector(selectAuth, (state) => {
  if (state.activeSubscriptions) {
    const eliteSubscription = state.activeSubscriptions['hydra:member'].find(
      (subscription) => subscription.offer.tier === OfferTier.ELITE
    );
    if (eliteSubscription) {
      return new Subscription(eliteSubscription);
    }
    const premiumPlusSubscription = state.activeSubscriptions['hydra:member'].find(
      (subscription) => subscription.offer.tier === OfferTier.PREMIUM_PLUS
    );
    if (premiumPlusSubscription) {
      return new Subscription(premiumPlusSubscription);
    }
  }
  return undefined;
});

export const currentSubscriptionTier = createSelector(
  currentSubscription,
  (currentSubscription) => {
    if (currentSubscription) {
      if (currentSubscription.offer.tier === OfferTier.ELITE) return SubscriptionTier.ELITE;
      if (currentSubscription.offer.tier === OfferTier.PREMIUM_PLUS)
        return SubscriptionTier.PREMIUM_PLUS;
    }
    return SubscriptionTier.FREE;
  }
);

export const impersonateUser = createSelector(selectAuth, (state) => {
  if (!state.impersonateUser) return undefined;
  return new Student(state.impersonateUser);
});
