import { EventEmitter } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { API_DATETIME_FORMAT } from '../../constants/date-format';
import {
  QuestionResult,
  QuestionStatisticResult,
  UpdateQuestionFileResult,
} from '../api-results/question.api-result';
import { NotionRank } from '../enums/notion.enums';
import { QuestionPublicationStatus } from '../enums/publication-status.enums';
import { QuestionAnswerMethod } from '../enums/question.enums';
import { QuestionFormValue, UpdateQuestionPayload } from '../payloads/question/question.payload';
import { Correction, CorrectionCoordinates } from './correction.entity';
import { Notion } from './notion.entity';
import { StatementContent } from './statement-content.entity';
import { Writer } from './writer.entity';
import moment from 'moment';
import { StatementContentType } from '../enums/statement-content.enums';
import { SessionQuestion } from './session-question.entity';
import {
  extractTCSAdditionalInformation,
  extractTCSHypothesis,
  extractTCSQuestion,
} from '../../utils/question-utils';
import { SimplifiedFolderQuestionResult } from '../api-results/folder-question.api-result';
import { SimplifiedExamContentQuestionResult } from '../api-results/exam-content-question.api-result';

export class Question {
  id: string;
  answerMethod: QuestionAnswerMethod;
  problemStatement: string;
  problemStatementContents: StatementContent[];
  corrections: Correction[];
  correctionStatement: string;
  correctionStatementContents: StatementContent[];
  notions: Notion[];
  file: string;
  isLisa: boolean;
  isPediatric: boolean;
  totalCorrectAnswers: number;

  author: Writer;
  createdAt: Date;
  updatedBy: Writer;
  updatedAt: Date;

  publicationStatus: QuestionPublicationStatus;

  hardestNotionRank: NotionRank;
  totalPublicFeedbacks: number;

  folderQuestion: SimplifiedFolderQuestionResult;
  examContentQuestions: SimplifiedExamContentQuestionResult[];

  questionStatistic: QuestionStatistic;

  // nnkitodo [v2later] plutôt déplacer ça dans SessionQuestion
  answerMatch = '';
  selectedAnswerOne: string;
  answerMatchingRecognition: CorrectionCoordinates;
  updateCanvasEmitter = new EventEmitter();
  isAnswered = false;
  isUpdating = false;

  constructor(result: QuestionResult) {
    this.id = result.id;
    this.answerMethod = result.answerMethod;
    this.problemStatement = result.problemStatement;
    if (result.problemStatementContents)
      this.problemStatementContents = result.problemStatementContents.map(
        (elt) => new StatementContent(elt)
      );
    if (result.corrections) {
      this.corrections = result.corrections
        .map((elt) => new Correction(elt))
        .sort((a, b) => a.priority - b.priority);
    }
    this.correctionStatement = result.correctionStatement;
    if (result.correctionStatementContents)
      this.correctionStatementContents = result.correctionStatementContents.map(
        (elt) => new StatementContent(elt)
      );
    if (result.notions) {
      this.notions = result.notions.map((elt) => new Notion(elt));
    } else {
      this.notions = [];
    }
    this.file = result.file;
    this.isLisa = result.isLisa;
    this.isPediatric = result.isPediatric;
    this.totalCorrectAnswers = result.totalCorrectAnswers;

    if (result.author) this.author = new Writer(result.author);
    if (result.createdAt) this.createdAt = new Date(result.createdAt);
    if (result.updatedBy) this.updatedBy = new Writer(result.updatedBy);
    if (result.updatedAt) this.updatedAt = new Date(result.updatedAt);

    this.publicationStatus = result.publicationStatus;

    this.hardestNotionRank = result.hardestNotionRank;
    this.totalPublicFeedbacks = result.totalPublicFeedbacks;

    if (result.folderQuestion) {
      this.folderQuestion = {
        folder: result.folderQuestion.folder,
      };
    }
    if (result.examContentQuestions) {
      this.examContentQuestions = result.examContentQuestions;
    }

    if (result.questionStatistic) {
      this.questionStatistic = new QuestionStatistic(result.questionStatistic);
    }
  }

  get displayName() {
    return this.problemStatement;
  }

  get hardestNotion() {
    if (!this.notions.length) return null;
    return this.notions.sort((a, b) => (a.rank < b.rank ? 1 : -1))[0];
  }

  // nnkitodo [v2later] enlever cette merde dupliquée
  get problemPictureStatementContents() {
    if (!this.problemStatementContents) return [];
    return this.problemStatementContents.filter(
      (statementContent) =>
        statementContent.type === StatementContentType.FILE &&
        !statementContent.file?.includes('.pdf')
    );
  }

  get correctionPictureStatementContents() {
    if (!this.correctionStatementContents) return [];
    return this.correctionStatementContents.filter(
      (statementContent) =>
        statementContent.type === StatementContentType.FILE &&
        !statementContent.file?.includes('.pdf')
    );
  }

  get problemEmbeddedStatementContents() {
    if (!this.problemStatementContents) return [];
    return this.problemStatementContents.filter(
      (statementContent) => statementContent.type === StatementContentType.EMBEDDED
    );
  }

  get correctionEmbeddedStatementContents() {
    if (!this.correctionStatementContents) return [];
    return this.correctionStatementContents.filter(
      (statementContent) => statementContent.type === StatementContentType.EMBEDDED
    );
  }

  get problemPdfStatementContents() {
    if (!this.problemStatementContents) return [];
    return this.problemStatementContents.filter(
      (statementContent) =>
        statementContent.type === StatementContentType.FILE &&
        statementContent.file?.includes('.pdf')
    );
  }

  get correctionPdfStatementContents() {
    if (!this.correctionStatementContents) return [];
    return this.correctionStatementContents.filter(
      (statementContent) =>
        statementContent.type === StatementContentType.FILE &&
        statementContent.file?.includes('.pdf')
    );
  }

  toFormValue(): QuestionFormValue {
    return {
      answerMethod: this.answerMethod,
      isLisa: this.isLisa,
      isPediatric: this.isPediatric,
      notions: this.notions,
      problemStatement: this.problemStatement,
      // nnkitodo [v2later] gérer TCS proprement en back
      TCSHypothesis:
        this.answerMethod === QuestionAnswerMethod.TCS
          ? extractTCSHypothesis(this.problemStatement)[1]
          : undefined,
      TCSAdditionalInformation:
        this.answerMethod === QuestionAnswerMethod.TCS
          ? extractTCSAdditionalInformation(this.problemStatement)[1]
          : undefined,
      TCSQuestion:
        this.answerMethod === QuestionAnswerMethod.TCS
          ? extractTCSQuestion(this.problemStatement)[1]
          : undefined,
      problemStatementContents: this.problemStatementContents.map((sc) => sc.toFormValue()),
      corrections: this.corrections.map((correction) => correction.toFormValue()),
      correctionStatement: this.correctionStatement,
      correctionStatementContents: this.correctionStatementContents.map((sc) => sc.toFormValue()),
      file: {
        file: null,
        path: this.file,
      },
    };
  }

  toResult(): QuestionResult {
    return {
      id: this.id,
      answerMethod: this.answerMethod,
      problemStatement: this.problemStatement,
      problemStatementContents: this.problemStatementContents?.map((elt) => elt.toResult()),
      corrections: this.corrections?.map((elt) => elt.toResult()),
      correctionStatement: this.correctionStatement,
      correctionStatementContents: this.correctionStatementContents?.map((elt) => elt.toResult()),
      notions: this.notions?.map((elt) => elt.toResult()),
      file: this.file,
      isLisa: this.isLisa,
      isPediatric: this.isPediatric,
      totalCorrectAnswers: this.totalCorrectAnswers,
      author: this.author?.toResult(),
      createdAt: moment(this.createdAt).format(API_DATETIME_FORMAT),
      updatedBy: this.updatedBy?.toResult(),
      updatedAt: moment(this.updatedAt).format(API_DATETIME_FORMAT),
      publicationStatus: this.publicationStatus,
      hardestNotionRank: this.hardestNotionRank,
      totalPublicFeedbacks: this.totalPublicFeedbacks,
      folderQuestion: this.folderQuestion,
      examContentQuestions: this.examContentQuestions,
      questionStatistic: this.questionStatistic?.toResult(),
    };
  }

  static updatedEntityToResult(
    payload: UpdateQuestionPayload,
    updateFileResult: UpdateQuestionFileResult
  ): Partial<QuestionResult> {
    return {
      problemStatement: payload.problemStatement,
      correctionStatement: payload.correctionStatement,
      isLisa: payload.isLisa,
      isPediatric: payload.isPediatric,
      file: updateFileResult.file,
    };
  }
}

export class QuestionStatistic {
  totalAnsweredFeedbacks: number;
  totalFeedbacks: number;
  globalSuccessRate: number;
  globalPerfectSuccessRate: number;

  constructor(result: QuestionStatisticResult) {
    this.totalAnsweredFeedbacks = result.totalAnsweredFeedbacks;
    this.totalFeedbacks = result.totalFeedbacks;
    this.globalSuccessRate = result.globalSuccessRate;
    this.globalPerfectSuccessRate = result.globalPerfectSuccessRate;
  }

  toResult(): QuestionStatisticResult {
    return {
      totalAnsweredFeedbacks: this.totalAnsweredFeedbacks,
      totalFeedbacks: this.totalFeedbacks,
      globalSuccessRate: this.globalSuccessRate,
      globalPerfectSuccessRate: this.globalPerfectSuccessRate,
    };
  }
}
