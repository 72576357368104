import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import localeIt from '@angular/common/locales/it';
import { ErrorHandler, NgModule, Provider } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';

import { environment } from '../environments/environment';

import { CoreModule } from './core/core.module';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import * as Sentry from '@sentry/angular';
import { filterSentryEvent } from './shared/utils/sentry-filter';
import packageJson from '../../package.json';

// nnkitodo [v2later] vérifier si utile (ya pas esp donc bizarre)
registerLocaleData(localeFr, 'fr');
registerLocaleData(localeIt, 'it');

if (environment.production) {
  Sentry.init({
    dsn: 'https://68b87c7e7382460c98962192dcd4f314@o103809.ingest.us.sentry.io/2517946',
    // nnkitodo [v2later] configurer sentry plus finement : https://docs.sentry.io/platforms/javascript/guides/angular/configuration/
    release: 'ecni-front-angular@' + packageJson.version,
    environment: environment.env + '-' + environment.defaultLanguage,
    beforeSend(event) {
      event = filterSentryEvent(event);
      return event;
    },
    // integrations: [
    //   // Sentry.browserTracingIntegration(),
    //   // Sentry.replayIntegration(),
    // ]
  });
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    CoreModule,
    SharedModule,
    AppRoutingModule,
    RouterModule,
    BrowserModule,
    BrowserAnimationsModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.env === 'prod',
      registrationStrategy: 'registerWhenStable:10000',
    }),
  ],
  providers: [
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler(),
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
