<div class="question-challenge">
  <div class="top-question-challenge" [class.-inside]="animationState.questionTop">
    <div class="discipline-badge -inside">
      <div class="badge-content">
        <img [src]="challengeSession.challenge.specialty.icon" />
        <span>{{ challengeSession.challenge.specialty.name }}</span>
      </div>
    </div>

    <div class="widget-time-remaining">
      <app-widget-time-remaining
        [remainingSeconds]="remainingSeconds"
        [percentTimeElapsed]="percentTimeElapsed"
      ></app-widget-time-remaining>
    </div>
  </div>

  <div
    class="content-question-challenge -in-candidate"
    [class.-inside]="animationState.questionContent"
  >
    <div class="question-number" [class.-inside]="animationState.questionNumber">
      {{ 'question.question' | translate }} {{ currentChallengeQuestion.index + 1 }}
    </div>

    <div class="question-content" [class.-inside]="animationState.questionText">
      <app-question-images
        *ngIf="currentChallengeQuestion.question.problemPictureStatementContents?.length"
        [pictureStatementContents]="
          currentChallengeQuestion.question.problemPictureStatementContents
        "
      ></app-question-images>

      <div
        appCopyRestricted
        class="question-text"
        [copyBlocked]="!(isPremiumPlus$ | async)"
        [innerHTML]="currentChallengeQuestion.question.problemStatement"
      ></div>

      <div
        class="block-videos-list"
        *ngIf="currentChallengeQuestion.question.problemEmbeddedStatementContents.length"
      >
        <div
          *ngFor="
            let statementContent of currentChallengeQuestion.question
              .problemEmbeddedStatementContents
          "
          [innerHTML]="statementContent.embedded | safe : 'html'"
          class="block-iframe"
        ></div>
      </div>

      <div
        class="pdf-question mt-8"
        *ngIf="currentChallengeQuestion.question.problemPdfStatementContents.length"
      >
        <ng-container
          *ngFor="
            let statementContent of currentChallengeQuestion.question.problemPdfStatementContents
          "
        >
          <app-pdf-viewer [url]="statementContent.file" [initialHeight]="'70vh'"></app-pdf-viewer>
        </ng-container>
      </div>
    </div>

    <div
      *ngIf="currentChallengeQuestion.question.answerMethod === QuestionAnswerMethod.MATCH"
      class="question-qroc"
      [class.-inside]="animationState.questionQroc"
    >
      <form
        class="form-nnki form-nnki-candidate -candidate"
        [class.nnki-no-select]="isCorrection"
        (ngSubmit)="answerToQuestion()"
      >
        <div class="row-form-nnki">
          <input
            type="text"
            class="input-nnki width100"
            placeholder="{{ 'util.saisissez' | translate }}"
            [(ngModel)]="currentChallengeQuestion.question.answerMatch"
            name="userAnswer-{{ currentChallengeQuestion.question.id }}"
            [class.-correct]="isCorrection && grade === maxGrade"
            [class.-incorrect]="isCorrection && grade !== maxGrade"
          />
        </div>

        <ng-container *ngIf="isCorrection && grade !== maxGrade">
          <div
            class="row-form-nnki"
            *ngFor="let correction of currentChallengeQuestion.question.corrections"
          >
            <input
              type="text"
              class="input-nnki width100 -accepted"
              placeholder="{{ 'util.saisissez' | translate }}"
              [value]="correction.content | utf8_encode"
            />
          </div>
        </ng-container>
      </form>
    </div>

    <div
      class="question-answers"
      *ngIf="
        currentChallengeQuestion.question.answerMethod === QuestionAnswerMethod.ONE ||
        currentChallengeQuestion.question.answerMethod === QuestionAnswerMethod.TCS ||
        currentChallengeQuestion.question.answerMethod === QuestionAnswerMethod.MANY_UNDEFINED ||
        currentChallengeQuestion.question.answerMethod ===
          QuestionAnswerMethod.MANY_DEFINED_SHORT ||
        currentChallengeQuestion.question.answerMethod === QuestionAnswerMethod.MANY_DEFINED_LONG
      "
      [class.-inside]="animationState.questionAnswers"
    >
      <div
        class="question-answer"
        *ngFor="let correction of currentChallengeQuestion.question.corrections"
        [class.-checked]="correction.isSelected"
        (click)="selectOption(correction)"
        [style.order]="correction.randomIndex"
        [class.-correct]="isCorrection && correction.isCorrect"
        [class.-incorrect]="isCorrection && !correction.isCorrect"
      >
        {{
          (isCorrection ? correction.displayContent : correction.content)
            | utf8_encode
            | capitalizeFirstLetter
        }}
      </div>
    </div>

    <div
      *ngIf="currentChallengeQuestion.question.answerMethod === QuestionAnswerMethod.MATCHING_AREA"
      class="zap_canvas_block"
      [class.-inside]="animationState.questionZap"
    >
      <div class="zap_canvas_container" (click)="selectPoint($event)" #canvasContainer>
        <canvas #canvas class="zap_canvas"></canvas>
        <img
          [src]="currentChallengeQuestion.question.file"
          #imageZAP
          class="zap_image"
          (load)="initCanvas()"
        />
      </div>
    </div>

    <div
      class="question-button"
      [class.-inside]="animationState.questionButton"
      *ngIf="!isCorrection"
    >
      <button
        matRipple
        [matRippleCentered]="false"
        [matRippleUnbounded]="true"
        [matRippleRadius]="50"
        class="btn-nnki btn-nnki-primary btn-nnki-uppercase"
        *ngIf="!isAnswering"
        (click)="answerToQuestion()"
      >
        {{ 'util.valider' | translate }}
      </button>

      <div class="spinner_100_container" *ngIf="isAnswering">
        <app-loader-dots></app-loader-dots>
      </div>
    </div>
  </div>
</div>
