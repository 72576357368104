import moment from 'moment';
import { environment } from '../../../../environments/environment';
import { API_DATETIME_FORMAT } from '../../constants/date-format';
import { ExamSessionResult } from '../api-results/exam-session.api-result';
import { ExamContentSession } from './exam-content-session.entity';
import { Exam } from './exam.entity';
import { Student } from './student.entity';
import { GraduationName } from './graduation-name.entity';
import { University } from './university.entity';
import { RankedSession } from '../interfaces/ranked-session.interface';

export class ExamSession implements RankedSession {
  createdAt: Date;
  closedAt: Date;
  isCompleted: boolean;

  grade: number;
  rank: number;
  universityCityRank: number;
  universityRank: number;
  graduationNameRank: number;

  totalParticipantsInStudentGraduationName: number;
  totalParticipantsInStudentUniversity: number;
  totalParticipantsInStudentUniversityCity: number;

  exam: Exam;
  examContentSessions: ExamContentSession[];

  student: Student;
  graduationName: GraduationName;
  university: University;

  constructor(result: ExamSessionResult) {
    if (result.createdAt) this.createdAt = new Date(result.createdAt);
    if (result.closedAt) this.closedAt = new Date(result.closedAt);
    this.isCompleted = result.isCompleted;

    this.grade = result.grade;
    this.rank = result.myRank ? result.myRank : result.studentRank;
    this.universityCityRank = result.myUniversityCityRank
      ? result.myUniversityCityRank
      : result.studentUniversityCityRank;
    this.universityRank = result.myUniversityRank
      ? result.myUniversityRank
      : result.studentUniversityRank;
    this.graduationNameRank = result.myGraduationNameRank
      ? result.myGraduationNameRank
      : result.studentGraduationNameRank;

    this.totalParticipantsInStudentGraduationName = result.totalParticipantsInStudentGraduationName;
    this.totalParticipantsInStudentUniversity = result.totalParticipantsInStudentUniversity;
    this.totalParticipantsInStudentUniversityCity = result.totalParticipantsInStudentUniversityCity;

    if (result.exam) this.exam = new Exam(result.exam);
    if (result.examContentSessions)
      this.examContentSessions = result.examContentSessions
        .map((elt) => new ExamContentSession(elt))
        .sort((a, b) => (a.createdAt < b.createdAt ? -1 : 1));

    if (result.student) this.student = new Student(result.student);
    if (result.graduationName) this.graduationName = new GraduationName(result.graduationName);
    if (result.university) this.university = new University(result.university);
  }

  toResult(): ExamSessionResult {
    return {
      createdAt: moment(this.createdAt).format(API_DATETIME_FORMAT),
      closedAt: moment(this.closedAt).format(API_DATETIME_FORMAT),
      isCompleted: this.isCompleted,
      grade: this.grade,
      studentRank: this.rank,
      myRank: this.rank,
      myUniversityCityRank: this.universityCityRank,
      studentUniversityCityRank: this.universityCityRank,
      myUniversityRank: this.universityRank,
      studentUniversityRank: this.universityRank,
      myGraduationNameRank: this.graduationNameRank,
      studentGraduationNameRank: this.graduationNameRank,
      totalParticipantsInStudentGraduationName: this.totalParticipantsInStudentGraduationName,
      totalParticipantsInStudentUniversity: this.totalParticipantsInStudentUniversity,
      totalParticipantsInStudentUniversityCity: this.totalParticipantsInStudentUniversityCity,
      exam: this.exam?.toResult(),
      examContentSessions: this.examContentSessions?.map((elt) => elt.toResult()),
      student: this.student?.toResult(),
      graduationName: this.graduationName?.toResult(),
      university: this.university?.toResult(),
    };
  }

  get content() {
    return this.exam;
  }
}
