import { Component, HostBinding, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { TranslateService } from '@ngx-translate/core';

import { ModalService } from '../../../core/services/modal.service';

import { ModalListFollowsType, StudentThumbnailType } from '../../models/enums/follow.enum';
import { ModalListFriendsStoreService } from './modal-list-friends.component-store';

import * as AuthSelectors from '../../../core/state/auth-state/auth.selectors';
import { first } from 'rxjs';

export interface ModalListFriendsData {
  studentId: string;
  type: ModalListFollowsType;
}

@Component({
  selector: 'app-modal-list-friends',
  templateUrl: './modal-list-friends.component.html',
  styleUrls: ['./modal-list-friends.component.scss'],
  providers: [ModalListFriendsStoreService],
})
export class ModalListFriendsComponent implements OnInit, OnDestroy {
  @HostBinding('attr.id') modalId = 'modalListFriends';

  constructor(
    public dialogRef: MatDialogRef<ModalListFriendsComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: ModalListFriendsData,
    private translate: TranslateService,
    private modalService: ModalService,
    public componentStore: ModalListFriendsStoreService
  ) {}

  ngOnInit(): void {
    this.modalService.addModalToStack(this.dialogRef);
    this.componentStore.init({ type: this.modalData.type, studentId: this.modalData.studentId });

    this.componentStore.initList({});
  }

  ngOnDestroy() {
    this.modalService.removeModalFromStack(this.dialogRef);
  }

  scroll() {
    this.componentStore.scroll();
  }

  get titleModale() {
    if (this.modalData.type === ModalListFollowsType.FOLLOWED) {
      return this.translate.instant('twit.abonnements');
    } else if (this.modalData.type === ModalListFollowsType.FOLLOWERS) {
      return this.translate.instant('twit.abonnes');
    } else if (this.modalData.type === ModalListFollowsType.PENDING) {
      return this.translate.instant('friend.en_attente');
    }
  }

  get studentThumbnailType() {
    if (this.modalData.type === ModalListFollowsType.FOLLOWED) {
      return StudentThumbnailType.DEFAULT;
    } else if (this.modalData.type === ModalListFollowsType.FOLLOWERS) {
      return StudentThumbnailType.FOLLOWERS;
    } else if (this.modalData.type === ModalListFollowsType.PENDING) {
      return StudentThumbnailType.PENDING;
    }
  }

  close() {
    this.dialogRef.close();
  }
}
