import { StudentActivityResult } from '../api-results/student-activity.api-result';
import { StudentActivityType } from '../enums/student-activity.enums';
import { ExamContent } from './exam-content.entity';
import { Specialty } from './specialty.entity';
import { Student } from './student.entity';

export class StudentActivity {
  id: string;
  createdAt: Date;
  type: StudentActivityType;
  student: Student;
  examContent: ExamContent;
  badgeLevel: number;
  specialty: Specialty;
  totalStudentLikedActivity: number;
  isLikedByMe: boolean;

  content: string;

  loadingLike: boolean;

  constructor(result: StudentActivityResult, loggedStudent?: Student) {
    this.id = result.id;
    if (result.createdAt) this.createdAt = new Date(result.createdAt);
    this.type = result.type;
    if (result.student) this.student = new Student(result.student);
    if (!result.student && loggedStudent) this.student = loggedStudent;
    if (result.examContent) this.examContent = new ExamContent(result.examContent);
    this.badgeLevel = result.badgeLevel;
    if (result.specialty) this.specialty = new Specialty(result.specialty);
    this.totalStudentLikedActivity = result.totalStudentLikedActivity;
    this.isLikedByMe = result.isLikedByMe;
    this.loadingLike = result.loadingLike;

    if (this.student) {
      if (this.type === StudentActivityType.BADGE) {
        this.content = $localize`<strong>${this.student.username}</strong> a remporté le badge <strong>Niveau ${this.badgeLevel} ${this.specialty.name}</strong>`;
      } else if (this.type === StudentActivityType.EXAM_CONTENT) {
        this.content = $localize`<strong>${this.student.username}</strong> a terminé l'examen <strong>${this.examContent.title} </strong>`;
      } else if (this.type === StudentActivityType.PROGRESS) {
        this.content = $localize`<strong>${this.student.username}</strong> a progressé dans la matière <strong>${this.specialty.name}</strong>`;
      } else if (this.type === StudentActivityType.VICTORY) {
        this.content = $localize`<strong>${this.student.username}</strong> a remporté un défi dans la matière <strong>${this.specialty.name}</strong>`;
      }
    }
  }
}
