import moment from 'moment';
import { API_DATETIME_FORMAT } from '../../constants/date-format';
import { AddSubscriptionResult, SubscriptionResult } from '../api-results/subscription.api-result';
import { OfferTier } from '../enums/offer.enums';
import { SubscriptionStatus, SubscriptionTier } from '../enums/subscription.enums';
import { Offer } from './offer.entity';
import { Voucher } from './voucher.entity';
import { environment } from '../../../../environments/environment';
import { AddSpecialtyPayload } from '../payloads/specialty.payload';
import { AddSubscriptionPayload } from '../payloads/subscription.payload';
import { OfferResult } from '../api-results/offer.api-result';
import { VoucherResult } from '../api-results/voucher.api-result';

export class Subscription {
  id: string;
  status: SubscriptionStatus;
  offer: Offer;
  availablePaymentInstallment: number;
  voucher: Voucher;

  createdAt: Date;
  startAt: Date;
  endAt: Date;
  closedAt: Date;

  paymentMethodId: string;
  fullyPaid: boolean;

  // nnkitodo [v2later] eurk
  loading = false;

  constructor(result: SubscriptionResult) {
    this.id = result.id;
    this.status = result.status;
    if (result.offer) this.offer = new Offer(result.offer);
    this.availablePaymentInstallment = result.availablePaymentInstallment;
    if (result.voucher) this.voucher = new Voucher(result.voucher);
    if (result.createdAt) {
      this.createdAt = new Date(result.createdAt);
    }
    if (result.startAt) {
      this.startAt = new Date(result.startAt);
    }
    if (result.endAt) {
      this.endAt = new Date(result.endAt);
    }
    if (result.closedAt) {
      this.closedAt = new Date(result.closedAt);
    }
    this.paymentMethodId = result.paymentMethodId;
    this.fullyPaid = result.fullyPaid;
  }

  get paidPrice() {
    if (!this.voucher) return this.offer.price;
    return this.offer.price - this.offer.price * (this.voucher.discount / 100);
  }

  toResult(): SubscriptionResult {
    return {
      id: this.id,
      status: this.status,
      offer: this.offer?.toResult(),
      availablePaymentInstallment: this.availablePaymentInstallment,
      voucher: this.voucher?.toResult(),
      createdAt: this.createdAt ? moment(this.createdAt).format(API_DATETIME_FORMAT) : null,
      startAt: this.startAt ? moment(this.startAt).format(API_DATETIME_FORMAT) : null,
      endAt: this.endAt ? moment(this.endAt).format(API_DATETIME_FORMAT) : null,
      closedAt: this.closedAt ? moment(this.closedAt).format(API_DATETIME_FORMAT) : null,
      paymentMethodId: this.paymentMethodId,
      fullyPaid: this.fullyPaid,
    };
  }

  static addedEntityToResult(
    availablePaymentInstallment: number,
    result: AddSubscriptionResult,
    offer: Offer,
    voucher: Voucher
  ): SubscriptionResult {
    return {
      id: result.id,
      status: SubscriptionStatus.PAYMENT_PENDING,
      offer: offer?.toResult(),
      availablePaymentInstallment: availablePaymentInstallment,
      voucher: voucher?.toResult(),
      createdAt: moment(new Date()).format(API_DATETIME_FORMAT),
      startAt: result.startAt,
      endAt: result.endAt,
      closedAt: null,
      paymentMethodId: undefined,
      fullyPaid: false,
    };
  }
}
