import { Injectable } from '@angular/core';

import { Howl } from 'howler';

import { Store } from '@ngrx/store';
import * as AuthSelectors from '../../core/state/auth-state/auth.selectors';
import { StudentSettingName } from '../../shared/models/enums/student-settings.enum';
import { first, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SoundService {
  sounds: Howl[] = [];

  challengeMusicActivated$ = this.store.select(
    AuthSelectors.studentSetting(StudentSettingName.CHALLENGE_MUSIC_ACTIVATED)
  );

  constructor(private store: Store) {}

  initSound(id: string, src: string, loop: boolean, volume?: number) {
    if (!volume) volume = 1;
    if (!this.sounds[id]) {
      this.sounds[id] = new Howl({
        src: [src],
        loop,
        volume,
      });
    }
  }

  playSound(id: string) {
    if (this.sounds[id]) {
      this.sounds[id].play();
    }
  }

  stopSound(id: string) {
    if (this.sounds[id]) {
      this.sounds[id].stop();
    }
  }

  fadeSound(id: string) {
    if (this.sounds[id]) {
      this.sounds[id].fade(1, 0, 5000);
    }
  }

  isChallengeMusicActivated() {
    return this.challengeMusicActivated$.pipe(
      first(),
      map((res) => res.booleanValue)
    );
  }
}
