import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl, NonNullableFormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { debounceTime, first, takeUntil } from 'rxjs/operators';

import { ChallengeService } from '../../../core/services/challenge.service';
import { HttpChallengeService } from '../../../core/services/http/http-challenge.service';

import { BaseComponent } from '../../components/inherited/base/base.component';

import {
  ModalCandidateChallengeComponent,
  ModalCandidateChallengeData,
} from '../modal-candidate-challenge/modal-candidate-challenge.component';

import { Student } from '../../models/entities/student.entity';
import { Specialty } from '../../models/entities/specialty.entity';
import { HttpSpecialtyService } from '../../../core/services/http/http-specialty.service';
import { SpecialtyQueryData } from '../../models/payloads/specialty.payload';
import { ChooseFriendsComponent } from '../../components/choose-friends/choose-friends.component';
import { ChallengePayload } from '../../models/payloads/challenge.payload';
import { ErrorService } from '../../../core/services/error.service';
import * as AuthSelectors from '../../../core/state/auth-state/auth.selectors';
import { Store } from '@ngrx/store';
import { ModalPremiumPlusComponent } from '../modal-premium-plus/modal-premium-plus.component';

export interface ModalCreateChallengeData {
  usersSelected?: Student[];
}

@Component({
  selector: 'app-modal-create-challenge',
  templateUrl: './modal-create-challenge.component.html',
  styleUrls: ['./modal-create-challenge.component.scss'],
})
export class ModalCreateChallengeComponent extends BaseComponent implements OnInit, AfterViewInit {
  challengeFormStep1 = this.formBuilder.group({
    specialty: [undefined as Specialty, [Validators.required]],
  });

  challengeFormStep2 = this.formBuilder.group({
    students: [[] as Student[], [Validators.required]],
  });

  specialties: Specialty[];
  filteredSpecialties: Specialty[];
  specialtySearchControl = new FormControl<string>('');

  @ViewChild(ChooseFriendsComponent) chooseFriendsComponent: ChooseFriendsComponent;

  isCreating = false;

  constructor(
    public dialogRef: MatDialogRef<ModalCreateChallengeComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: ModalCreateChallengeData,
    private formBuilder: NonNullableFormBuilder,
    private matDialog: MatDialog,
    private store: Store,
    private toastr: ToastrService,
    private translate: TranslateService,
    private challengeService: ChallengeService,
    private errorService: ErrorService,
    private httpChallengeService: HttpChallengeService,
    private httpSpecialtyService: HttpSpecialtyService
  ) {
    super();
  }

  ngOnInit(): void {
    this.store
      .select(AuthSelectors.isPremiumPlus)
      .pipe(first())
      .subscribe((isPremiumPlus) => {
        if (!isPremiumPlus) {
          this.matDialog.open(ModalPremiumPlusComponent, {
            maxWidth: '100vw',
          });
          this.close();
          return;
        }

        this.specialtySearchControl.valueChanges
          .pipe(takeUntil(this.alive$), debounceTime(500))
          .subscribe(() => {
            this.filterSpecialties();
          });

        this.loadSpecialties();
      });
  }

  ngAfterViewInit(): void {
    this.chooseFriendsComponent.componentStore.selectedStudents$
      .pipe(takeUntil(this.alive$))
      .subscribe((selectedStudents) => {
        this.challengeFormStep2.patchValue({
          students: selectedStudents,
        });
      });
  }

  close() {
    this.dialogRef.close();
  }

  loadSpecialties() {
    this.httpSpecialtyService
      .list(
        new SpecialtyQueryData(
          {
            sortBy: 'name',
            sortByOrder: 'asc',
            minCoursesIsolatedQuestions: 7,
          },
          true
        )
      )
      .subscribe((res) => {
        this.specialties = res['hydra:member'].map((elt) => new Specialty(elt));
        this.filterSpecialties();
      });
  }

  filterSpecialties() {
    this.challengeFormStep1.patchValue({
      specialty: undefined,
    });
    this.filteredSpecialties = this.specialties.filter((specialty) =>
      specialty.name.toLowerCase().includes(this.specialtySearchControl.value.toLowerCase())
    );
  }

  toggleDisciplineSelected(specialty: Specialty) {
    this.challengeFormStep1.patchValue({
      specialty: specialty,
    });
  }

  createChallenge() {
    if (this.challengeFormStep2.valid) {
      this.isCreating = true;

      this.httpChallengeService
        .add(
          new ChallengePayload({
            ...this.challengeFormStep1.getRawValue(),
            ...this.challengeFormStep2.getRawValue(),
          })
        )
        .subscribe({
          next: (res) => {
            this.toastr.success(this.translate.instant('challenge.succes'));

            this.challengeService.reloadChallengeList.emit();
            this.challengeService.updateAvailableChallenges();

            this.close();

            const data: ModalCandidateChallengeData = {
              challengeId: res.id,
              challengeQuestions: res.challengeQuestions,
            };

            this.matDialog.open(ModalCandidateChallengeComponent, {
              maxWidth: '100vw',
              data,
            });
          },
          error: (err) => {
            this.isCreating = false;
            this.errorService.toastError(err);
          },
        });
    }
  }
}
