import { Injectable } from '@angular/core';
import { HttpFollowService } from '../../../core/services/http/http-follow.service';
import {
  listInitialState,
  AbstractListComponentStore,
  ListState,
} from '../../../core/state/base-component-stores/abstract-list.component-store';
import { WListState } from '../../../core/state/base-component-stores/abstract-writer-list.component-store';
import { StudentResult } from '../../models/api-results/student.api-result';
import { Student } from '../../models/entities/student.entity';
import { FollowStatus, ModalListFollowsType } from '../../models/enums/follow.enum';
import { FiltersFormValue, QueryData } from '../../models/payloads/query-data.payload';
import { concatMap, filter, first, withLatestFrom } from 'rxjs';
import { FollowedStudentResult } from '../../models/api-results/follow.api-result';
import { FollowedStudent } from '../../models/entities/follow.entity';
import { FollowQueryData } from '../../models/payloads/follow.payload';
import { Store } from '@ngrx/store';
import * as AuthSelectors from '../../../core/state/auth-state/auth.selectors';

export interface ModalListFriendsState extends ListState<FollowedStudentResult> {
  type: ModalListFollowsType;
  studentId: string;
}

export const initialState: ModalListFriendsState = {
  ...listInitialState,
  type: undefined,
  studentId: undefined,
};

@Injectable()
export class ModalListFriendsStoreService extends AbstractListComponentStore<ModalListFriendsState> {
  constructor(private httpFollowService: HttpFollowService) {
    super(initialState);
  }

  // SELECTORS

  entities$ = this.select((state) =>
    state.entities['hydra:member'].map((elt) => new FollowedStudent(elt, state.type))
  );
  totalEntities$ = this.select((state) => state.entities['hydra:totalItems']);
  type$ = this.select((state) => state.type);
  studentId$ = this.select((state) => state.studentId);

  // ACTIONS

  init = this.updater((state, data: { type: ModalListFollowsType; studentId: string }) => {
    return {
      ...state,
      type: data.type,
      studentId: data.studentId,
    };
  });

  // EFFECTS

  listApiCall(filtersFormValue: FiltersFormValue) {
    return this.type$.pipe(
      filter((type) => !!type),
      first(),
      withLatestFrom(this.studentId$),
      concatMap(([type, studentId]) => {
        if (type === ModalListFollowsType.FOLLOWED) {
          if (!studentId) {
            return this.httpFollowService.listFollowedByMe(
              new FollowQueryData({ ...filtersFormValue, status: FollowStatus.APPROVED })
            );
          } else {
            return this.httpFollowService.listFollowedByStudent(
              studentId,
              new FollowQueryData({ ...filtersFormValue, status: FollowStatus.APPROVED })
            );
          }
        } else if (type === ModalListFollowsType.FOLLOWERS) {
          if (!studentId) {
            return this.httpFollowService.listMyFollowers(
              new FollowQueryData({ ...filtersFormValue, status: FollowStatus.APPROVED })
            );
          } else {
            return this.httpFollowService.listStudentFollowers(
              studentId,
              new FollowQueryData({ ...filtersFormValue, status: FollowStatus.APPROVED })
            );
          }
        } else if (type === ModalListFollowsType.PENDING) {
          return this.httpFollowService.listMyFollowers(
            new FollowQueryData({ ...filtersFormValue, status: FollowStatus.PENDING })
          );
        }
      })
    );
  }
}
