import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Subscription, timer } from 'rxjs';
import * as AuthSelectors from '../state/auth-state/auth.selectors';
import { HttpKnowledgeAnchoringService } from './http/http-knowledge-anchoring.service';
import { KnowledgeAnchoringPlanning } from '../../shared/models/entities/knowledge-anchoring.entity';
import { KnowledgeAnchoringPlannedRevisions } from '../../shared/models/enums/knowledge-anchoring.enums';

@Injectable({
  providedIn: 'root',
})
export class KnowledgeAnchoringService {
  checkTimeSubscription: Subscription;
  knowledgeAnchoringPlanning$ = new BehaviorSubject<KnowledgeAnchoringPlanning>(null);

  constructor(
    private store: Store,
    private httpKnowledgeAnchoringService: HttpKnowledgeAnchoringService
  ) {}

  init() {
    this.store.select(AuthSelectors.isLogged).subscribe((isLogged) => {
      if (isLogged) {
        this.checkTimeSubscription = timer(0, 5 * 60 * 1000).subscribe(() => {
          this.updateKnowledgeAnchoringPlanning();
        });
      } else {
        if (this.checkTimeSubscription) {
          this.checkTimeSubscription.unsubscribe();
        }
        this.knowledgeAnchoringPlanning$.next(null);
      }
    });
  }

  updateKnowledgeAnchoringPlanning() {
    this.httpKnowledgeAnchoringService.getPlanning().subscribe((res) => {
      this.knowledgeAnchoringPlanning$.next(new KnowledgeAnchoringPlanning(res));
    });
  }

  substractRevision(plannedRevisions: KnowledgeAnchoringPlannedRevisions) {
    const planning = this.knowledgeAnchoringPlanning$.getValue();

    if (plannedRevisions === KnowledgeAnchoringPlannedRevisions.ONLY_LATE) {
      planning.totalRepetitionsLate--;
    } else if (plannedRevisions === KnowledgeAnchoringPlannedRevisions.ONLY_TODAY) {
      planning.totalRepetitionsPlannedToday--;
    } else {
      if (planning.totalRepetitionsLate > 0) {
        planning.totalRepetitionsLate--;
      } else {
        planning.totalRepetitionsPlannedToday--;
      }
    }

    this.knowledgeAnchoringPlanning$.next(planning);
  }
}
