import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { GoogleAnalyticsService } from '../../../core/services/google-analytics.service';
import { LastDeployedVersions } from '../../models/entities/front-app-version.entity';

export interface ModalUpdateVersionData {
  forceUpdate: boolean;
  currentVersion: string;
  lastDeployedVersions: LastDeployedVersions;
}

@Component({
  selector: 'app-modal-update-version',
  templateUrl: './modal-update-version.component.html',
  styleUrls: ['./modal-update-version.component.scss'],
})
export class ModalUpdateVersionComponent implements OnInit, OnDestroy {
  acceptUpdate = false;

  constructor(
    public dialogRef: MatDialogRef<ModalUpdateVersionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ModalUpdateVersionData,
    private googleAnalyticsService: GoogleAnalyticsService
  ) {}

  ngOnInit() {}

  ngOnDestroy() {
    let acceptStr = 'refuse update site';
    if (this.acceptUpdate) {
      acceptStr = 'accept update site';
    }

    this.googleAnalyticsService.sendEvent(
      acceptStr,
      'update',
      'from ' +
        this.data.currentVersion +
        ' to ' +
        (this.data.lastDeployedVersions
          ? this.data.lastDeployedVersions.lastVersion.displayName
          : '[sw auto]')
    );
  }

  updateSite() {
    // nnkitodo [v2later] je ne pense pas que ceci fonctionne
    this.acceptUpdate = true;
    window.location.href = window.location.href;
    window.location.reload();
  }

  close() {
    this.dialogRef.close();
  }
}
