import { ChallengeSessionResult } from '../api-results/challenge-session.api-result';
import { ChallengeResult } from '../api-results/challenge.api-result';
import { ContentQuestionResult } from '../api-results/content-question.api-result';
import { SessionQuestionResult } from '../api-results/session-question.api-result';
import { ChallengeInvitationStatus } from '../enums/challenge-invitation.enum';
import { ContentSessionType, SessionStatus } from '../enums/session.enum';
import { ChallengeInvitation } from './challenge-invitation.entity';
import { ChallengeQuestion } from './challenge-question.entity';
import { Challenge } from './challenge.entity';
import { ContentSession } from './content-session.entity';
import { SessionQuestion } from './session-question.entity';
import { Student } from './student.entity';

export class ChallengeSession extends ContentSession {
  createdAt: Date;
  closedAt: Date;
  status: SessionStatus;

  student: Student;
  challenge: Challenge;

  timerActivatedAt: Date;
  remainingTime: number;

  challengeQuestions: ChallengeQuestion[];

  constructor(result: ChallengeSessionResult, loggedStudent?: Student) {
    super(result);

    if (result.createdAt) this.createdAt = new Date(result.createdAt);
    if (result.closedAt) this.closedAt = new Date(result.closedAt);
    this.status = result.status;

    if (result.student) this.student = new Student(result.student);
    if (!result.student && loggedStudent) this.student = loggedStudent;

    if (result.challenge) this.challenge = new Challenge(result.challenge);

    if (result.timerActivatedAt) this.timerActivatedAt = new Date(result.timerActivatedAt);
    if (result.remainingTime) this.remainingTime = result.remainingTime;

    if (result.challengeQuestions) {
      this.setChallengeQuestions(result.challengeQuestions);

      if (result.challengeSessionQuestionAnswers) {
        this.setChallengeQuestionAnswers(result.challengeSessionQuestionAnswers);
      }
    }
  }

  get type() {
    return ContentSessionType.CHALLENGE;
  }

  get content() {
    return undefined;
  }

  get contentQuestions() {
    return this.challengeQuestions;
  }

  get isCompleted() {
    return this.status === SessionStatus.CLOSED;
  }

  get maxGrade() {
    return undefined;
  }

  get studentRate() {
    return undefined;
  }

  get courses() {
    return [];
  }

  get students() {
    return [
      ...this.challenge?.challengeInvitations
        .filter(
          (challengeInvitation) => challengeInvitation.status === ChallengeInvitationStatus.PENDING
        )
        .map((challengeInvitation) => challengeInvitation.student),
      ...this.challenge?.challengeSessions.map((challengeSession) => challengeSession.student),
    ];
  }

  get sessionsClosedByGrade(): ChallengeSession[] {
    return [...[this], ...this.challenge.challengeSessions]
      .sort((a, b) => (a.grade > b.grade ? -1 : 1))
      .filter((challengeSession) => challengeSession.status === SessionStatus.CLOSED);
  }

  get sessionsNotClosed(): ChallengeSession[] {
    return [...[this], ...this.challenge.challengeSessions].filter(
      (challengeSession) => challengeSession.status !== SessionStatus.CLOSED
    );
  }

  get pendingInvitations(): ChallengeInvitation[] {
    return this.challenge.challengeInvitations.filter(
      (challengeInvitation) => challengeInvitation.status === ChallengeInvitationStatus.PENDING
    );
  }

  studentPosition(studentId: string) {
    return (
      this.sessionsClosedByGrade.findIndex(
        (challengeSession) => challengeSession.student.id === studentId
      ) + 1
    );
  }

  setChallengeQuestions(challengeQuestions: ContentQuestionResult[]) {
    this.challengeQuestions = challengeQuestions
      .map((elt) => new ChallengeQuestion(elt))
      .sort((a, b) => (a.priority < b.priority ? -1 : 1));

    this.challengeQuestions.forEach(
      (challengeQuestion, index) => (challengeQuestion.index = index)
    );
  }

  setChallengeQuestionAnswers(challengeQuestionAnswers: SessionQuestionResult[]) {
    this.questions = challengeQuestionAnswers.map((elt) => new SessionQuestion(elt));
    SessionQuestion.buildQuestionsFromSession(this.questions, this.challengeQuestions);
  }
}
