<app-modal [isWriterArea]="true" color="site-main-one" (close)="close(false)">
  <ng-container modalTitle>
    <ng-container i18n>{{ modalData.title }}</ng-container>
  </ng-container>

  {{ modalData.message }}

  <ng-container modalButtons>
    <app-button
      i18n-label
      label="Non"
      icon="backspace"
      [isMaterial]="true"
      backgroundClass="bg-theme-white"
      borderClass="border border-text-site-main-one border-solid"
      textClass="text-site-main-one"
      [iconWidth]="30"
      (click)="close(false)"
    ></app-button>
    <app-button
      i18n-label
      label="Oui"
      icon="check"
      [isMaterial]="true"
      backgroundClass="bg-site-main-one"
      [iconWidth]="30"
      (click)="close(true)"
    ></app-button>
  </ng-container>
</app-modal>
