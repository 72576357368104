import { API_DATETIME_FORMAT } from '../../constants/date-format';
import { AddedEntity } from '../api-results/base.api-result';
import {
  EcosActionAnswerPictureResult,
  EcosActionAnswerResult,
  EcosActionAnswerTextResult,
  EcosActionAnswerVideoResult,
  EcosActionResult,
  EcosActionStatisticResult,
  EcosCorrectionGridResult,
  EcosSectionResult,
} from '../api-results/ecos-correction-grid.api-result';
import { EcosActionType } from '../enums/ecos.enum';
import { ActionButton } from '../interfaces/action-button.interface';
import {
  EcosActionAnswerPictureFormValue,
  EcosActionAnswerPicturePayload,
  EcosActionAnswerTextFormValue,
  EcosActionAnswerTextPayload,
  EcosActionAnswerVideoFormValue,
  EcosActionAnswerVideoPayload,
  EcosActionFormValue,
  EcosActionPayload,
  EcosSectionFormValue,
  EcosSectionPayload,
} from '../payloads/ecos-correction-grid.payload';
import moment from 'moment';
import { EcosSession } from './ecos-session.entity';
import { ExamContentEcos } from './exam-content-ecos.entity';
import { ExamContent } from './exam-content.entity';

export class EcosCorrectionGrid {
  ecosSections: EcosSection[];

  constructor(result: EcosCorrectionGridResult) {
    if (result.ecosSections)
      this.ecosSections = result.ecosSections.map((elt) => new EcosSection(elt));
  }
}

export class EcosSection {
  id: string;
  title: string;
  ecosActions: EcosAction[];

  examContentEcos: ExamContentEcos;

  constructor(result: EcosSectionResult) {
    this.id = result.id;
    this.title = result.title;
    if (result.ecosActions)
      this.ecosActions = result.ecosActions
        .map((elt) => new EcosAction(elt))
        .sort((a, b) => (a.createdAt > b.createdAt ? 1 : -1));

    if (result.examContentEcos) this.examContentEcos = new ExamContentEcos(result.examContentEcos);
  }

  toFormValue(): EcosSectionFormValue {
    return {
      title: this.title,
    };
  }

  static addedEntityToResult(
    payload: EcosSectionPayload,
    addedEntity: AddedEntity
  ): EcosSectionResult {
    return {
      id: addedEntity.id,
      title: payload.title,
      ecosActions: [],
      examContentEcos: undefined,
    };
  }

  static updatedEntityToResult(payload: EcosSectionPayload): Partial<EcosSectionResult> {
    return {
      title: payload.title,
    };
  }
}

export class EcosAction {
  id: string;
  type: EcosActionType;
  title: string;
  keywords: string[];
  maxGrade: number;
  comment: string;
  createdAt: Date;

  ecosActionAnswersPicture: EcosActionAnswerPicture[];
  ecosActionAnswersText: EcosActionAnswerText[];
  ecosActionAnswersVideo: EcosActionAnswerVideo[];

  actionButtons: ActionButton[];

  ecosSection: EcosSection;
  examContent: ExamContent;

  ecosActionStatistic: EcosActionStatistic;

  constructor(result: EcosActionResult) {
    this.id = result.id;
    this.type = result.type;
    this.title = result.title;
    this.keywords = result.keywords;
    this.maxGrade = result.maxGrade;
    this.comment = result.comment;
    this.createdAt = new Date(result.createdAt);

    if (result.ecosActionAnswersPicture)
      this.ecosActionAnswersPicture = result.ecosActionAnswersPicture.map(
        (elt) => new EcosActionAnswerPicture(elt)
      );
    if (result.ecosActionAnswersText)
      this.ecosActionAnswersText = result.ecosActionAnswersText.map(
        (elt) => new EcosActionAnswerText(elt)
      );
    if (result.ecosActionAnswersVideo)
      this.ecosActionAnswersVideo = result.ecosActionAnswersVideo.map(
        (elt) => new EcosActionAnswerVideo(elt)
      );

    if (result.ecosSection) this.ecosSection = new EcosSection(result.ecosSection);
    if (result.examContent) this.examContent = new ExamContent(result.examContent);
    if (result.ecosActionStatistic)
      this.ecosActionStatistic = new EcosActionStatistic(result.ecosActionStatistic);
  }

  // nnkitodo [v2later] afficher type avec pipe?
  get displayName() {
    return this.title;
  }

  toFormValue(): EcosActionFormValue {
    return {
      type: this.type,
      title: this.title,
      keywords: this.keywords?.map((keyword) => {
        return { keyword };
      }),
      maxGrade: this.maxGrade,
      comment: this.comment,
    };
  }

  static addedEntityToResult(
    payload: EcosActionPayload,
    addedEntity: AddedEntity
  ): EcosActionResult {
    return {
      id: addedEntity.id,
      type: payload.type,
      title: payload.title,
      keywords: payload.keywords,
      maxGrade: payload.maxGrade,
      comment: payload.comment,
      createdAt: moment(new Date()).format(API_DATETIME_FORMAT),
      ecosActionAnswersPicture: [],
      ecosActionAnswersText: [],
      ecosActionAnswersVideo: [],
      ecosSection: undefined,
      examContent: undefined,
      ecosActionStatistic: undefined,
    };
  }

  static updatedEntityToResult(payload: EcosActionPayload): Partial<EcosActionResult> {
    return {
      type: payload.type,
      title: payload.title,
      keywords: payload.keywords,
      maxGrade: payload.maxGrade,
      comment: payload.comment,
      createdAt: moment(new Date()).format(API_DATETIME_FORMAT),
    };
  }

  hasBeenMatched(ecosSession: EcosSession) {
    return !!ecosSession.actions.find(
      (sessionEcosAction) => sessionEcosAction.ecosActionId === this.id
    );
  }
}

export class EcosActionStatistic {
  totalAnsweredFeedbacks: number;
  totalFeedbacks: number;

  constructor(result: EcosActionStatisticResult) {
    this.totalAnsweredFeedbacks = result.totalAnsweredFeedbacks;
    this.totalFeedbacks = result.totalFeedbacks;
  }

  toResult(): EcosActionStatisticResult {
    return {
      totalAnsweredFeedbacks: this.totalAnsweredFeedbacks,
      totalFeedbacks: this.totalFeedbacks,
    };
  }
}

export abstract class EcosActionAnswer {
  id: string;

  constructor(result: EcosActionAnswerResult) {
    this.id = result.id;
  }
}

export class EcosActionAnswerText extends EcosActionAnswer {
  text: string;

  constructor(result: EcosActionAnswerTextResult) {
    super(result);
    this.text = result.text;
  }

  toFormValue(): EcosActionAnswerTextFormValue {
    return {
      text: this.text,
    };
  }

  static addedEntityToResult(
    payload: EcosActionAnswerTextPayload,
    addedEntity: AddedEntity
  ): EcosActionAnswerTextResult {
    return {
      id: addedEntity.id,
      text: payload.text,
    };
  }

  static updatedEntityToResult(
    payload: EcosActionAnswerTextPayload
  ): Partial<EcosActionAnswerTextResult> {
    return {
      text: payload.text,
    };
  }
}

export class EcosActionAnswerPicture extends EcosActionAnswer {
  picture: string;

  constructor(result: EcosActionAnswerPictureResult) {
    super(result);
    this.picture = result.picture;
  }

  toFormValue(): EcosActionAnswerPictureFormValue {
    return {
      picture: {
        file: null,
        path: this.picture,
      },
    };
  }

  static addedEntityToResult(
    addedEntity: EcosActionAnswerPictureResult
  ): EcosActionAnswerPictureResult {
    return {
      id: addedEntity.id,
      picture: addedEntity.picture,
    };
  }

  static updatedEntityToResult(
    result: EcosActionAnswerPictureResult
  ): Partial<EcosActionAnswerPictureResult> {
    return {
      picture: result.picture,
    };
  }
}

export class EcosActionAnswerVideo extends EcosActionAnswer {
  video: string;

  constructor(result: EcosActionAnswerVideoResult) {
    super(result);
    this.video = result.video;
  }

  toFormValue(): EcosActionAnswerVideoFormValue {
    return {
      video: this.video,
    };
  }

  static addedEntityToResult(
    payload: EcosActionAnswerVideoPayload,
    addedEntity: AddedEntity
  ): EcosActionAnswerVideoResult {
    return {
      id: addedEntity.id,
      video: payload.video,
    };
  }

  static updatedEntityToResult(
    payload: EcosActionAnswerVideoPayload
  ): Partial<EcosActionAnswerVideoResult> {
    return {
      video: payload.video,
    };
  }
}
