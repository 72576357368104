import { Pipe, PipeTransform } from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { USER_DATETIME_FORMAT } from '../constants/date-format';
import {
  patternLowercase,
  patternNumber,
  patternSpecialCharacter,
  patternTrue,
  patternUppercase,
} from '../utils/form-utils';

@Pipe({
  name: 'displayFormControlErrors',
})
export class DisplayFormControlErrorsPipe implements PipeTransform {
  constructor(private translate: TranslateService) {}

  transform(validationErrors: ValidationErrors): string {
    let errors = [];
    if (validationErrors.required) {
      errors.push(this.translate.instant('form.requis'));
    } else if (validationErrors.minlength) {
      errors.push(
        this.translate.instant('form.minlength', {
          minlength: validationErrors.minlength.requiredLength,
        })
      );
    } else if (validationErrors.maxlength) {
      errors.push($localize`Longueur maximum: ${validationErrors.maxlength.requiredLength}`);
    } else if (validationErrors.min) {
      errors.push(
        this.translate.instant('form.min', {
          val: validationErrors.min.min,
        })
      );
    } else if (validationErrors.max) {
      errors.push(
        this.translate.instant('form.max', {
          val: validationErrors.max.max,
        })
      );
    } else if (validationErrors.arrayMaxLength) {
      errors.push($localize`Nombre maximal autorisé: ${validationErrors.arrayMaxLength}`);
    } else if (validationErrors.email) {
      errors.push(this.translate.instant('settings.error_email'));
    } else if (validationErrors.pattern) {
      if (validationErrors.pattern.requiredPattern === `^${patternTrue}$`) {
        errors.push(this.translate.instant('form.requis'));
      }
      if (validationErrors.pattern.requiredPattern === `^${patternUppercase}$`) {
        errors.push($localize`Le mot de passe doit contenir au moins une majuscule`);
      }
      if (validationErrors.pattern.requiredPattern === `^${patternLowercase}$`) {
        errors.push($localize`Le mot de passe doit contenir au moins une minuscule`);
      }
      if (validationErrors.pattern.requiredPattern === `^${patternNumber}$`) {
        errors.push($localize`Le mot de passe doit contenir au moins un chiffre`);
      }
      if (validationErrors.pattern.requiredPattern === `^${patternSpecialCharacter}$`) {
        errors.push($localize`Le mot de passe doit contenir au moins un caractère spécial`);
      }
    } else if (validationErrors.notSame) {
      errors.push(this.translate.instant('settings.mdp_same'));
    } else if (validationErrors.dateNotAfter) {
      errors.push(
        $localize`La date doit être après le ${moment(validationErrors.dateNotAfter.date).format(
          USER_DATETIME_FORMAT
        )}`
      );
    } else if (validationErrors.noSupport) {
      errors.push($localize`Aucun support sélectionné`);
    } else if (validationErrors.textInputNotTheSame) {
      errors.push($localize`Le texte de confirmation ne correspond pas`);
    } else if (validationErrors.phoneNumberError) {
      if (validationErrors.phoneNumberError === 1) {
        errors.push($localize`Le préfixe est invalide`);
      } else if (validationErrors.phoneNumberError === 2) {
        errors.push($localize`Le numéro est trop court`);
      } else if (validationErrors.phoneNumberError === 3) {
        errors.push($localize`Le numéro est trop long`);
      } else {
        errors.push($localize`Le numéro est invalide`);
      }
    }
    return errors.join(', ');
  }
}
