import { environment } from '../../../../environments/environment';
import { API_DATETIME_FORMAT } from '../../constants/date-format';
import {
  FolderResult,
  FolderStatisticResult,
  UpdateFolderLCAResult,
} from '../api-results/folder.api-result';
import { UpdateQuestionFileResult, QuestionResult } from '../api-results/question.api-result';
import { FolderType } from '../enums/folder.enums';
import { FolderPublicationStatus } from '../enums/publication-status.enums';
import { FolderFormValue, UpdateFolderPayload } from '../payloads/folder.payload';
import { Correction } from './correction.entity';
import { Course } from './course.entity';
import { ExamContentSession } from './exam-content-session.entity';
import { ExamContent } from './exam-content.entity';
import { Exam } from './exam.entity';
import { FolderQuestion } from './folder-question.entity';
import { FolderSession } from './folder-session.entity';
import { Notion } from './notion.entity';
import { QuestionStatistic } from './question.entity';
import { Rate } from './rate.entity';
import { Specialty } from './specialty.entity';
import { StatementContent } from './statement-content.entity';
import { Writer } from './writer.entity';
import moment from 'moment';
import { uniqBy } from 'lodash';
import { StatementContentType } from '../enums/statement-content.enums';

export class Folder {
  id: string;
  publicationStatus: FolderPublicationStatus;
  type: FolderType;
  isR2C: boolean;
  title: string;
  statement: string;
  lca: string;
  folderStatementContents: StatementContent[];

  author: Writer;
  createdAt: Date;
  updatedBy: Writer;
  updatedAt: Date;

  isPaid: boolean;
  priority: number;

  maxGrade: number;
  averageRating: number;
  totalFolderQuestions: number;

  folderStatistic: FolderStatistic;

  courses: Course[];
  examContent: ExamContent;
  specialties: Specialty[];

  isBookmarked: boolean;

  folderQuestions: FolderQuestion[];
  studentRate: Rate;

  // nnkitodo [v2later] eurk
  isSelected: boolean;

  constructor(result: FolderResult) {
    this.id = result.id;
    this.publicationStatus = result.publicationStatus;
    this.type = result.type;
    this.isR2C = result.isR2C;
    this.title = result.title;
    this.statement = result.statement;
    this.lca = result.lca;
    if (result.folderStatementContents)
      this.folderStatementContents = result.folderStatementContents.map(
        (elt) => new StatementContent(elt)
      );

    if (result.author) this.author = new Writer(result.author);
    if (result.createdAt) this.createdAt = new Date(result.createdAt);
    if (result.updatedBy) this.updatedBy = new Writer(result.updatedBy);
    if (result.updatedAt) this.updatedAt = new Date(result.updatedAt);

    this.isPaid = result.isPaid;
    this.priority = result.priority;

    this.maxGrade = result.maxGrade;
    this.averageRating = result.averageRating;
    this.totalFolderQuestions = result.totalFolderQuestions;

    if (result.folderStatistic) {
      this.folderStatistic = new FolderStatistic(result.folderStatistic);
    }

    if (result.courses) this.courses = result.courses.map((elt) => new Course(elt));
    if (result.examContent) this.examContent = new ExamContent(result.examContent);
    if (result.specialties) this.specialties = result.specialties.map((elt) => new Specialty(elt));

    this.isBookmarked = result.isBookmarked;

    if (result.folderQuestions) {
      this.folderQuestions = result.folderQuestions
        .map((elt) => new FolderQuestion(elt))
        .sort((a, b) => (a.priority < b.priority ? -1 : 1));

      this.folderQuestions.forEach((folderQuestion, index) => (folderQuestion.index = index));

      if (result.studentRate) this.studentRate = new Rate(result.studentRate);

      if (this.courses === undefined) {
        this.courses = [];
        this.folderQuestions.forEach((folderQuestion) => {
          folderQuestion.question.notions?.forEach((notion) => {
            if (notion.course) {
              this.courses.push(notion.course);
            }
          });
        });
        this.courses = uniqBy(this.courses, 'id')
          .sort((a, b) => (a.ecniNumber > b.ecniNumber ? 1 : -1))
          .sort((a: Course, b: Course) => (a.ecniNumber > b.ecniNumber ? 1 : -1));
      }
    }

    this.isSelected = result.isSelected;
  }

  get displayName() {
    return this.title;
  }

  // nnkitodo [v2later] enlever cette merde dupliquée
  get embeddedStatementContents() {
    if (!this.folderStatementContents) return [];
    return this.folderStatementContents.filter(
      (statementContent) => statementContent.type === StatementContentType.EMBEDDED
    );
  }

  get pdfStatementContents() {
    if (!this.folderStatementContents) return [];
    return this.folderStatementContents.filter(
      (statementContent) =>
        statementContent.type === StatementContentType.FILE &&
        statementContent.file?.includes('.pdf')
    );
  }

  get pictureStatementContents() {
    if (!this.folderStatementContents) return [];
    return this.folderStatementContents.filter(
      (statementContent) =>
        statementContent.type === StatementContentType.FILE &&
        !statementContent.file?.includes('.pdf')
    );
  }

  toFormValue(): FolderFormValue {
    return {
      title: this.title,
      type: this.type,
      statement: this.statement,
      lca: {
        file: null,
        path: this.lca,
      },
    };
  }

  toResult(): FolderResult {
    return {
      id: this.id,
      publicationStatus: this.publicationStatus,
      type: this.type,
      isR2C: this.isR2C,
      title: this.title,
      statement: this.statement,
      lca: this.lca,
      folderStatementContents: this.folderStatementContents?.map((elt) => elt.toResult()),
      author: this.author?.toResult(),
      createdAt: moment(this.createdAt).format(API_DATETIME_FORMAT),
      updatedBy: this.updatedBy?.toResult(),
      updatedAt: moment(this.updatedAt).format(API_DATETIME_FORMAT),
      isPaid: this.isPaid,
      priority: this.priority,
      maxGrade: this.maxGrade,
      averageRating: this.averageRating,
      totalFolderQuestions: this.totalFolderQuestions,
      folderStatistic: this.folderStatistic?.toResult(),
      courses: this.courses?.map((elt) => elt.toResult()),
      examContent: this.examContent?.toResult(),
      specialties: this.specialties?.map((elt) => elt.toResult()),
      isBookmarked: this.isBookmarked,
      folderQuestions: this.folderQuestions?.map((elt) => elt.toResult()),
      studentRate: this.studentRate?.toResult(),
      isSelected: this.isSelected,
    };
  }

  static updatedEntityToResult(
    payload: UpdateFolderPayload,
    updateLCAResult: UpdateFolderLCAResult
  ): Partial<FolderResult> {
    return {
      title: payload.title,
      statement: payload.statement,
      lca: updateLCAResult.lca,
    };
  }
}

export class FolderStatistic {
  totalAnsweredFeedbacks: number;
  totalFeedbacks: number;
  totalParticipants: number;

  constructor(result: FolderStatisticResult) {
    this.totalAnsweredFeedbacks = result.totalAnsweredFeedbacks;
    this.totalFeedbacks = result.totalFeedbacks;
    this.totalParticipants = result.totalParticipants;
  }

  toResult(): FolderStatisticResult {
    return {
      totalAnsweredFeedbacks: this.totalAnsweredFeedbacks,
      totalFeedbacks: this.totalFeedbacks,
      totalParticipants: this.totalParticipants,
    };
  }
}
