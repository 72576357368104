import { environment } from '../../../../environments/environment';
import {
  FrontAppVersionResult,
  LastDeployedVersionsResult,
} from '../api-results/front-app-version.api-result';
import {
  FrontAppVersionFormValue,
  FrontAppVersionPayload,
} from '../payloads/front-app-version.payload';

export class FrontAppVersion {
  id: string;
  major: number;
  minor: number;
  patch: number;
  content: string;
  isBreaking: boolean;
  isMobile: boolean;
  isInAppleApprovalProcess: boolean;
  createdAt: Date;

  constructor(result: FrontAppVersionResult) {
    this.id = result.id;
    this.major = result.major;
    this.minor = result.minor;
    this.patch = result.patch;
    this.content = result.content;
    this.isBreaking = result.isBreaking;
    this.isMobile = result.isMobile;
    this.isInAppleApprovalProcess = result.isInAppleApprovalProcess;
    if (result.createdAt) {
      this.createdAt = new Date(result.createdAt);
    }
  }

  get displayName() {
    return `${this.major}.${this.minor}.${this.patch}`;
  }

  toFormValue(): FrontAppVersionFormValue {
    return {
      major: this.major.toString(),
      minor: this.minor.toString(),
      patch: this.patch.toString(),
      content: this.content,
      isBreaking: this.isBreaking,
      isMobile: this.isMobile,
      isInAppleApprovalProcess: this.isInAppleApprovalProcess,
    };
  }

  static updatedEntityToResult(payload: FrontAppVersionPayload): Partial<FrontAppVersionResult> {
    return {
      major: payload.major,
      minor: payload.minor,
      patch: payload.patch,
      content: payload.content,
      isBreaking: payload.isBreaking,
      isMobile: payload.isMobile,
      isInAppleApprovalProcess: payload.isInAppleApprovalProcess,
    };
  }

  isUpToDate(currentInstalledVersion: FrontAppVersion) {
    if (currentInstalledVersion.major < this.major) return false;
    if (currentInstalledVersion.major === this.major) {
      if (currentInstalledVersion.minor < this.minor) return false;
      if (currentInstalledVersion.minor === this.minor) {
        if (currentInstalledVersion.patch < this.patch) return false;
      }
    }
    return true;
  }
}

export class LastDeployedVersions {
  lastVersion: FrontAppVersion;
  lastMobileVersion: FrontAppVersion;
  lastBreakingVersion: FrontAppVersion;

  constructor(result: LastDeployedVersionsResult) {
    if (result.lastVersion) this.lastVersion = new FrontAppVersion(result.lastVersion);
    if (result.lastMobileVersion)
      this.lastMobileVersion = new FrontAppVersion(result.lastMobileVersion);
    if (result.lastBreakingVersion)
      this.lastBreakingVersion = new FrontAppVersion(result.lastBreakingVersion);
  }
}
