import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';

import { throwError, Observable, race } from 'rxjs';
import { catchError, first, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { Store } from '@ngrx/store';
import * as AuthSelectors from '../state/auth-state/auth.selectors';
import * as AuthActions from '../state/auth-state/auth.actions';
import { Actions, ofType } from '@ngrx/effects';
import { HttpAuthService } from '../services/http/http-auth.service';
import { Student } from '../../shared/models/entities/student.entity';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private actions$: Actions,
    private store: Store,
    private httpAuthService: HttpAuthService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.store.select(AuthSelectors.token).pipe(
      first(),
      withLatestFrom(this.store.select(AuthSelectors.impersonateUser)),
      switchMap(([token, impersonateUser]) => {
        if (token) {
          request = this.addToken(request, token);
        }
        if (impersonateUser) {
          request = this.impersonateUser(request, impersonateUser);
        }
        return next.handle(request);
      }),
      // nnkitodo [v2later] mieux gérer erreur par défaut
      // nnkitodo [v2later] essayer de catcher les 500 : erreur sur setaspaid writer bill sur folder déjà payé, on n'a pas d'erreur qui pop
      catchError((error: HttpEvent<any>) => {
        if (error instanceof HttpErrorResponse) {
          if (error.status === 401 && !this.byPassRequestFor401ErrorHandling(request)) {
            return this.handle401Error(request, next, error);
          } else if (error.status === 503) {
            return this.handle503Error(request, next, error);
          } else {
            console.error(`[HTTP ERROR ${error.status}]`, error);
          }
        } else {
          console.error('[UNDEFINED REQUEST ERROR]', error);
        }
        return throwError(() => error);
      })
    );
  }

  private addToken(request: HttpRequest<any>, token: string) {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  private impersonateUser(request: HttpRequest<any>, student: Student) {
    let params = request.params;
    params = params.set('_switch_user', student.username);
    return request.clone({
      params: params,
    });
  }

  private byPassRequestFor401ErrorHandling(request: HttpRequest<any>) {
    return request.url.includes(this.httpAuthService.refreshAuthTokenEndpoint);
  }

  // nnkitodo [v2later auth] voir si on peut utiliser un exhaustmap pour simplifier le process (cf global entities)
  private handle401Error(
    request: HttpRequest<any>,
    next: HttpHandler,
    httpError: HttpErrorResponse
  ): Observable<HttpEvent<any>> {
    return this.store.select(AuthSelectors.selectAuth).pipe(
      first(),
      tap((authState) => {
        if (!authState.refreshingToken && authState.refreshToken) {
          this.store.dispatch(
            AuthActions.refreshToken({
              refreshToken: authState.refreshToken,
            })
          );
        }
      }),
      switchMap((authState) => {
        if (authState.refreshToken) {
          return race(
            this.actions$.pipe(
              ofType(AuthActions.refreshTokenSucceed),
              first(),
              switchMap((action) => next.handle(this.addToken(request, action.result.token)))
            ),
            this.actions$.pipe(
              ofType(AuthActions.refreshTokenFailed),
              first(),
              switchMap(() => throwError(() => httpError))
            )
          );
        } else {
          return throwError(() => httpError);
        }
      })
    );
  }

  private handle503Error(
    request: HttpRequest<any>,
    next: HttpHandler,
    httpError: HttpErrorResponse
  ): Observable<HttpEvent<any>> {
    return this.store.select(AuthSelectors.selectAuth).pipe(
      first(),
      tap((authState) => {
        if (!authState.isInMaintenance) {
          this.store.dispatch(
            AuthActions.triggerMaintenance({ message: httpError.error?.message })
          );
        }
      }),
      switchMap(() => {
        return throwError(() => httpError);
      })
    );
  }
}
