import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {
  ModalCreateChallengeComponent,
  ModalCreateChallengeData,
} from '../../shared/modals/modal-create-challenge/modal-create-challenge.component';
import { ModalPremiumPlusComponent } from '../../shared/modals/modal-premium-plus/modal-premium-plus.component';
import { ComponentType } from 'ngx-toastr';
import { first, map } from 'rxjs';
import {
  WriterConfirmModalComponent,
  WriterConfirmModalData,
  WriterConfirmModalResult,
} from '../../modules/logged-area/writer-area/writer-shared/generic/modals/writer-confirm-modal/writer-confirm-modal.component';
import {
  ModalUserProfileComponent,
  ModalUserProfileData,
} from '../../shared/modals/modal-user-profile/modal-user-profile.component';
import { Student } from '../../shared/models/entities/student.entity';
import { Store } from '@ngrx/store';
import * as AuthSelectors from '../../core/state/auth-state/auth.selectors';
import {
  WriterMessageModalComponent,
  WriterMessageModalData,
  WriterMessageModalResult,
} from '../../modules/logged-area/writer-area/writer-shared/generic/modals/writer-message-modal/writer-message-modal.component';

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  stackedModals: MatDialogRef<any>[] = [];

  constructor(private matDialog: MatDialog, private store: Store) {}

  openModalChallenge(user?: Student) {
    this.store
      .select(AuthSelectors.isPremiumPlus)
      .pipe(first())
      .subscribe((isPremiumPlus) => {
        if (!isPremiumPlus) {
          this.matDialog.open(ModalPremiumPlusComponent, {
            maxWidth: '100vw',
          });
          return;
        }

        let modalData: ModalCreateChallengeData;
        if (user) {
          modalData = {
            usersSelected: [user],
          };
        }

        this.matDialog.open(ModalCreateChallengeComponent, {
          maxWidth: '100vw',
          data: modalData,
        });
      });
  }

  openModalStudentProfile(studentId: string) {
    const modalData: ModalUserProfileData = { studentId };
    this.matDialog.open(ModalUserProfileComponent, {
      maxWidth: '100vw',
      data: modalData,
    });
  }

  // nnkitodo [v2later] utiliser partout
  openModalPremiumPlus() {
    this.matDialog.open(ModalPremiumPlusComponent);
  }

  addModalToStack(modal: MatDialogRef<any>) {
    this.stackedModals.push(modal);

    setTimeout(() => {
      if (modal.componentInstance.modalId === 'modalUserProfile') {
        this.cleanStackedModals();
      }
    });
  }

  removeModalFromStack(modalToRemove: MatDialogRef<any>) {
    this.stackedModals = this.stackedModals.filter((modal) => {
      return modal.id !== modalToRemove.id;
    });
  }

  cleanStackedModals() {
    let modalProfileCount = 0;
    this.stackedModals.forEach((modal) => {
      if (modal.componentInstance.modalId === 'modalUserProfile') {
        modalProfileCount++;
      }
    });

    if (modalProfileCount > 1) {
      let stopCleaning = false;
      this.stackedModals.forEach((modal) => {
        if (!stopCleaning) {
          modal.close();
        }
        if (modal.componentInstance.modalId === 'modalUserProfile') {
          stopCleaning = true;
        }
      });
    }
  }

  open(modalComponent: ComponentType<any>, data?: any) {
    return this.matDialog.open(modalComponent, {
      maxWidth: '100vw',
      data,
    });
  }

  confirm(message?: string) {
    const modalData: WriterConfirmModalData = {
      message,
    };
    const dialogRef = this.open(WriterConfirmModalComponent, modalData);

    return dialogRef.afterClosed().pipe(
      map((result: WriterConfirmModalResult) => {
        if (result?.confirm) {
          return true;
        } else {
          return false;
        }
      })
    );
  }

  message(title: string, message: string) {
    const modalData: WriterMessageModalData = {
      title,
      message,
    };
    const dialogRef = this.open(WriterMessageModalComponent, modalData);

    return dialogRef.afterClosed().pipe(
      map((result: WriterMessageModalResult) => {
        if (result?.confirm) {
          return true;
        } else {
          return false;
        }
      })
    );
  }
}
