import { Injectable, NgZone } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

import { Device } from '@capacitor/device';
import { PushNotifications } from '@capacitor/push-notifications';
import { filter, tap } from 'rxjs/operators';

import { environment } from '../../../environments/environment';

import {
  ModalAcceptNotificationsComponent,
  ModalAcceptNotificationsResult,
} from '../../shared/modals/modal-accept-notifications/modal-accept-notifications.component';
import { HttpNotificationService } from './http/http-notification.service';
import { IsApplicationService } from './is-application.service';

import { LocaleKeys } from '../../shared/utils/locale-keys';
import { HttpDeviceTokenService } from './http/http-device-token.service';
import { AddDeviceTokenPayload } from '../../shared/models/payloads/device-token.payload';
import { DeviceTokenType } from '../../shared/models/enums/device-token.enum';
import * as AuthSelectors from '../state/auth-state/auth.selectors';
import * as AuthActions from '../state/auth-state/auth.actions';
import { Store } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';
import { HttpNotificationCampaignService } from './http/http-notification-campaign.service';
import {
  NotificationTemplate,
  NotificationCampaignTargetedActiveUsers,
} from '../../shared/models/enums/notification.enums';
import { SubscriptionTier } from '../../shared/models/enums/subscription.enums';

@Injectable({
  providedIn: 'root',
})
export class AppPushNotificationsService {
  registrationId: string;

  constructor(
    private isApplicationService: IsApplicationService,
    private matDialog: MatDialog,
    private httpDeviceTokenService: HttpDeviceTokenService,
    private router: Router,
    private ngZone: NgZone,
    private store: Store
  ) {}

  init() {
    Device.getInfo().then((infos) => {
      if (infos.platform !== 'web') {
        this.store
          .select(AuthSelectors.isLogged)
          .pipe(filter((isLogged) => isLogged))
          .subscribe(() => {
            this.initNotifications();
          });
        this.initPushListeners();
      }
    });
  }

  initNotifications() {
    if (localStorage.getItem(LocaleKeys.notifsDeviceRegistered) === '1') {
      this.acceptNotifications();
    } else if (localStorage.getItem(LocaleKeys.notifsDeviceRegistered) !== '-1') {
      this.askForNotifications();
    } else {
      // les notifs ont déjà été refusées : on regarde c'était quand et si > 15 jours on redemande
      const lastNotificationsRefusedDate = localStorage.getItem(LocaleKeys.notifsLastRefusedDate);
      const now = new Date();
      if (lastNotificationsRefusedDate) {
        const refusedDate = new Date(parseInt(lastNotificationsRefusedDate, 10));

        const diffTime = Math.abs(refusedDate.getTime() - now.getTime());
        const diffDays = diffTime / (1000 * 60 * 60 * 24);

        if (diffDays > 15) {
          this.askForNotifications();
        }
      } else {
        this.askForNotifications();
      }
    }
  }

  askForNotifications() {
    const dialogRef = this.matDialog.open(ModalAcceptNotificationsComponent, {
      maxWidth: '100vw',
    });

    dialogRef.afterClosed().subscribe((result: ModalAcceptNotificationsResult) => {
      if (result) {
        if (result.accept) {
          this.acceptNotifications();
        } else {
          this.refuseNotifications();
        }
      }
    });
  }

  refuseNotifications() {
    const now = new Date();
    localStorage.setItem(LocaleKeys.notifsDeviceRegistered, '-1');
    localStorage.setItem(LocaleKeys.notifsLastRefusedDate, now.getTime().toString());
  }

  acceptNotifications() {
    if (!this.registrationId) {
      this.requestPermissionOnDevice();
    } else {
      this.registerDevice();
    }
  }

  requestPermissionOnDevice() {
    PushNotifications.requestPermissions().then((result) => {
      if (result?.receive === 'granted') {
        PushNotifications.addListener('registration', (token) => {
          localStorage.setItem(LocaleKeys.notifsDeviceRegistered, '1');

          this.registrationId = token.value;

          this.registerDevice();

          if (this.isApplicationService.isAppAndroid()) {
            PushNotifications.createChannel({
              id: environment.pushChannel,
              name: environment.pushName,
              description: environment.pushName,
              importance: 5,
              visibility: 1,
              lights: true,
              vibration: true,
              sound: 'default',
            });
          }

          PushNotifications.removeAllDeliveredNotifications();
        });

        setTimeout(() => {
          if (!this.registrationId) {
            PushNotifications.register();
          }
        }, 500);
      }
    });
  }

  initPushListeners() {
    PushNotifications.addListener('pushNotificationActionPerformed', (actionPerformed) => {
      this.ngZone.run(() => {
        let redirectUrl: string;
        if (actionPerformed.notification?.data?.aps) {
          redirectUrl = actionPerformed.notification.data.aps.redirectUrl;
        } else if (actionPerformed.notification?.data?.redirectUrl) {
          redirectUrl = actionPerformed.notification.data.redirectUrl;
        }

        if (redirectUrl) {
          this.redirectAfterPushNotification(redirectUrl.split(environment.domain).pop());
        }
      });
    });
  }

  registerDevice() {
    Device.getInfo().then((info) => {
      const platform = info.platform;

      if (platform === 'ios') {
        this.httpDeviceTokenService
          .add(new AddDeviceTokenPayload(this.registrationId, DeviceTokenType.IOS))
          .subscribe();
      } else if (platform === 'android') {
        this.httpDeviceTokenService
          .add(new AddDeviceTokenPayload(this.registrationId, DeviceTokenType.ANDROID))
          .subscribe();
      }
    });
  }

  redirectAfterPushNotification(slug: string) {
    this.router.navigateByUrl(slug);
  }
}
