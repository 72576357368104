import moment from 'moment';
import { API_DATETIME_FORMAT } from '../../constants/date-format';
import { VoucherResult } from '../api-results/voucher.api-result';
import { UpdateVoucherPayload, VoucherFormValue } from '../payloads/voucher.payload';
import { Offer } from './offer.entity';
import { environment } from '../../../../environments/environment';

export class Voucher {
  id: string;
  name: string;
  code: string;
  discount: number;
  offers: Offer[];

  startAt: Date;
  endAt: Date;
  createdAt: Date;

  constructor(result: VoucherResult) {
    this.id = result.id;
    this.name = result.name;
    this.code = result.code;
    this.discount = result.discount;
    if (result.offers) {
      this.offers = result.offers.map((elt) => new Offer(elt));
    }
    if (result.startAt) {
      this.startAt = new Date(result.startAt);
    }
    if (result.endAt) {
      this.endAt = new Date(result.endAt);
    }
    if (result.createdAt) {
      this.createdAt = new Date(result.createdAt);
    }
  }

  toResult(): VoucherResult {
    return {
      id: this.id,
      name: this.name,
      code: this.code,
      discount: this.discount,
      offers: this.offers?.map((elt) => elt.toResult()),
      startAt: this.startAt ? moment(this.startAt).format(API_DATETIME_FORMAT) : null,
      endAt: this.endAt ? moment(this.startAt).format(API_DATETIME_FORMAT) : null,
      createdAt: this.createdAt ? moment(this.startAt).format(API_DATETIME_FORMAT) : null,
    };
  }

  toFormValue(): VoucherFormValue {
    return {
      name: this.name,
      code: this.code,
      discount: this.discount.toString(),
      offers: this.offers,
      startAt: this.startAt,
      endAt: this.endAt,
    };
  }

  static updatedEntityToResult(payload: UpdateVoucherPayload): Partial<VoucherResult> {
    return {
      name: payload.name,
      code: payload.code,
      discount: payload.discount,
      startAt: payload.startAt,
      endAt: payload.endAt,
    };
  }
}
