import { Injectable } from '@angular/core';

import { environment } from '../../../environments/environment';

import { Store } from '@ngrx/store';
import * as AuthSelectors from '../state/auth-state/auth.selectors';
import { first } from 'rxjs';
import Pusher from 'pusher-js';
import { SessionType } from '../../shared/models/enums/session.enum';

@Injectable({
  providedIn: 'root',
})
export class PusherService {
  private pusher: Pusher;
  private channelName: string;

  constructor(private store: Store) {
    this.store
      .select(AuthSelectors.token)
      .pipe(first())
      .subscribe((token) => {
        this.pusher = new Pusher(environment.pusher_id, {
          userAuthentication: {
            endpoint: `${environment.api_base_url}/pusher/auth`,
            headers: { Authorization: `Bearer ${token}` },
            transport: 'ajax',
          },
          channelAuthorization: {
            endpoint: `${environment.api_base_url}/pusher/auth`,
            headers: { Authorization: `Bearer ${token}` },
            transport: 'ajax',
          },
          cluster: 'eu',
        });
      });
  }

  subscribe(sessionType: SessionType, sessionId: string, userId?: string) {
    this.channelName = `presence-${sessionType.toLowerCase()}_session-${sessionId}`;
    if (sessionType === SessionType.CHALLENGE) {
      this.channelName += `-${userId}`;
    }
    this.pusher.subscribe(this.channelName);
  }

  unsubscribe() {
    this.pusher.unsubscribe(this.channelName);
  }
}
