import { environment } from '../../../../environments/environment';
import { API_DATETIME_FORMAT } from '../../constants/date-format';
import { AddedEntity } from '../api-results/base.api-result';
import { NotionResult } from '../api-results/notion.api-result';
import { NotionRank } from '../enums/notion.enums';
import { AddNotionPayload, NotionFormValue, UpdateNotionPayload } from '../payloads/notion.payload';
import { Chapter, ChapterFrontEnd } from './chapter.entity';
import { Course } from './course.entity';
import moment from 'moment';

export class Notion {
  id: string;
  name: string;
  createdAt: Date;
  rank: NotionRank;

  chapter: Chapter;
  course: Course;

  constructor(result: NotionResult) {
    this.id = result.id;
    this.name = result.name;
    if (result.createdAt) {
      this.createdAt = new Date(result.createdAt);
    }
    this.rank = result.rank;

    if (result.chapter) {
      this.chapter = new Chapter(result.chapter);
    }
    if (result.course) {
      this.course = new Course(result.course);
    }
  }

  get displayName() {
    return `${this.rank} - ${this.name}`;
  }

  toFormValue(): NotionFormValue {
    return {
      chapter: this.chapter,
      name: this.name,
      rank: this.rank,
    };
  }

  toResult(): NotionResult {
    return {
      id: this.id,
      name: this.name,
      createdAt: moment(this.createdAt).format(API_DATETIME_FORMAT),
      rank: this.rank,
      chapter: this.chapter?.toResult(),
      course: this.course?.toResult(),
    };
  }

  static addedEntityToResult(
    payload: AddNotionPayload,
    formValue: Partial<NotionFormValue>,
    addedEntity: AddedEntity
  ): NotionResult {
    return {
      id: addedEntity.id,
      name: payload.name,
      createdAt: moment(new Date()).format(API_DATETIME_FORMAT),
      rank: payload.rank,
      chapter: formValue.chapter.toResult(),
      course: undefined,
    };
  }

  static updatedEntityToResult(payload: UpdateNotionPayload): Partial<NotionResult> {
    return {
      name: payload.name,
      rank: payload.rank,
    };
  }
}
