import { environment } from '../../../../environments/environment';
import { API_DATETIME_FORMAT } from '../../constants/date-format';
import { OfferResult } from '../api-results/offer.api-result';
import { OfferTier } from '../enums/offer.enums';
import { OfferFormValue, UpdateOfferPayload } from '../payloads/offer.payload';
import moment from 'moment';

export class Offer {
  id: string;
  name: string;
  tier: OfferTier;
  price: number;
  duration: number;
  availablePaymentInstallments: number[];
  isCustom: boolean;

  startAt: Date;
  endAt: Date;
  createdAt: Date;

  constructor(result: OfferResult) {
    this.id = result.id;
    this.name = result.name;
    if (this.name === 'Custom offer') this.name = '';
    this.tier = result.tier;
    this.price = result.price;
    this.duration = result.duration;
    this.availablePaymentInstallments = result.availablePaymentInstallments;
    this.isCustom = result.isCustom;

    if (result.startAt) {
      this.startAt = new Date(result.startAt);
    }
    if (result.endAt) {
      this.endAt = new Date(result.endAt);
    }
    if (result.createdAt) {
      this.createdAt = new Date(result.createdAt);
    }
  }

  get displayName() {
    return this.name;
  }

  toFormValue(): OfferFormValue {
    return {
      name: this.name,
      tier: this.tier,
      price: this.price.toString(),
      duration: this.duration.toString(),
      availablePaymentInstallments: this.availablePaymentInstallments.filter(
        (installment) => installment > 1
      ),
      startAt: this.startAt,
      endAt: this.endAt,
    };
  }

  toResult(): OfferResult {
    return {
      id: this.id,
      name: this.name,
      tier: this.tier,
      price: this.price,
      duration: this.duration,
      availablePaymentInstallments: this.availablePaymentInstallments,
      isCustom: this.isCustom,
      startAt: this.startAt ? moment(this.startAt).format(API_DATETIME_FORMAT) : null,
      endAt: this.endAt ? moment(this.endAt).format(API_DATETIME_FORMAT) : null,
      createdAt: this.createdAt ? moment(this.createdAt).format(API_DATETIME_FORMAT) : null,
    };
  }

  static updatedEntityToResult(payload: UpdateOfferPayload): Partial<OfferResult> {
    return {
      name: payload.name,
      price: payload.price,
      duration: payload.duration,
      availablePaymentInstallments: payload.availablePaymentInstallments,
      isCustom: payload.isCustom,
      startAt: payload.startAt,
      endAt: payload.endAt,
    };
  }
}
