import moment from 'moment';
import { API_DATETIME_FORMAT } from '../../constants/date-format';
import { ExamResult, ExamStatisticResult } from '../api-results/exam.api-result';
import { ExamDifficulty } from '../enums/exam.enums';
import { ExamPublicationStatus } from '../enums/publication-status.enums';
import { SubscriptionTier } from '../enums/subscription.enums';
import { ExamFormValue, UpdateExamPayload } from '../payloads/exam.payload';
import { ExamContent } from './exam-content.entity';
import { ExamType } from './exam-type.entity';
import { Writer } from './writer.entity';
import { environment } from '../../../../environments/environment';
import { Specialty } from './specialty.entity';
import { getCountdown } from '../../utils/time';
import { ExamSession } from './exam-session.entity';
import { uniqBy } from 'lodash';
import { compact } from 'lodash';
import { concat } from 'lodash';
import { flatten } from 'lodash';
import { Course } from './course.entity';
import { ExamContentEcos } from './exam-content-ecos.entity';
import { cpuUsage } from 'process';

export class Exam {
  id: string;
  title: string;
  theme: string;

  difficulty: ExamDifficulty;
  isR2C: boolean;
  type: ExamType;
  publicationStatus: ExamPublicationStatus;

  author: Writer;
  createdAt: Date;
  updatedBy: Writer;
  updatedAt: Date;
  publishAt: Date;

  authorizedGrantedSubscription: SubscriptionTier;
  freeStartAt: Date;
  freeEndAt: Date;
  correctionStartAt: Date;
  correctionEndAt: Date;
  rankingAvailableAt: Date;

  maxGrade: number;
  averageRating: number;
  totalExamContents: number;
  totalFolders: number;
  totalIsolatedQuestions: number;
  totalFolderQuestions: number;

  totalTimerDuration: number;
  examStatistic: ExamStatistic;

  examContents: ExamContent[];

  mainSpecialties: Specialty[];
  otherSpecialties: Specialty[];
  courses: Course[];

  examSession: ExamSession;

  examContentEcos: ExamContentEcos;

  constructor(result: ExamResult) {
    this.id = result.id;
    this.title = result.title;
    this.theme = result.theme;

    this.difficulty = result.difficulty;
    this.isR2C = result.isR2C;
    if (result.type) this.type = new ExamType(result.type);
    this.publicationStatus = result.publicationStatus;

    if (result.author) this.author = new Writer(result.author);
    if (result.createdAt) this.createdAt = new Date(result.createdAt);
    if (result.updatedBy) this.updatedBy = new Writer(result.updatedBy);
    if (result.updatedAt) this.updatedAt = new Date(result.updatedAt);
    // nnkitodo [v2later] fix api list_folders_that_should_or_should_not_be_paid_of_a_writer
    if ((result as any).publishedAt && !result.publishAt)
      result.publishAt = (result as any).publishedAt;
    if (result.publishAt) this.publishAt = new Date(result.publishAt);

    this.authorizedGrantedSubscription = result.authorizedGrantedSubscription;

    if (result.freeStartAt) this.freeStartAt = new Date(result.freeStartAt);
    if (result.freeEndAt) this.freeEndAt = new Date(result.freeEndAt);
    if (result.correctionStartAt) this.correctionStartAt = new Date(result.correctionStartAt);
    if (result.correctionEndAt) this.correctionEndAt = new Date(result.correctionEndAt);
    if (result.rankingAvailableAt) this.rankingAvailableAt = new Date(result.rankingAvailableAt);

    this.maxGrade = result.maxGrade;
    this.averageRating = result.averageRating;
    this.totalExamContents = result.totalExamContents;
    this.totalFolders = result.totalFolders;
    this.totalIsolatedQuestions = result.totalIsolatedQuestions;
    this.totalFolderQuestions = result.totalFolderQuestions;

    this.totalTimerDuration = result.totalTimerDuration;
    if (result.examStatistic) this.examStatistic = new ExamStatistic(result.examStatistic);

    if (result.examContents)
      this.examContents = result.examContents
        .map((elt) => new ExamContent(elt))
        .sort((a, b) => a.priority - b.priority);

    if (result.mainSpecialties)
      this.mainSpecialties = result.mainSpecialties.map((elt) => new Specialty(elt));
    if (result.otherSpecialties)
      this.otherSpecialties = result.otherSpecialties.map((elt) => new Specialty(elt));

    if (result.examSession) this.examSession = new ExamSession(result.examSession);

    if (this.examContents?.length) {
      this.totalFolderQuestions = this.examContents.reduce(
        (sum, examContent) => sum + examContent.totalFolderQuestions,
        0
      );

      this.totalIsolatedQuestions = this.examContents.reduce(
        (sum, examContent) => sum + examContent.totalIsolatedQuestions,
        0
      );
      this.totalFolders = this.examContents.reduce(
        (sum, examContent) => sum + examContent.folders?.length,
        0
      );

      this.totalTimerDuration = this.examContents.reduce(
        (sum, examContent) => sum + examContent.timerDuration,
        0
      );
    }

    if (
      this.examContents?.length &&
      this.mainSpecialties === undefined &&
      this.otherSpecialties === undefined
    ) {
      this.mainSpecialties = uniqBy(
        compact(...[this.examContents.map((examContent) => examContent.mainSpecialty)]),
        'id'
      ).sort((a: Specialty, b: Specialty) => (a.name > b.name ? 1 : -1));

      this.otherSpecialties = uniqBy(
        compact(flatten(this.examContents.map((examContent) => examContent.otherSpecialties))),
        'id'
      ).sort((a: Specialty, b: Specialty) => (a.name > b.name ? 1 : -1));

      this.courses = uniqBy(
        compact(flatten(this.examContents.map((examContent) => examContent.courses))),
        'id'
      ).sort((a: Course, b: Course) => (a.ecniNumber > b.ecniNumber ? 1 : -1));
    }

    if (result.examContentEcos) {
      // nnkitodo [v2later] fix dégueu pour patcher api
      if (result.examContentEcos[0])
        this.examContentEcos = new ExamContentEcos(result.examContentEcos[0]);
      else this.examContentEcos = new ExamContentEcos(result.examContentEcos);
    }
  }

  get displayName() {
    return this.title;
  }

  get icon() {
    if (this.type.icon) {
      return this.type.icon;
    } else if (this.mainSpecialties?.length) {
      return this.mainSpecialties[0].icon;
    } else if (this.examContentEcos?.patientPictogram) {
      return this.examContentEcos.patientPictogram;
    } else {
      return 'assets/images/thumbnails/someone-1.jpg';
    }
  }

  // nnkitodo [v2later] pipe
  get difficultyIcon() {
    if (this.difficulty === ExamDifficulty.EASY) return 'icon-classification-easy';
    if (this.difficulty === ExamDifficulty.MEDIUM) return 'icon-classification-moderate';
    if (this.difficulty === ExamDifficulty.DIFFICULT) return 'icon-classification-hard';
  }

  get specialties(): Specialty[] {
    return uniqBy([...this.mainSpecialties, ...this.otherSpecialties], 'id');
  }

  get freeCountdown() {
    const now = new Date();
    if (this.freeStartAt <= now && this.freeEndAt >= now) {
      const countdown = getCountdown(this.freeEndAt);
      if (countdown.days) {
        return $localize`Gratuit ${countdown.days} jours`;
      } else if (countdown.hours) {
        return $localize`Gratuit ${countdown.hours} heures`;
      } else {
        return $localize`Gratuit ${countdown.minutes} min`;
      }
    }
  }

  get statistic() {
    return this.examStatistic;
  }

  toFormValue(): ExamFormValue {
    return {
      title: this.title,
      theme: this.theme,
      type: this.type,
      authorizedGrantedSubscription: this.authorizedGrantedSubscription,
      freeStartAt: this.freeStartAt,
      freeEndAt: this.freeEndAt,
      correctionStartAt: this.correctionStartAt,
      correctionEndAt: this.correctionEndAt,
      rankingAvailableAt: this.rankingAvailableAt,
    };
  }

  toResult(): ExamResult {
    return {
      id: this.id,
      title: this.title,
      theme: this.theme,
      difficulty: this.difficulty,
      isR2C: this.isR2C,
      type: this.type?.toResult(),
      publicationStatus: this.publicationStatus,
      author: this.author?.toResult(),
      createdAt: moment(this.createdAt).format(API_DATETIME_FORMAT),
      updatedBy: this.updatedBy?.toResult(),
      updatedAt: moment(this.updatedAt).format(API_DATETIME_FORMAT),
      publishAt: moment(this.publishAt).format(API_DATETIME_FORMAT),
      authorizedGrantedSubscription: this.authorizedGrantedSubscription,
      freeStartAt: moment(this.freeStartAt).format(API_DATETIME_FORMAT),
      freeEndAt: moment(this.freeEndAt).format(API_DATETIME_FORMAT),
      correctionStartAt: moment(this.correctionStartAt).format(API_DATETIME_FORMAT),
      correctionEndAt: moment(this.correctionEndAt).format(API_DATETIME_FORMAT),
      rankingAvailableAt: moment(this.rankingAvailableAt).format(API_DATETIME_FORMAT),
      maxGrade: this.maxGrade,
      averageRating: this.averageRating,
      totalExamContents: this.totalExamContents,
      totalFolders: this.totalFolders,
      totalIsolatedQuestions: this.totalIsolatedQuestions,
      totalFolderQuestions: this.totalFolderQuestions,
      totalTimerDuration: this.totalTimerDuration,
      examStatistic: this.examStatistic?.toResult(),
      examContents: this.examContents?.map((elt) => elt.toResult()),
      mainSpecialties: this.mainSpecialties?.map((elt) => elt.toResult()),
      otherSpecialties: this.otherSpecialties?.map((elt) => elt.toResult()),
      examSession: this.examSession?.toResult(),
      examContentEcos: this.examContentEcos?.toResult(),
    };
  }

  static updatedEntityToResult(payload: UpdateExamPayload): Partial<ExamResult> {
    return {
      title: payload.title,
      theme: payload.theme,
      authorizedGrantedSubscription: payload.authorizedGrantedSubscription,
      freeStartAt: payload.freeStartAt,
      freeEndAt: payload.freeEndAt,
      correctionStartAt: payload.correctionStartAt,
      correctionEndAt: payload.correctionEndAt,
      rankingAvailableAt: payload.rankingAvailableAt,
    };
  }
}

export class ExamStatistic {
  totalAnsweredFeedbacks: number;
  totalFeedbacks: number;
  totalParticipantsWhoFinishedAtLeastOneExamContent: number;
  totalParticipants: number;

  constructor(result: ExamStatisticResult) {
    this.totalAnsweredFeedbacks = result.totalAnsweredFeedbacks;
    this.totalFeedbacks = result.totalFeedbacks;
    this.totalParticipantsWhoFinishedAtLeastOneExamContent =
      result.totalParticipantsWhoFinishedAtLeastOneExamContent;
    this.totalParticipants = result.totalParticipants;
  }

  toResult(): ExamStatisticResult {
    return {
      totalAnsweredFeedbacks: this.totalAnsweredFeedbacks,
      totalFeedbacks: this.totalFeedbacks,
      totalParticipantsWhoFinishedAtLeastOneExamContent:
        this.totalParticipantsWhoFinishedAtLeastOneExamContent,
      totalParticipants: this.totalParticipants,
    };
  }
}
