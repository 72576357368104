export enum RegisterFormState {
  CREDENTIALS = 'CREDENTIALS',
  NAMES = 'NAMES',
  INFOS = 'INFOS',
}

export enum SSOProvider {
  APPLE = 'apple',
  FACEBOOK = 'facebook',
  GOOGLE = 'google',
}

export enum SSOProviderOAUTH {
  FACEBOOK_OAUTH = 'FACEBOOK_OAUTH',
  GOOGLE_OAUTH = 'GOOGLE_OAUTH',
  APPLE_OAUTH = 'APPLE_OAUTH',
}

export enum SSOLocation {
  LOGIN = 'LOGIN',
  REGISTER = 'REGISTER',
  LANDING_TOP = 'LANDING_TOP',
  LANDING_BOTTOM = 'LANDING_BOTTOM',
}

export enum RegisterFormType {
  REGISTER_WITH_EMAIL = 'REGISTER_WITH_EMAIL',
  REGISTER_WITH_SSO = 'REGISTER_WITH_SSO',
  REGISTER_WRITER = 'REGISTER_WRITER',
}

export enum UserRole {
  ROLE_STUDENT = 'ROLE_STUDENT',
  ROLE_WRITER = 'ROLE_WRITER',
  ROLE_COURSE_WRITER = 'ROLE_COURSE_WRITER',
  ROLE_PROOFREADER = 'ROLE_PROOFREADER',
  ROLE_CHIEF_WRITER = 'ROLE_CHIEF_WRITER',
  ROLE_ECNI = 'ROLE_ECNI',
}

export enum FilterUserRolePipeType {
  ALL = 'ALL',
  ONLY_WRITERS = 'ONLY_WRITERS',
  ONLY_WRITERS_BELOW_CHIEF_WRITER = 'ONLY_WRITERS_BELOW_CHIEF_WRITER',
}
