export class LocaleKeys {
  static token = 'ECNIV2_AUTH_token';
  static refreshToken = 'ECNIV2_AUTH_refreshToken';

  static rememberMe = 'ECNIV2_PWD_rememberMe';
  static rememberMePassword = 'ECNIV2_PWD_rememberMe_password';
  static rememberMeUsername = 'ECNIV2_PWD_rememberMe_username';

  static cookiesAccepted = 'ECNIV2_COOKIES_accepted';
  static cookiesLastRefusedDate = 'ECNIV2_COOKIES_lastCookiesRefusedDate';

  static notifsDeviceRegistered = 'ECNIV2_NOTIF_notifs_device_registered';
  static notifsLastRefusedDate = 'ECNIV2_NOTIF_lastNotificationsRefusedDate';

  static registerEmailFromLanding = 'ECNIV2_REGISTER_email_from_landing';

  static ancrageTodayShowed = 'ECNIV2_ANCRAGE_ancrageTodayShowed';
}
