import { Country } from '../../constants/countries';
import { FileFormValue } from './file.payload';
import { AddSubscriptionFormValue } from './subscription.payload';

export class UserIsActivePayload {
  isActive: string;

  constructor(isActive: boolean) {
    this.isActive = isActive ? 'true' : 'false';
  }
}

export class UserIsLockedPayload {
  locked: string;

  constructor(isLocked: boolean) {
    this.locked = isLocked ? 'true' : 'false';
  }
}

export interface UserUsernameFormValue {
  username: string;
  password: string;
}

export class UserUsernamePayload {
  username: string;

  constructor(formValue: Partial<UserUsernameFormValue>) {
    this.username = formValue.username;
  }
}

export class MyUsernamePayload extends UserUsernamePayload {
  password: string;

  constructor(formValue: Partial<UserUsernameFormValue>) {
    super(formValue);
    this.password = formValue.password;
  }
}

export interface UserEmailFormValue {
  email: string;
  password: string;
}

export class UserEmailPayload {
  email: string;

  constructor(formValue: Partial<UserEmailFormValue>) {
    this.email = formValue.email;
  }
}

export class MyEmailPayload extends UserEmailPayload {
  password: string;

  constructor(formValue: Partial<UserEmailFormValue>) {
    super(formValue);
    this.password = formValue.password;
  }
}

export class UserPicturePayload {
  file: File;

  constructor(fileFormValue: FileFormValue) {
    this.file = fileFormValue.file;
  }
}

export interface UserAddressAndInformationsFormValue {
  firstName: string;
  lastName: string;
  street: string;
  zipCode: string;
  city: string;
  country: Country;
  phoneNumber: string;
}

export class UserInformationsPayload {
  firstName: string;
  lastName: string;
  phoneNumber: string;

  constructor(formValue: Partial<UserAddressAndInformationsFormValue> | AddSubscriptionFormValue) {
    this.firstName = formValue.firstName;
    this.lastName = formValue.lastName;
    this.phoneNumber = formValue.phoneNumber;
  }
}

export class UserAddressPayload {
  street: string;
  city: string;
  zipCode: string;
  country: string;

  constructor(formValue: Partial<UserAddressAndInformationsFormValue> | AddSubscriptionFormValue) {
    this.street = formValue.street;
    this.city = formValue.city;
    this.zipCode = formValue.zipCode;
    this.country = formValue.country.code;
  }
}
