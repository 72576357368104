export enum ExamDifficulty {
  EASY = 'EASY',
  MEDIUM = 'MEDIUM',
  DIFFICULT = 'DIFFICULT',
}

export enum AuthorizedExamType {
  QUIZZ = 'QUIZZ',
  QUIZZ_ISO = 'QUIZZ_ISO',
  QUIZZ_MMG = 'QUIZZ_MMG',
  CONFTUBE = 'CONFTUBE',
  MASTERCLASS = 'MASTERCLASS',
  LCA = 'LCA',
  IMAGING = 'IMAGING',
  CONTEST = 'CONTEST',
  CONTEST_ISO = 'CONTEST_ISO',
  CONTEST_OFFICIAL = 'CONTEST_OFFICIAL',
  EASY_DP = 'EASY_DP',
  MINI_DP = 'MINI_DP',
  KFP = 'KFP',
  TCS = 'TCS',
  ECNI_BLANK = 'ECNI_BLANK',
  ANNALS = 'ANNALS',
  ECOS = 'ECOS',
}
