import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { QueryData } from '../../../shared/models/payloads/query-data.payload';
import { prepareQueryParams } from '../../../shared/utils/api-transformations';
import { map } from 'rxjs';
import {
  AddSubscriptionResult,
  ListSubscriptionsResult,
  UpdateCreditCardResult,
} from '../../../shared/models/api-results/subscription.api-result';
import { AddedEntity } from '../../../shared/models/api-results/base.api-result';
import {
  AddSubscriptionPayload,
  ConvertSubscriptionPayload,
} from '../../../shared/models/payloads/subscription.payload';

@Injectable({
  providedIn: 'root',
})
export class HttpSubscriptionService {
  constructor(private http: HttpClient) {}

  list(params: QueryData) {
    return this.http
      .get(`${environment.api_base_url}/subscriptions/me/list_my_subscriptions`, {
        params: prepareQueryParams(params),
      })
      .pipe(map((res) => res as ListSubscriptionsResult));
  }

  listActive() {
    return this.http
      .get(`${environment.api_base_url}/subscriptions/me/list_my_active_subscriptions`)
      .pipe(map((res) => res as ListSubscriptionsResult));
  }

  add(offerId: string, payload: AddSubscriptionPayload) {
    return this.http
      .post(`${environment.api_base_url}/subscriptions/subscribe_to_an_offer/${offerId}`, payload)
      .pipe(map((res) => res as AddSubscriptionResult));
  }

  convertFromPremiumPlusToElite(payload: ConvertSubscriptionPayload) {
    return this.http
      .post(
        `${environment.api_base_url}/subscriptions/convert_active_subscription_from_premium_to_elite`,
        payload
      )
      .pipe(map((res) => res as AddSubscriptionResult));
  }

  triggerPaymentAttempt(id: string) {
    return this.http
      .post(
        `${environment.api_base_url}/subscriptions/${id}/attempt_to_debit_failed_subscription`,
        {}
      )
      .pipe(map((res) => res as AddSubscriptionResult));
  }

  updateCreditCard(id: string) {
    return this.http
      .post(`${environment.api_base_url}/subscriptions/${id}/update_my_credit_card_information`, {})
      .pipe(map((res) => res as UpdateCreditCardResult));
  }

  wList(id: string, params: QueryData) {
    return this.http
      .get(`${environment.api_base_url}/subscriptions/list_subscriptions_of_student/${id}`, {
        params: prepareQueryParams(params),
      })
      .pipe(map((res) => res as ListSubscriptionsResult));
  }

  // nnkitodo [v2later ecos] Semblerait qu’il y ait un soucis sur addSubscription by chief writer si on ajoute du ECOS en parallèle d’un p+
  wAdd(offerId: string, userId: string) {
    return this.http
      .post(
        `${environment.api_base_url}/subscriptions/add_custom_subscription/offer/${offerId}/user/${userId}`,
        {}
      )
      .pipe(
        map((res: any) => {
          res.id = 1;
          return res as AddedEntity;
        })
      );
  }

  wClose(id: string) {
    return this.http.post(`${environment.api_base_url}/subscriptions/${id}/close_subscription`, {});
  }
}
