import { Question } from '../entities/question.entity';

export class AnswerMatchQuestionPayload {
  questionId: string;
  answer: string;
  // nnkitodo [v2later] plutot faire un extends
  timeSpent?: number;

  constructor(question: Question, timeSpent?: number) {
    this.questionId = question.id;
    this.answer = question.answerMatch;
    if (timeSpent !== undefined) this.timeSpent = Math.max(0, timeSpent);
  }
}

export class AnswerOneOrTCSQuestionPayload {
  questionId: string;
  answer: string;
  timeSpent?: number;

  constructor(question: Question, timeSpent?: number) {
    this.questionId = question.id;
    if (question.selectedAnswerOne) {
      this.answer = question.selectedAnswerOne;
    } else {
      this.answer = question.corrections
        .filter((correction) => correction.isSelected)
        .map((correction) => correction.id)?.[0];
    }

    if (timeSpent !== undefined) this.timeSpent = Math.max(0, timeSpent);
  }
}

export class AnswerManyUndefinedQuestionPayload {
  questionId: string;
  answers: string[];
  timeSpent?: number;

  constructor(question: Question, timeSpent?: number) {
    this.questionId = question.id;
    this.answers = question.corrections
      .filter((correction) => correction.isSelected)
      .map((correction) => correction.id);

    if (timeSpent !== undefined) this.timeSpent = Math.max(0, timeSpent);
  }
}

export class AnswerManyDefinedQuestionPayload {
  questionId: string;
  answers: string[];
  timeSpent?: number;

  constructor(question: Question, timeSpent?: number) {
    this.questionId = question.id;
    this.answers = question.corrections
      .filter((correction) => correction.isSelected)
      .map((correction) => correction.id);

    if (timeSpent !== undefined) this.timeSpent = Math.max(0, timeSpent);
  }
}

export class AnswerMatchingAreaQuestionPayload {
  questionId: string;
  answer: string;
  timeSpent?: number;

  constructor(question: Question, timeSpent?: number) {
    this.questionId = question.id;
    if (question.answerMatchingRecognition) {
      this.answer = `${question.answerMatchingRecognition?.x}:${question.answerMatchingRecognition?.y}`;
    }
    if (timeSpent !== undefined) this.timeSpent = Math.max(0, timeSpent);
  }
}
