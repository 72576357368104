import { Injectable } from '@angular/core';
import { HttpStudentActivityService } from '../../../../core/services/http/http-student-activity.service';
import {
  ListState,
  listInitialState,
  AbstractListComponentStore,
} from '../../../../core/state/base-component-stores/abstract-list.component-store';
import { StudentActivityResult } from '../../../models/api-results/student-activity.api-result';
import { StudentActivity } from '../../../models/entities/student-activity.entity';
import { FiltersFormValue, QueryData } from '../../../models/payloads/query-data.payload';
import { filter, first, concatMap, iif, withLatestFrom, tap, Observable, exhaustMap } from 'rxjs';
import * as AuthSelectors from '../../../../core/state/auth-state/auth.selectors';
import { Store } from '@ngrx/store';
import { tapResponse } from '@ngrx/component-store';
import { ErrorService } from '../../../../core/services/error.service';

export interface ChallengeActualitiesState extends ListState<StudentActivityResult> {
  studentId: string;
}

export const initialState: ChallengeActualitiesState = {
  ...listInitialState,
  studentId: undefined,
};

@Injectable()
export class ChallengeActualitiesStoreService extends AbstractListComponentStore<ChallengeActualitiesState> {
  constructor(
    private store: Store,
    private errorService: ErrorService,
    private httpStudentActivityService: HttpStudentActivityService
  ) {
    super(initialState);
  }

  // SELECTORS

  entities$ = this.select(
    this.store.select(AuthSelectors.loggedStudent),
    this.select((state) => state.entities['hydra:member']),
    (loggedStudent, entityResults) =>
      entityResults.map((elt) => new StudentActivity(elt, loggedStudent))
  );
  studentId$ = this.select((state) => state.studentId);

  // ACTIONS

  init = this.updater((state, studentId: string) => {
    return {
      ...state,
      studentId,
    };
  });

  initLike = this.updater((state, id: string) => {
    return {
      ...state,
      entities: {
        ...state.entities,
        'hydra:member': state.entities['hydra:member'].map((studentActivity) => {
          if (studentActivity.id === id) {
            studentActivity.loadingLike = true;
          }
          return studentActivity;
        }),
      },
    };
  });

  likeFailed = this.updater((state, id: string) => {
    return {
      ...state,
      entities: {
        ...state.entities,
        'hydra:member': state.entities['hydra:member'].map((studentActivity) => {
          if (studentActivity.id === id) {
            studentActivity.loadingLike = false;
          }
          return studentActivity;
        }),
      },
    };
  });

  likeAddedSucceed = this.updater((state, id: string) => {
    return {
      ...state,
      entities: {
        ...state.entities,
        'hydra:member': state.entities['hydra:member'].map((studentActivity) => {
          if (studentActivity.id === id) {
            studentActivity.isLikedByMe = true;
            studentActivity.totalStudentLikedActivity++;
            studentActivity.loadingLike = false;
          }
          return studentActivity;
        }),
      },
    };
  });

  likeRemovedSucceed = this.updater((state, id: string) => {
    return {
      ...state,
      entities: {
        ...state.entities,
        'hydra:member': state.entities['hydra:member'].map((studentActivity) => {
          if (studentActivity.id === id) {
            studentActivity.isLikedByMe = false;
            studentActivity.totalStudentLikedActivity--;
            studentActivity.loadingLike = false;
          }
          return studentActivity;
        }),
      },
    };
  });

  // EFFECTS

  listApiCall(filtersFormValue: FiltersFormValue) {
    return this.studentId$.pipe(
      filter((studentId) => !!studentId),
      first(),
      withLatestFrom(this.store.select(AuthSelectors.loggedStudent)),
      concatMap(([studentId, loggedStudent]) =>
        iif(
          () => studentId === loggedStudent.id,
          this.httpStudentActivityService.listMine(new QueryData(filtersFormValue, true)),
          this.httpStudentActivityService.list(studentId, new QueryData(filtersFormValue, true))
        )
      )
    );
  }

  like = this.effect((studentActivity$: Observable<StudentActivity>) =>
    studentActivity$.pipe(
      tap((studentActivity) => this.initLike(studentActivity.id)),
      exhaustMap((studentActivity) => {
        return this.httpStudentActivityService.like(studentActivity.id).pipe(
          tapResponse(
            () => {
              this.likeAddedSucceed(studentActivity.id);
            },
            (error) => {
              this.likeFailed(studentActivity.id);
              this.errorService.toastError(error);
            }
          )
        );
      })
    )
  );

  unlike = this.effect((studentActivity$: Observable<StudentActivity>) =>
    studentActivity$.pipe(
      tap((studentActivity) => this.initLike(studentActivity.id)),
      exhaustMap((studentActivity) => {
        return this.httpStudentActivityService.unlike(studentActivity.id).pipe(
          tapResponse(
            () => {
              this.likeRemovedSucceed(studentActivity.id);
            },
            (error) => {
              this.likeFailed(studentActivity.id);
              this.errorService.toastError(error);
            }
          )
        );
      })
    )
  );
}
