import {
  ListStudentSettingsResult,
  StudentSettingResult,
} from '../../../shared/models/api-results/student-setting.api-result';
import { StudentResult } from '../../../shared/models/api-results/student.api-result';
import { ListSubscriptionsResult } from '../../../shared/models/api-results/subscription.api-result';
import { WriterResult } from '../../../shared/models/api-results/writer.api-result';
import { CallState, LoadingState } from '../../../shared/models/interfaces/call-state.interface';
import { LocaleKeys } from '../../../shared/utils/locale-keys';

export interface AuthState {
  initializing: boolean;
  token: string;
  refreshToken: string;
  refreshingToken: boolean;
  student: StudentResult;
  studentSettings: ListStudentSettingsResult;
  activeSubscriptions: ListSubscriptionsResult;
  writer: WriterResult;

  loginState: CallState;
  pathToReachAfterLogin: string;

  isInMaintenance: boolean;

  impersonateUser: StudentResult;
}

export const initialAuthState: AuthState = {
  initializing: true,
  token: localStorage.getItem(LocaleKeys.token),
  refreshToken: localStorage.getItem(LocaleKeys.refreshToken),
  refreshingToken: false,
  student: undefined,
  studentSettings: undefined,
  activeSubscriptions: undefined,
  writer: undefined,

  loginState: LoadingState.INIT,
  pathToReachAfterLogin: undefined,

  isInMaintenance: false,

  impersonateUser: undefined,
};
