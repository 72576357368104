// nnkitodo [v2later] mieux

import { environment } from '../../../environments/environment';
import { CorrectionStatus } from '../models/enums/correction.enums';
import { FolderType } from '../models/enums/folder.enums';
import { QuestionAnswerMethod } from '../models/enums/question.enums';
import { CorrectionFormValue } from '../models/payloads/correction.payload';
import { QuestionFormValue } from '../models/payloads/question/question.payload';

export function questionMaxGradeForContext(folderType?: FolderType) {
  if (environment.defaultLanguage === 'fr') {
    if (folderType === FolderType.LCA) {
      return 2;
    } else {
      return 1;
    }
  } else if (environment.defaultLanguage === 'it') {
    return 1;
  } else if (environment.defaultLanguage === 'es') {
    return 3;
  }
}

export function checkQuestionCorrectionsValidity(
  question: QuestionFormValue,
  corrections: CorrectionFormValue[]
) {
  if (!corrections.length) {
    return $localize`La question doit avoir au moins une réponse`;
  }

  if (
    question.answerMethod === QuestionAnswerMethod.MANY_DEFINED_LONG ||
    question.answerMethod === QuestionAnswerMethod.MANY_DEFINED_SHORT ||
    question.answerMethod === QuestionAnswerMethod.MANY_UNDEFINED ||
    question.answerMethod === QuestionAnswerMethod.ONE ||
    question.answerMethod === QuestionAnswerMethod.TCS
  ) {
    const nbCorrectionsCorrect = corrections.reduce(
      (sum, correction) =>
        sum +
        (correction.status === CorrectionStatus.WIN ||
        correction.status === CorrectionStatus.MANDATORY
          ? 1
          : 0),
      0
    );

    if (
      question.answerMethod === QuestionAnswerMethod.MANY_DEFINED_LONG ||
      question.answerMethod === QuestionAnswerMethod.MANY_DEFINED_SHORT ||
      question.answerMethod === QuestionAnswerMethod.MANY_UNDEFINED
    ) {
      if (nbCorrectionsCorrect < 1) {
        return $localize`La question doit avoir au moins une réponse correcte`;
      }
    }

    if (
      question.answerMethod === QuestionAnswerMethod.ONE ||
      question.answerMethod === QuestionAnswerMethod.TCS
    ) {
      if (nbCorrectionsCorrect != 1) {
        return $localize`La question doit avoir exactement une réponse correcte`;
      }
    }
  }

  return null;
}

export function generateTCSProblemStatement(
  TCSHypothesis: string,
  TCSAdditionalInformation: string,
  TCSQuestion: string
) {
  return `<p><strong>Hypothèse: </strong><br>${TCSHypothesis}</p><p><strong>Information additionnelle: </strong><br>${TCSAdditionalInformation}</p><p><strong>Question: </strong><br>${TCSQuestion}</p>`;
}

export function extractTCSHypothesis(problemStatement: string) {
  return problemStatement.match(/<p><strong>Hypothèse: <\/strong><br>(.*?)<\/p>/);
}

export function extractTCSAdditionalInformation(problemStatement: string) {
  return problemStatement.match(/<p><strong>Information additionnelle: <\/strong><br>(.*?)<\/p>/);
}

export function extractTCSQuestion(problemStatement: string) {
  return problemStatement.match(/<p><strong>Question: <\/strong><br>(.*?)<\/p>/);
}
