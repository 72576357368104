import { ApplicationStatsResult } from '../api-results/application-stats.api-result';

export class ApplicationStats {
  totalPublishedExams: number;
  totalPublishedFolderQuestions: number;
  totalPublishedOnLessonsCourses: number;
  totalPublishedOnTrainingQuestions: number;
  totalSessions: number;
  totalUsers: number;
  totalUnpublishedOnTrainingPublishedOnExamExamContentQuestions: number;

  totalQuestions: number;

  constructor(result: ApplicationStatsResult) {
    this.totalPublishedExams = result.totalPublishedExams;
    this.totalPublishedFolderQuestions = result.totalPublishedFolderQuestions;
    this.totalPublishedOnLessonsCourses = result.totalPublishedOnLessonsCourses;
    this.totalPublishedOnTrainingQuestions = result.totalPublishedOnTrainingQuestions;
    this.totalSessions = result.totalSessions;
    this.totalUsers = result.totalUsers;
    this.totalUnpublishedOnTrainingPublishedOnExamExamContentQuestions =
      result.totalUnpublishedOnTrainingPublishedOnExamExamContentQuestions;

    this.totalQuestions =
      this.totalPublishedFolderQuestions +
      this.totalPublishedOnTrainingQuestions +
      this.totalUnpublishedOnTrainingPublishedOnExamExamContentQuestions;
  }
}
