import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-svg-icon',
  templateUrl: './svg-icon.component.html',
  styleUrls: ['./svg-icon.component.scss'],
})
export class SvgIconComponent implements OnInit {
  @Input() icon: string;
  @Input() isMaterial = false;
  @Input() width: number;

  @Input() inline: boolean;

  constructor() {}

  ngOnInit(): void {}
}
