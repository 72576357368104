import { Writer } from 'tinymce';
import { Student } from './student.entity';
import {
  AddressResult,
  EcniUserResult,
  EcniUserTypeaheadResult,
} from '../api-results/ecni-user.api-result';
import { environment } from '../../../../environments/environment';
import { Country, getCountryName } from '../../constants/countries';
import {
  UserAddressAndInformationsFormValue,
  UserAddressPayload,
  UserInformationsPayload,
} from '../payloads/user.payload';
import moment from 'moment';
import { API_DATETIME_FORMAT } from '../../constants/date-format';
import { convertStringToInt } from '../../utils/letters-numbers-transformations';
import { UserRole } from '../enums/auth.enums';

export class EcniUserTypeahead {
  id: string;
  createdAt: Date;
  firstName: string;
  lastName: string;
  username: string;

  constructor(result: EcniUserTypeaheadResult) {
    this.id = result.id;
    if (result.createdAt) this.createdAt = new Date(result.createdAt);
    this.firstName = result.firstName;
    this.lastName = result.lastName;
    this.username = result.username;
  }

  get displayName() {
    return `${this.firstName} ${this.lastName} (${this.username})`;
  }

  toResult(): EcniUserTypeaheadResult {
    return {
      id: this.id,
      createdAt: moment(this.createdAt).format(API_DATETIME_FORMAT),
      firstName: this.firstName,
      lastName: this.lastName,
      username: this.username,
    };
  }
}

export class EcniUser extends EcniUserTypeahead {
  email: string;

  address: EcniAddress;
  phoneNumber: string;

  picture: string;

  createdAt: Date;
  lastConnectedAt: Date;

  isActive: boolean;
  lockedAt: Date;

  role: UserRole;

  constructor(result: EcniUserResult) {
    super(result);
    this.email = result.email;

    if (result.address) {
      this.address = new EcniAddress(result.address);
    }
    this.phoneNumber = result.phoneNumber;

    this.picture = result.picture
      ? result.picture
      : `assets/images/thumbnails/someone-${(convertStringToInt(this.id) % 4) + 1}.jpg`;

    if (result.createdAt) {
      this.createdAt = new Date(result.createdAt);
    }
    if (result.lastConnectedAt) {
      this.lastConnectedAt = new Date(result.lastConnectedAt);
    }

    this.isActive = result.isActive;
    if (result.lockedAt) {
      this.lockedAt = new Date(result.lockedAt);
    }

    this.role = result.role;
  }

  toFormValue(): Partial<UserAddressAndInformationsFormValue> {
    return {
      firstName: this.firstName,
      lastName: this.lastName,
      phoneNumber: this.phoneNumber,
    };
  }

  toResult(): EcniUserResult {
    return {
      ...super.toResult(),
      email: this.email,
      address: this.address?.toResult(),
      phoneNumber: this.phoneNumber,
      picture: this.picture,
      createdAt: moment(this.createdAt).format(API_DATETIME_FORMAT),
      lastConnectedAt: moment(this.lastConnectedAt).format(API_DATETIME_FORMAT),
      isActive: this.isActive,
      lockedAt: moment(this.lockedAt).format(API_DATETIME_FORMAT),
      role: this.role,
    };
  }

  static updatedEntityToResult(
    informationsPayload: UserInformationsPayload,
    addressPayload: UserAddressPayload
  ): Partial<EcniUserResult> {
    return {
      firstName: informationsPayload.firstName,
      lastName: informationsPayload.lastName,
      address: EcniAddress.updatedEntityToResult(addressPayload),
      phoneNumber: informationsPayload.phoneNumber,
    };
  }

  get hasWriterAccess() {
    return this.role === UserRole.ROLE_WRITER || this.hasCourseWriterAccess;
  }

  get hasCourseWriterAccess() {
    return this.role === UserRole.ROLE_COURSE_WRITER || this.hasProofReaderAccess;
  }

  get hasProofReaderAccess() {
    return this.role === UserRole.ROLE_PROOFREADER || this.hasChiefWriterAccess;
  }

  get hasChiefWriterAccess() {
    return this.role === UserRole.ROLE_CHIEF_WRITER || this.hasAdministratorAccess;
  }

  get hasAdministratorAccess() {
    return this.role === UserRole.ROLE_ECNI;
  }
}

export class EcniAddress {
  street: string;
  zipCode: string;
  city: string;
  country: Country;

  constructor(addressResult: AddressResult) {
    this.street = addressResult.street;
    this.zipCode = addressResult.zipCode;
    this.city = addressResult.city;
    this.country = addressResult.country
      ? {
          code: addressResult.country,
          name: getCountryName(environment.defaultLanguage, addressResult.country),
        }
      : null;
  }

  toFormValue(): Partial<UserAddressAndInformationsFormValue> {
    return {
      street: this.street,
      zipCode: this.zipCode,
      city: this.city,
      country: this.country,
    };
  }

  toResult(): AddressResult {
    return {
      street: this.street,
      zipCode: this.zipCode,
      city: this.city,
      country: this.country.code,
    };
  }

  static updatedEntityToResult(payload: UserAddressPayload): AddressResult {
    return {
      street: payload.street,
      zipCode: payload.zipCode,
      city: payload.city,
      country: payload.country,
    };
  }
}
