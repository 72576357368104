import { Offer } from '../entities/offer.entity';
import { OfferTier } from './offer.enums';

export enum SubscriptionTier {
  FREE = 'FREE',
  PREMIUM_PLUS = 'PREMIUM_PLUS',
  ELITE = 'ELITE',

  // nnkitodo [v2later ecos]
  // ECOS = 'ECOS',
}

export function getColorForSubscriptionTier(tier: SubscriptionTier | OfferTier) {
  if (tier === SubscriptionTier.FREE) {
    return 'bg-free-gradient-one';
  } else if (tier === SubscriptionTier.PREMIUM_PLUS) {
    return 'bg-premiumplus-gradient-two';
  } else if (tier === SubscriptionTier.ELITE) {
    return 'bg-black';
  }
  // nnkitodo [v2later ecos]
  // } else if (tier === SubscriptionTier.ECOS) {
  //   return 'bg-purple-one';
  // }
}

export enum SubscriptionStatus {
  NOT_STARTED = 'NOT_STARTED',
  ACTIVE = 'ACTIVE',
  PAYMENT_FAILED = 'PAYMENT_FAILED',
  PAYMENT_PENDING = 'PAYMENT_PENDING',
  CLOSED = 'CLOSED',
  ENDED = 'ENDED',
}

export function getColorForSubscriptionStatus(status: SubscriptionStatus) {
  if (status === SubscriptionStatus.NOT_STARTED) {
    return 'bg-purple-one';
  } else if (status === SubscriptionStatus.ACTIVE) {
    return 'bg-green-three';
  } else if (status === SubscriptionStatus.PAYMENT_FAILED) {
    return 'bg-red-one';
  } else if (status === SubscriptionStatus.PAYMENT_PENDING) {
    return 'bg-orange-two';
  } else if (status === SubscriptionStatus.CLOSED || status === SubscriptionStatus.ENDED) {
    return 'bg-theme-gray-five';
  }
}
