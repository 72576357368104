import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

import { IsApplicationService } from '../../../core/services/is-application.service';

import { HttpOfferService } from '../../../core/services/http/http-offer.service';
import { Offer } from '../../models/entities/offer.entity';
import { OfferTier } from '../../models/enums/offer.enums';

@Component({
  selector: 'app-modal-premium-plus',
  templateUrl: './modal-premium-plus.component.html',
  styleUrls: ['./modal-premium-plus.component.scss'],
})
export class ModalPremiumPlusComponent implements OnInit {
  offers: Offer[];

  Math = Math;
  OfferTier = OfferTier;

  constructor(
    public dialogRef: MatDialogRef<ModalPremiumPlusComponent>,
    public isApplicationService: IsApplicationService,
    private httpOfferService: HttpOfferService
  ) {}

  ngOnInit(): void {
    this.httpOfferService.list().subscribe((res) => {
      this.offers = res['hydra:member'].map((elt) => new Offer(elt));
    });
  }

  close() {
    this.dialogRef.close();
  }
}
