import { environment } from '../../../../environments/environment';
import { ConversationMessageResult } from '../api-results/conversation-message.api-result';
import { Student } from './student.entity';

export class ConversationMessage {
  id: string;
  createdAt: Date;
  content: string;
  author: string;

  // nnkitodo [v2later] autrement
  createdAgo: number;

  constructor(result: ConversationMessageResult) {
    this.id = result.id;
    if (result.createdAt) this.createdAt = new Date(result.createdAt);
    this.content = result.content;
    this.author = result.author;
    if (this.createdAt) this.createdAgo = new Date().getTime() - this.createdAt.getTime();
  }
}
