import { first, iif, map, of, withLatestFrom } from 'rxjs';
import { StudentResult } from '../../models/api-results/student.api-result';
import { Injectable } from '@angular/core';
import { HttpFollowService } from '../../../core/services/http/http-follow.service';
import {
  listInitialState,
  AbstractListComponentStore,
  ListState,
} from '../../../core/state/base-component-stores/abstract-list.component-store';
import { WListState } from '../../../core/state/base-component-stores/abstract-writer-list.component-store';
import { Student } from '../../models/entities/student.entity';
import { FiltersFormValue, QueryData } from '../../models/payloads/query-data.payload';
import { FollowStatus } from '../../models/enums/follow.enum';
import { FollowQueryData } from '../../models/payloads/follow.payload';
import { HttpStudentService } from '../../../core/services/http/http-student.service';
import { ListResult } from '../../models/api-results/base.api-result';
import { LoadingState } from '../../models/interfaces/call-state.interface';
import { Store } from '@ngrx/store';
import * as AuthSelectors from '../../../core/state/auth-state/auth.selectors';
import { StudentFiltersFormValue, StudentQueryData } from '../../models/payloads/student.payload';

export interface ChooseFriendsState extends ListState<StudentResult> {
  selectedStudents: StudentResult[];
}

export const initialState: ChooseFriendsState = {
  ...listInitialState,
  selectedStudents: [],
};

@Injectable()
export class ChooseFriendsStoreService extends AbstractListComponentStore<ChooseFriendsState> {
  constructor(
    private store: Store,
    private httpFollowService: HttpFollowService,
    private httpStudentService: HttpStudentService
  ) {
    super(initialState);
  }

  // SELECTORS

  entities$ = this.select((state) => state.entities['hydra:member'].map((elt) => new Student(elt)));
  storedSelectedStudents$ = this.select((state) =>
    state.selectedStudents.map((elt) => new Student(elt))
  );

  displayedStudents$ = this.select(
    this.store.select(AuthSelectors.loggedStudent),
    this.entities$,
    this.storedSelectedStudents$,
    (loggedStudent, entities, storedSelectedStudents) => [
      ...storedSelectedStudents,
      ...entities
        .filter(
          (student) =>
            storedSelectedStudents.findIndex(
              (selectedStudent) => selectedStudent.id === student.id
            ) === -1
        )
        .filter((student) => student.id !== loggedStudent.id),
    ]
  );

  selectedStudents$ = this.select(this.displayedStudents$, (displayedStudents) =>
    displayedStudents.filter((student) => student.selected)
  );

  // ACTIONS

  updateUserSelected = this.updater((state, params: { user: Student; selected: boolean }) => {
    return {
      ...state,
      entities: {
        ...state.entities,
        'hydra:member': state.entities['hydra:member'].map((student) => {
          if (student.id === params.user.id) {
            return {
              ...student,
              selected: params.selected,
            };
          } else {
            return student;
          }
        }),
      },
      selectedStudents: state.selectedStudents.map((student) => {
        if (student.id === params.user.id) {
          return {
            ...student,
            selected: params.selected,
          };
        } else {
          return student;
        }
      }),
    };
  });

  storeSelectedUsers = this.updater((state) => {
    return {
      ...state,
      selectedStudents: [
        ...state.selectedStudents.filter((student) => student.selected),
        ...state.entities['hydra:member'].filter((student) => student.selected),
      ],
    };
  });

  addSelectedUsers = this.updater((state, users: Student[]) => {
    return {
      ...state,
      selectedStudents: [
        ...state.selectedStudents,
        ...users.map((elt) => {
          return {
            ...elt.toResult(),
            selected: true,
          };
        }),
      ],
    };
  });

  // EFFECTS

  listApiCall(filtersFormValue: StudentFiltersFormValue) {
    return iif(
      () => !!filtersFormValue.username,
      this.httpStudentService.list(new StudentQueryData(filtersFormValue)),
      this.httpFollowService
        .listFollowedByMe(
          new FollowQueryData({ ...filtersFormValue, status: FollowStatus.APPROVED })
        )
        .pipe(
          map((listFollowedResult) => {
            return {
              ...listFollowedResult,
              'hydra:member': listFollowedResult['hydra:member'].map(
                // nnkitodo [v2later] eurk
                (followResult) => {
                  return {
                    ...followResult,
                    id: followResult.userId,
                  } as any as StudentResult;
                }
              ),
            };
          })
        )
    );
  }
}
