// nnkitodo [v2later] vérifier si on peut pas enlever des trucs de l'export
// nnkitodo [v2later] mieux ranger les imports exports

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import {
  MAT_RIPPLE_GLOBAL_OPTIONS,
  MatRippleModule,
  RippleGlobalOptions,
} from '@angular/material/core';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';

import { TranslateModule } from '@ngx-translate/core';
import { EditorModule } from '@tinymce/tinymce-angular';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { MomentModule } from 'ngx-moment';
import { NgxUsefulSwiperModule } from 'ngx-useful-swiper';

import { environment } from '../../environments/environment';

import { ActionButtonsComponent } from './components/action-buttons/action-buttons.component';
import { ArticleContentComponent } from './components/article-detail/article-content.component';
import { ArticleCardComponent } from './components/cards/article-card/article-card.component';
import { CourseCardComponent } from './components/cards/course-card/course-card.component';
import { DashboardAncrageCardComponent } from './components/cards/dashboard-ancrage-card/dashboard-ancrage-card.component';
import { ExamCardComponent } from './components/cards/exam-card/exam-card.component';
import { FolderDoneCardComponent } from './components/cards/folder-done-card/folder-done-card.component';
import { MultirankCardComponent } from './components/cards/multirank-card/multirank-card.component';
import { OvalHeaderCardComponent } from './components/cards/oval-header-card/oval-header-card.component';
import { ProgressAncrageTrainingCardComponent } from './components/cards/progress-ancrage-training-card/progress-ancrage-training-card.component';
import { ProgressCustomTrainingCardComponent } from './components/cards/progress-custom-training-card/progress-custom-training-card.component';
import { SubscriptionCardComponent } from './components/cards/subscription-card/subscription-card.component';
import { TestimonyCardComponent } from './components/cards/testimony-card/testimony-card.component';
import { TrainingDisciplineCardComponent } from './components/cards/training-discipline-card/training-discipline-card.component';
import { TrainingItemCardComponent } from './components/cards/training-item-card/training-item-card.component';
import { ChallengeActualitiesComponent } from './components/challenge/challenge-actualities/challenge-actualities.component';
import { ChallengeDescriptionComponent } from './components/challenge/challenge-description/challenge-description.component';
import { ChallengeMajorComponent } from './components/challenge/challenge-major/challenge-major.component';
import { ChooseFriendsComponent } from './components/choose-friends/choose-friends.component';
import { FriendInviteComponent } from './components/choose-friends/friend-invite/friend-invite.component';
import { ImageContentComponent } from './components/contents/image-content/image-content.component';
import { TextContentComponent } from './components/contents/text-content/text-content.component';
import { HistogramComponent } from './components/histogram/histogram.component';
import { BaseComponent } from './components/inherited/base/base.component';
import { LoaderBookComponent } from './components/loaders/loaders/loader-book/loader-book.component';
import { LoaderDotsComponent } from './components/loaders/loaders/loader-dots/loader-dots.component';
import { LoaderHashtagComponent } from './components/loaders/loaders/loader-hashtag/loader-hashtag.component';
import { LoaderInfiniteScrollComponent } from './components/loaders/loader-infinite-scroll/loader-infinite-scroll.component';
import { FeaturedTestimonyCardComponent } from './components/featured-testimonies-slider/featured-testimony-card/featured-testimony-card.component';
import { FeaturedTestimoniesSliderComponent } from './components/featured-testimonies-slider/featured-testimonies-slider.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { NotionsListComponent } from './components/notions-list/notions-list.component';
import { PdfViewerComponent } from './components/pdf-viewer/pdf-viewer.component';
import { PricingPremiumComponent } from './components/pricing-premium/pricing-premium.component';
import { QuestionCandidateFormComponent } from './components/question/question-candidate-form/question-candidate-form.component';
import { QuestionContentComponent } from './components/question/question-content/question-content.component';
import { QuestionCorrectionFormComponent } from './components/question/question-correction-form/question-correction-form.component';
import { QuestionCorrectionTopComponent } from './components/question/question-correction-top/question-correction-top.component';
import { QuestionHeaderComponent } from './components/question/question-header/question-header.component';
import { QuestionImagesComponent } from './components/question/question-images/question-images.component';
import { SiteLogoComponent } from './components/site-logo/site-logo.component';
import { SocialLoginComponent } from './components/social-login/social-login.component';
import { StarsGradeComponent } from './components/stars-grade/stars-grade.component';
import { StudentThumbnailComponent } from './components/student-thumbnail/student-thumbnail.component';
import { TopSearchComponent } from './components/top-search/top-search.component';
import { UserSunrayComponent } from './components/user-sunray/user-sunray.component';
import { WidgetProgressionComponent } from './components/widget-progression/widget-progression.component';

import { SafePipe } from '../shared/pipes/safe.pipe';
import { Utf8EncodePipe } from '../shared/pipes/utf8_encode.pipe';
import { AddAbbreviationsPipe } from './pipes/add-abbreviations.pipe';
import { CountdownPipe } from './pipes/countdown.pipe';
import { ParagraphizePipe } from './pipes/paragraphize.pipe';
import { StripTagsPipe } from './pipes/strip-tags.pipe';

import { AccordionTableDirective } from './directives/accordion-table.directive';
import { BaseDirective } from './directives/base.directive';
import { CollapsibleElementDirective } from './directives/collapsible-element.directive';
import { CopyRestrictedDirective } from './directives/copy-restricted.directive';
import { GradientHiddenDirective } from './directives/gradient-hidden.directive';
import { InfiniteScrollDirective } from './directives/infinite-scroll.directive';
import { PanelDirective } from './directives/panel.directive';

import { ModalAcceptNotificationsComponent } from './modals/modal-accept-notifications/modal-accept-notifications.component';
import { ModalAncrageInfosComponent } from './modals/modal-ancrage-infos/modal-ancrage-infos.component';
import { ModalAncrageTodayComponent } from './modals/modal-ancrage-today/modal-ancrage-today.component';
import { EndChallengeComponent } from './modals/modal-candidate-challenge/end-challenge/end-challenge.component';
import { IntroCandidateChallengeComponent } from './modals/modal-candidate-challenge/intro-candidate-challenge/intro-candidate-challenge.component';
import { IntroQuestionChallengeComponent } from './modals/modal-candidate-challenge/intro-question-challenge/intro-question-challenge.component';
import { ModalCandidateChallengeComponent } from './modals/modal-candidate-challenge/modal-candidate-challenge.component';
import { QuestionChallengeComponent } from './modals/modal-candidate-challenge/question-challenge/question-challenge.component';
import { ModalChallengeBadgeInfosComponent } from './modals/modal-challenge-badge-infos/modal-challenge-badge-infos.component';
import { ModalChooseFolderComponent } from './modals/modal-choose-folder/modal-choose-folder.component';
import { ModalCorrectionChallengeComponent } from './modals/modal-correction-challenge/modal-correction-challenge.component';
import { ModalCourseCustomNotesComponent } from './modals/modal-course-custom-notes/modal-course-custom-notes.component';
import { DisciplineChallengeComponent } from './modals/modal-create-challenge/discipline-challenge/discipline-challenge.component';
import { ModalCreateChallengeComponent } from './modals/modal-create-challenge/modal-create-challenge.component';
import { ModalCreateExamCustomComponent } from './modals/modal-create-exam-custom/modal-create-exam-custom.component';
import { ModalEndCustomExamComponent } from './modals/modal-end-custom-exam/modal-end-custom-exam.component';
import { ModalItemAbbreviationsComponent } from './modals/modal-item-abbreviations/modal-item-abbreviations.component';
import { ModalLastQuestionComponent } from './modals/modal-last-question/modal-last-question.component';
import { ModalLaunchAncrageRevisionsComponent } from './modals/modal-launch-ancrage-revisions/modal-launch-ancrage-revisions.component';
import { ModalLaunchExamComponent } from './modals/modal-launch-exam/modal-launch-exam.component';
import { ModalListFriendsComponent } from './modals/modal-list-friends/modal-list-friends.component';
import { ModalMaintenanceComponent } from './modals/modal-maintenance/modal-maintenance.component';
import { ModalMessageComponent } from './modals/modal-message/modal-message.component';
import { ModalAddTestimonyComponent } from './modals/modal-add-testimony/modal-add-testimony.component';
import { ModalPdfFullscreenComponent } from './modals/modal-pdf-fullscreen/modal-pdf-fullscreen.component';
import { ModalPostFeedbackComponent } from './modals/modal-post-feedback/modal-post-feedback.component';
import { ModalPremiumPlusComponent } from './modals/modal-premium-plus/modal-premium-plus.component';
import { ModalGoPremiumComponent } from './modals/modal-go-premium/modal-go-premium.component';
import { ModalQuestionFeedbackComponent } from './modals/modal-question-feedback/modal-question-feedback.component';
import { ModalListImagesApplicationComponent } from './modals/modal-list-images-application/modal-list-images-application.component';
import { ModalListImagesComponent } from './modals/modal-list-images/modal-list-images.component';
import { ModalRateFolderComponent } from './modals/modal-rate-folder/modal-rate-folder.component';
import { ModalResetPasswordComponent } from './modals/modal-reset-password/modal-reset-password.component';
import { ModalSearchUserComponent } from './modals/modal-search-user/modal-search-user.component';
import { ModalStartMessageGroupComponent } from './modals/modal-start-message-group/modal-start-message-group.component';
import { ModalUpdatePaymentInfosComponent } from './modals/modal-update-payment-infos/modal-update-payment-infos.component';
import { ModalUpdateVersionComponent } from './modals/modal-update-version/modal-update-version.component';
import { ModalUserProfileComponent } from './modals/modal-user-profile/modal-user-profile.component';
import { ModalUsersInMessageGroupComponent } from './modals/modal-users-in-message-group/modal-users-in-message-group.component';
import { ModalViewRevisionComponent } from './modals/modal-view-revision/modal-view-revision.component';
import { ModalWarningComponent } from './modals/modal-warning/modal-warning.component';
import { BlockBodyScrollDirective } from './directives/block-body-scroll.directive';
import { CoursePictureCardComponent } from './components/cards/course-picture-card/course-picture-card.component';
import { VideoCardComponent } from './components/cards/video-card/video-card.component';
import { ModalVideoComponent } from './modals/modal-video/modal-video.component';
import { PricingBlockFeatureComponent } from './components/pricing-premium/pricing-block-feature/pricing-block-feature.component';
import { ModalEliteComponent } from './modals/modal-elite/modal-elite.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { FormFieldSelectWithSearchComponent } from './components/forms/form-field-select-with-search/form-field-select-with-search.component';
import { PricingFeaturesTableSectionComponent } from './components/pricing-premium/pricing-features-table-section/pricing-features-table-section.component';
import { PricingFeaturesTableRowComponent } from './components/pricing-premium/pricing-features-table-row/pricing-features-table-row.component';
import { LandingTestimoniesComponent } from './components/landing-testimonies/landing-testimonies.component';
import { LandingAncrageComponent } from './components/landing-ancrage/landing-ancrage.component';
import { LandingFeedbacksComponent } from './components/landing-feedbacks/landing-feedbacks.component';
import { HeaderExamComponent } from './components/header-exam/header-exam.component';
import { WidgetButtonsHiddenOnTopscrollDirective } from './directives/widget-buttons-hidden-on-topscroll.directive';
import { ModalEndEcosComponent } from './modals/modal-end-ecos/modal-end-ecos.component';
import { FaqFolderDetailComponent } from './components/faq-folder-detail/faq-folder-detail.component';
import { FaqQuestionDiscussionComponent } from './components/faq-folder-detail/faq-question-discussion/faq-question-discussion.component';
import { ModalFeedbacksQuestionComponent } from './modals/modal-feedbacks-question/modal-feedbacks-question.component';
import { FormFieldTextComponent } from './components/forms/form-field-text/form-field-text.component';
import { DisplayFormControlErrorsPipe } from './pipes/display-form-control-errors.pipe';
import { FormFieldPasswordComponent } from './components/forms/form-field-password/form-field-password.component';
import { ButtonComponent } from './components/buttons/button/button.component';
import { FormFieldCheckboxComponent } from './components/forms/form-field-checkbox/form-field-checkbox.component';
import { SvgIconComponent } from './components/svg-icon/svg-icon.component';
import { ModalUpdateUserUsernameComponent } from './modals/modal-update-user-username/modal-update-user-username.component';
import { FormFieldReadonlyComponent } from './components/forms/form-field-readonly/form-field-readonly.component';
import { LoaderInitialComponent } from './components/loaders/loader-initial/loader-initial.component';
import { LoaderListScrollComponent } from './components/loaders/loader-list-scroll/loader-list-scroll.component';
import { NotAvailableOnIosComponent } from './components/not-available-on-ios/not-available-on-ios.component';
import { NoResultsComponent } from './components/no-results/no-results.component';
import { ErrorTooltipComponent } from './components/error-tooltip/error-tooltip.component';
import { FormFieldTextareaComponent } from './components/forms/form-field-textarea/form-field-textarea.component';
import { TopPageComponent } from './components/top-page/top-page.component';
import { TestimoniesGridComponent } from './components/grids/testimonies-grid/testimonies-grid.component';
import { ArticlesGridComponent } from './components/grids/articles-grid/articles-grid.component';
import { RoundCoverImageComponent } from './components/images/round-cover-image/round-cover-image.component';
import { CoverImageComponent } from './components/images/cover-image/cover-image.component';
import { SliderComponent } from './components/slider/slider.component';
import { FormFieldSelectComponent } from './components/forms/form-field-select/form-field-select.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { RouterModule } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { SocialProviderComponent } from './components/social-login/social-provider/social-provider.component';
import { FormControlSlideToggleComponent } from './components/forms/form-control-slide-toggle/form-control-slide-toggle.component';
import { FormFieldSlideToggleComponent } from './components/forms/form-field-slide-toggle/form-field-slide-toggle.component';
import { FormFieldTinyEditorComponent } from './components/forms/form-field-tiny-editor/form-field-tiny-editor.component';
import { FormFieldFileComponent } from './components/forms/form-field-file/form-field-file.component';
import { ArticleTypePipe } from './pipes/enums/article-type.pipe';
import { ExamTypePipe } from './pipes/enums/exam-type.pipe';
import { TestimonyPublicationStatusPipe } from './pipes/publication-status/testimony-publication-status.pipe';
import { FeaturedTestimonyPublicationStatusPipe } from './pipes/publication-status/featured-testimony-publication-status.pipe';
import { RankPipe } from './pipes/rank.pipe';
import { FormFieldSelectTrueFalseComponent } from './components/forms/form-field-select-true-false/form-field-select-true-false.component';
import { UserRolePipe } from './pipes/enums/user-role.pipe';
import { ModalUpdateUserEmailComponent } from './modals/modal-update-user-email/modal-update-user-email.component';
import { FilterUserRolePipe } from './pipes/filters/filter-user-role.pipe';
import { FormFieldTypeaheadComponent } from './components/forms/form-field-typeahead/form-field-typeahead.component';
import { CoursePublicationStatusPipe } from './pipes/publication-status/course-publication-status.pipe';
import { ListPipe } from './pipes/list.pipe';
import { CourseContentTypePipe } from './pipes/enums/course-content-type.pipe';
import { CapitalizeFirstLetterPipe } from './pipes/capitalize-first-letter.pipe';
import { ModalTutoEcosComponent } from './modals/modal-tuto-ecos/modal-tuto-ecos.component';
import { BreadcrumbModule } from 'xng-breadcrumb';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { TypeSafeMatCellDef } from './directives/type-safe-mat-cell-directive';
import { ReceiptStatusPipe } from './pipes/enums/receipt-status.pipe';
import { OfferTierPipe } from './pipes/enums/offer-tier.pipe';
import { AdminBlockDirective } from './directives/styles/admin-block.directive';
import { AdminFormDirective } from './directives/styles/admin-form.directive';
import { NgxMatDatetimePickerModule } from '@angular-material-components/datetime-picker';
import { NgxMatMomentModule } from '@angular-material-components/moment-adapter';
import { FormFieldDateTimePickerComponent } from './components/forms/form-field-date-time-picker/form-field-date-time-picker.component';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { QuestionAnswerMethodPipe } from './pipes/enums/question-answer-method.pipe';
import { QuestionPublicationStatusPipe } from './pipes/publication-status/question-publication-status.pipe';
import { NotionsByCoursesPipe } from './pipes/notions-by-courses.pipe';
import { NotionsByChaptersPipe } from './pipes/notions-by-chapters.pipe';
import { StatementContentTypePipe } from './pipes/enums/statement-content-type.pipe';
import { FolderTypePipe } from './pipes/enums/folder-type.pipe';
import { FolderPublicationStatusPipe } from './pipes/publication-status/folder-publication-status.pipe';
import { ExamPublicationStatusPipe } from './pipes/publication-status/exam-publication-status.pipe';
import { SubscriptionTierPipe } from './pipes/enums/subscription-tier.pipe';
import { LoadingMaskDirective } from './directives/loading-mask.directive';
import { ExamGroupPublicationStatusPipe } from './pipes/publication-status/exam-group-publication-status.pipe';
import { NotificationCampaignTargetedActiveUsersPipe } from './pipes/enums/notification-campaign-targeted-active-users.pipe';
import { NotificationTemplatePipe } from './pipes/enums/notification-template.pipe';
import { FileButtonComponent } from './components/file-button/file-button.component';
import { ModalComponent } from './modals/modal/modal.component';
import { OrderByPriorityPipe } from './pipes/order-by-priority.pipe';
import { FilterNotificationTemplatePipe } from './pipes/filters/filter-notification-template.pipe';
import { ViewRevisionComponent } from './components/view-revision/view-revision.component';
import { FilterLoggedUserPipe } from './pipes/filter-logged-user.pipe';
import { FeedbackContentTypePipe } from './pipes/enums/feedback-content-type.pipe';
import { SubscriptionStatusPipe } from './pipes/enums/subscription-status.pipe';
import { CustomExamCardComponent } from './components/cards/custom-exam-card/custom-exam-card.component';
import { SessionContentSettingPipe } from './pipes/enums/session-content-setting.pipe';
import { SessionSourceSettingPipe } from './pipes/enums/session-source-setting.pipe';
import { WidgetNavigationComponent } from './components/widget-navigation/widget-navigation.component';
import { NotionRankPipe } from './pipes/enums/notion-rank.pipe';
import { ChapterTypePipe } from './pipes/enums/chapter-type.pipe';
import { WidgetTimeRemainingComponent } from './components/widget-time-remaining/widget-time-remaining.component';
import { OffersByTierPipe } from './pipes/offers-by-tier.pipe';
import { TransactionStatusPipe } from './pipes/enums/transaction-status.pipe';
import { PodcastCardComponent } from './components/cards/podcast-card/podcast-card.component';
import { ModalPodcastComponent } from './modals/modal-podcast/modal-podcast.component';
import { WidgetNavigationPanelHeaderComponent } from './components/widget-navigation/widget-navigation-panel-header/widget-navigation-panel-header.component';
import { WidgetNavigationPanelBodyComponent } from './components/widget-navigation/widget-navigation-panel-body/widget-navigation-panel-body.component';
import { ModalLaunchExamContentComponent } from './modals/modal-launch-exam-content/modal-launch-exam-content.component';
import { ModalEndExamContentComponent } from './modals/modal-end-exam-content/modal-end-exam-content.component';
import { StudentsRankingFiltersComponent } from './components/ranking/students-ranking-filters/students-ranking-filters.component';
import { StudentRankingRowComponent } from './components/ranking/students-ranking-table/student-ranking-row/student-ranking-row.component';
import { StudentsRankingTableComponent } from './components/ranking/students-ranking-table/students-ranking-table.component';
import { UniversitiesRankingTableComponent } from './components/ranking/universities-ranking-table/universities-ranking-table.component';
import { ExamTypeColorPipe } from './pipes/enums/exam-type-color.pipe';
import { UserProfileComponent } from './components/user-profile/user-profile.component';
import { ChallengeBadgesComponent } from './components/user-profile/challenge-badges/challenge-badges.component';
import { SpecialtyCardComponent } from './components/cards/specialty-card/specialty-card.component';
import { CourseConftubesComponent } from './components/course-conftubes/course-conftubes.component';
import { EcosActionTypePipe } from './pipes/enums/ecos-action-type.pipe';
import { ModalSetupTrainingComponent } from './modals/modal-setup-training/modal-setup-training.component';
import { ObserveVisibilityDirective } from './directives/observe-visibility.directive';
import { SessionDifficultySettingPipe } from './pipes/enums/session-difficulty-setting.pipe';
import { SessionPediatricSettingPipe } from './pipes/enums/session-pediatric-setting.pipe';
import { RemoveClassPipe } from './pipes/remove-class.pipe';
import { RememberingLevelPipe } from './pipes/enums/remembering-level.pipe';
import { FilterNotionRankPipe } from './pipes/filters/filter-notion-rank.pipe';
import { NotionRankComponent } from './components/notion-rank/notion-rank.component';
import { NotionRankColorPipe } from './pipes/notion-rank-color.pipe';
import { FilterQuestionPublicationStatusPipe } from './pipes/filters/filter-question-publication-status.pipe';
import { CorrectionStatusPipe } from './pipes/enums/correction-status.pipe';
import { SessionStatusPipe } from './pipes/enums/session-status.pipe';
import { ExamDifficultyPipe } from './pipes/enums/exam-difficulty.pipe';
import { LottieModule } from 'ngx-lottie';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    TranslateModule,
    MomentModule,
    MatDialogModule,
    MatTooltipModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatNativeDateModule,
    MatInputModule,
    MatRadioModule,
    MatSnackBarModule,
    MatRippleModule,
    MatSelectModule,
    MatCheckboxModule,
    MatExpansionModule,
    MatListModule,
    NgxUsefulSwiperModule,
    MatBadgeModule,
    MatSidenavModule,
    AngularSvgIconModule,
    MatTabsModule,
    NgxExtendedPdfViewerModule,
    MatIconModule,
    MatSlideToggleModule,
    MatButtonToggleModule,
    MatStepperModule,
    EditorModule,
    NgxMatSelectSearchModule,
    MatPaginatorModule,
    MatTableModule,
    MatSortModule,
    BreadcrumbModule,
    DragDropModule,
    NgxMatDatetimePickerModule,
    NgxMatMomentModule,
    LottieModule,
  ],
  declarations: [
    ExamCardComponent,
    TrainingDisciplineCardComponent,
    TrainingItemCardComponent,
    ProgressAncrageTrainingCardComponent,
    ProgressCustomTrainingCardComponent,
    FeaturedTestimoniesSliderComponent,
    CopyRestrictedDirective,
    Utf8EncodePipe,
    SafePipe,
    StarsGradeComponent,
    SiteLogoComponent,
    OvalHeaderCardComponent,
    CourseCardComponent,
    DashboardAncrageCardComponent,
    SpecialtyCardComponent,
    MultirankCardComponent,
    ArticleCardComponent,
    QuestionCandidateFormComponent,
    QuestionCorrectionFormComponent,
    SocialLoginComponent,
    NotFoundComponent,
    HistogramComponent,
    PdfViewerComponent,
    InfiniteScrollDirective,
    ModalAncrageTodayComponent,
    AccordionTableDirective,
    ModalListImagesComponent,
    ModalListImagesApplicationComponent,
    ModalLastQuestionComponent,
    ModalLaunchExamComponent,
    ModalGoPremiumComponent,
    ModalUpdatePaymentInfosComponent,
    ModalMaintenanceComponent,
    ModalAcceptNotificationsComponent,
    ModalAncrageInfosComponent,
    ModalPremiumPlusComponent,
    ModalItemAbbreviationsComponent,
    ModalUpdateUserEmailComponent,
    AddAbbreviationsPipe,
    StripTagsPipe,
    PricingPremiumComponent,
    BaseComponent,
    TopSearchComponent,
    WidgetProgressionComponent,
    SubscriptionCardComponent,
    ImageContentComponent,
    TextContentComponent,
    ModalCreateChallengeComponent,
    FriendInviteComponent,
    CountdownPipe,
    ModalChallengeBadgeInfosComponent,
    ModalUserProfileComponent,
    UserProfileComponent,
    ModalListFriendsComponent,
    StudentThumbnailComponent,
    ChallengeDescriptionComponent,
    ChallengeMajorComponent,
    ModalSearchUserComponent,
    DisciplineChallengeComponent,
    ModalStartMessageGroupComponent,
    ChooseFriendsComponent,
    TestimonyCardComponent,
    QuestionContentComponent,
    QuestionImagesComponent,
    QuestionHeaderComponent,
    QuestionCorrectionTopComponent,
    ModalEndCustomExamComponent,
    CollapsibleElementDirective,
    GradientHiddenDirective,
    ModalCandidateChallengeComponent,
    IntroCandidateChallengeComponent,
    IntroQuestionChallengeComponent,
    QuestionChallengeComponent,
    EndChallengeComponent,
    ModalCorrectionChallengeComponent,
    UserSunrayComponent,
    ModalViewRevisionComponent,
    ModalUpdateVersionComponent,
    ModalPostFeedbackComponent,
    ModalWarningComponent,
    ModalLaunchAncrageRevisionsComponent,
    ModalResetPasswordComponent,
    ModalCreateExamCustomComponent,
    ModalMessageComponent,
    LoaderBookComponent,
    LoaderDotsComponent,
    LoaderHashtagComponent,
    LoaderInfiniteScrollComponent,
    FolderDoneCardComponent,
    ModalUsersInMessageGroupComponent,
    ActionButtonsComponent,
    ModalCourseCustomNotesComponent,
    ModalChooseFolderComponent,
    ModalPdfFullscreenComponent,
    PanelDirective,
    BaseDirective,
    ModalQuestionFeedbackComponent,
    ParagraphizePipe,
    NotionsListComponent,
    ModalAddTestimonyComponent,
    ChallengeActualitiesComponent,
    FeaturedTestimonyCardComponent,
    ArticleContentComponent,
    ModalRateFolderComponent,
    BlockBodyScrollDirective,
    CoursePictureCardComponent,
    VideoCardComponent,
    ModalVideoComponent,
    PricingBlockFeatureComponent,
    ModalEliteComponent,
    FormFieldSelectWithSearchComponent,
    PricingFeaturesTableSectionComponent,
    PricingFeaturesTableRowComponent,
    LandingTestimoniesComponent,
    LandingAncrageComponent,
    LandingFeedbacksComponent,
    HeaderExamComponent,
    WidgetButtonsHiddenOnTopscrollDirective,
    ModalEndEcosComponent,
    FaqFolderDetailComponent,
    FaqQuestionDiscussionComponent,
    ModalFeedbacksQuestionComponent,
    FormFieldTextComponent,
    DisplayFormControlErrorsPipe,
    FormFieldPasswordComponent,
    ButtonComponent,
    FormFieldCheckboxComponent,
    SvgIconComponent,
    ModalUpdateUserUsernameComponent,
    FormFieldReadonlyComponent,
    LoaderInitialComponent,
    LoaderListScrollComponent,
    NotAvailableOnIosComponent,
    NoResultsComponent,
    ErrorTooltipComponent,
    FormFieldTextareaComponent,
    TopPageComponent,
    TestimoniesGridComponent,
    ArticlesGridComponent,
    RoundCoverImageComponent,
    CoverImageComponent,
    SliderComponent,
    FormFieldSelectComponent,
    PaginationComponent,
    SocialProviderComponent,
    FormControlSlideToggleComponent,
    FormFieldSlideToggleComponent,
    CapitalizeFirstLetterPipe,
    FormFieldTinyEditorComponent,
    FormFieldFileComponent,
    ArticleTypePipe,
    OfferTierPipe,
    ExamTypePipe,
    ExamTypeColorPipe,
    FeaturedTestimonyPublicationStatusPipe,
    TestimonyPublicationStatusPipe,
    RankPipe,
    FormFieldSelectTrueFalseComponent,
    UserRolePipe,
    FilterUserRolePipe,
    FormFieldTypeaheadComponent,
    CoursePublicationStatusPipe,
    FolderPublicationStatusPipe,
    ListPipe,
    CourseContentTypePipe,
    CapitalizeFirstLetterPipe,
    ModalTutoEcosComponent,
    TypeSafeMatCellDef,
    ReceiptStatusPipe,
    AdminBlockDirective,
    AdminFormDirective,
    FormFieldDateTimePickerComponent,
    ProgressBarComponent,
    QuestionAnswerMethodPipe,
    QuestionPublicationStatusPipe,
    NotionsByCoursesPipe,
    NotionsByChaptersPipe,
    StatementContentTypePipe,
    FolderTypePipe,
    ExamPublicationStatusPipe,
    SubscriptionTierPipe,
    LoadingMaskDirective,
    ExamGroupPublicationStatusPipe,
    NotificationCampaignTargetedActiveUsersPipe,
    NotificationTemplatePipe,
    FileButtonComponent,
    ModalComponent,
    OrderByPriorityPipe,
    FilterNotificationTemplatePipe,
    ViewRevisionComponent,
    FilterLoggedUserPipe,
    FeedbackContentTypePipe,
    SubscriptionStatusPipe,
    CustomExamCardComponent,
    SessionContentSettingPipe,
    SessionSourceSettingPipe,
    WidgetNavigationComponent,
    NotionRankPipe,
    ChapterTypePipe,
    WidgetTimeRemainingComponent,
    OffersByTierPipe,
    TransactionStatusPipe,
    PodcastCardComponent,
    ModalPodcastComponent,
    WidgetNavigationPanelHeaderComponent,
    WidgetNavigationPanelBodyComponent,
    ModalLaunchExamContentComponent,
    ModalEndExamContentComponent,
    UniversitiesRankingTableComponent,
    StudentsRankingTableComponent,
    StudentRankingRowComponent,
    StudentsRankingFiltersComponent,
    ChallengeBadgesComponent,
    CourseConftubesComponent,
    EcosActionTypePipe,
    ModalSetupTrainingComponent,
    ObserveVisibilityDirective,
    SessionDifficultySettingPipe,
    SessionPediatricSettingPipe,
    RemoveClassPipe,
    RememberingLevelPipe,
    FilterNotionRankPipe,
    NotionRankComponent,
    NotionRankColorPipe,
    FilterQuestionPublicationStatusPipe,
    CorrectionStatusPipe,
    SessionStatusPipe,
    ExamDifficultyPipe,
  ],
  providers: [{ provide: MAT_DATE_LOCALE, useValue: environment.locale }, StripTagsPipe, Title],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    MomentModule,
    ExamCardComponent,
    TrainingDisciplineCardComponent,
    TrainingItemCardComponent,
    ProgressAncrageTrainingCardComponent,
    ProgressCustomTrainingCardComponent,
    FeaturedTestimoniesSliderComponent,
    CopyRestrictedDirective,
    Utf8EncodePipe,
    SafePipe,
    StarsGradeComponent,
    SiteLogoComponent,
    MatDialogModule,
    MatTooltipModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatNativeDateModule,
    MatInputModule,
    MatRadioModule,
    MatSnackBarModule,
    MatRippleModule,
    MatSelectModule,
    MatCheckboxModule,
    MatExpansionModule,
    MatListModule,
    OvalHeaderCardComponent,
    CourseCardComponent,
    DashboardAncrageCardComponent,
    SpecialtyCardComponent,
    MultirankCardComponent,
    ArticleCardComponent,
    NgxUsefulSwiperModule,
    QuestionCandidateFormComponent,
    QuestionCorrectionFormComponent,
    SocialLoginComponent,
    NotFoundComponent,
    HistogramComponent,
    PdfViewerComponent,
    InfiniteScrollDirective,
    AccordionTableDirective,
    MatBadgeModule,
    MatSidenavModule,
    AddAbbreviationsPipe,
    AngularSvgIconModule,
    StripTagsPipe,
    MatTabsModule,
    PricingPremiumComponent,
    BaseComponent,
    TopSearchComponent,
    WidgetProgressionComponent,
    SubscriptionCardComponent,
    ImageContentComponent,
    TextContentComponent,
    CountdownPipe,
    UserProfileComponent,
    StudentThumbnailComponent,
    ChallengeDescriptionComponent,
    ChallengeMajorComponent,
    ChooseFriendsComponent,
    TestimonyCardComponent,
    QuestionContentComponent,
    QuestionImagesComponent,
    QuestionHeaderComponent,
    QuestionCorrectionTopComponent,
    CollapsibleElementDirective,
    GradientHiddenDirective,
    UserSunrayComponent,
    NgxExtendedPdfViewerModule,
    ModalViewRevisionComponent,
    ModalUpdateVersionComponent,
    ModalPostFeedbackComponent,
    ModalWarningComponent,
    ModalLaunchAncrageRevisionsComponent,
    ModalResetPasswordComponent,
    ModalCreateExamCustomComponent,
    ModalMessageComponent,
    MatIconModule,
    LoaderBookComponent,
    LoaderDotsComponent,
    LoaderHashtagComponent,
    LoaderInfiniteScrollComponent,
    MatSlideToggleModule,
    MatButtonToggleModule,
    MatStepperModule,
    FolderDoneCardComponent,
    ActionButtonsComponent,
    EditorModule,
    PanelDirective,
    ParagraphizePipe,
    NotionsListComponent,
    ChallengeActualitiesComponent,
    ArticleContentComponent,
    BlockBodyScrollDirective,
    CoursePictureCardComponent,
    VideoCardComponent,
    PricingBlockFeatureComponent,
    NgxMatSelectSearchModule,
    FormFieldSelectWithSearchComponent,
    LandingTestimoniesComponent,
    LandingAncrageComponent,
    LandingFeedbacksComponent,
    HeaderExamComponent,
    WidgetButtonsHiddenOnTopscrollDirective,
    FaqFolderDetailComponent,
    FaqQuestionDiscussionComponent,
    FormFieldTextComponent,
    DisplayFormControlErrorsPipe,
    FormFieldPasswordComponent,
    ButtonComponent,
    FormFieldCheckboxComponent,
    SvgIconComponent,
    FormFieldReadonlyComponent,
    LoaderInitialComponent,
    LoaderListScrollComponent,
    NotAvailableOnIosComponent,
    NoResultsComponent,
    ErrorTooltipComponent,
    FormFieldTextareaComponent,
    TopPageComponent,
    TestimoniesGridComponent,
    ArticlesGridComponent,
    RoundCoverImageComponent,
    CoverImageComponent,
    SliderComponent,
    FormFieldSelectComponent,
    PaginationComponent,
    MatTableModule,
    MatSortModule,
    FormControlSlideToggleComponent,
    FormFieldSlideToggleComponent,
    CapitalizeFirstLetterPipe,
    FormFieldTinyEditorComponent,
    FormFieldFileComponent,
    ArticleTypePipe,
    OfferTierPipe,
    ExamTypePipe,
    ExamTypeColorPipe,
    FeaturedTestimonyPublicationStatusPipe,
    TestimonyPublicationStatusPipe,
    RankPipe,
    FormFieldSelectTrueFalseComponent,
    UserRolePipe,
    FilterUserRolePipe,
    FormFieldTypeaheadComponent,
    CoursePublicationStatusPipe,
    FolderPublicationStatusPipe,
    ListPipe,
    CourseContentTypePipe,
    BreadcrumbModule,
    DragDropModule,
    TypeSafeMatCellDef,
    ReceiptStatusPipe,
    AdminBlockDirective,
    AdminFormDirective,
    NgxMatDatetimePickerModule,
    NgxMatMomentModule,
    FormFieldDateTimePickerComponent,
    ProgressBarComponent,
    QuestionAnswerMethodPipe,
    QuestionPublicationStatusPipe,
    NotionsByCoursesPipe,
    NotionsByChaptersPipe,
    StatementContentTypePipe,
    FolderTypePipe,
    ExamPublicationStatusPipe,
    SubscriptionTierPipe,
    LoadingMaskDirective,
    ExamGroupPublicationStatusPipe,
    NotificationCampaignTargetedActiveUsersPipe,
    NotificationTemplatePipe,
    FileButtonComponent,
    ModalComponent,
    OrderByPriorityPipe,
    FilterNotificationTemplatePipe,
    ViewRevisionComponent,
    FilterLoggedUserPipe,
    FeedbackContentTypePipe,
    SubscriptionStatusPipe,
    CustomExamCardComponent,
    SessionContentSettingPipe,
    SessionSourceSettingPipe,
    WidgetNavigationComponent,
    NotionRankPipe,
    ChapterTypePipe,
    WidgetTimeRemainingComponent,
    OffersByTierPipe,
    TransactionStatusPipe,
    PodcastCardComponent,
    UniversitiesRankingTableComponent,
    StudentsRankingTableComponent,
    StudentRankingRowComponent,
    StudentsRankingFiltersComponent,
    CourseConftubesComponent,
    EcosActionTypePipe,
    ObserveVisibilityDirective,
    SessionDifficultySettingPipe,
    SessionPediatricSettingPipe,
    RemoveClassPipe,
    RememberingLevelPipe,
    FilterNotionRankPipe,
    NotionRankComponent,
    NotionRankColorPipe,
    FilterQuestionPublicationStatusPipe,
    CorrectionStatusPipe,
    SessionStatusPipe,
    ExamDifficultyPipe,
    LottieModule,
  ],
})
export class SharedModule {}
