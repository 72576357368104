import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { LocaleKeys } from '../../shared/utils/locale-keys';

@Injectable()
export class SetHeadersInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let headers = request.headers.append('Accept', ['application/json', 'application/ld+json']);
    return next.handle(request.clone({ headers }));
  }
}
