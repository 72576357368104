<div class="user-image-sunray">
  <div
    class="sunray"
    [class.cursor-pointer]="student?.picture && !introChallengeMode"
    (click)="openModalImage()"
  ></div>
  <app-round-cover-image
    [src]="canChangePhoto ? (loggedStudent$ | async).picture : student?.picture"
    [size]="184"
  ></app-round-cover-image>

  <ng-container *ngIf="languageService.challengeEnabled">
    <div class="user-position" *ngIf="challengeScore?.rank && challengeScore?.score">
      <svg-icon
        src="assets/images/svg-icons/icon-medal.svg"
        [svgStyle]="{ 'width.px': '13' }"
      ></svg-icon>
      {{ challengeScore.rank | number : '1.0-0' : 'fr' }}
    </div>
    <div class="user-badges" *ngIf="challengeSpecialtyScoreBadges">
      <div class="user-badge" *ngFor="let badge of challengeSpecialtyScoreBadges">
        <img src="assets/images/svg-illustrations/icon-challenge-{{ badge.level }}.svg" />
      </div>
    </div>
  </ng-container>

  <!-- nnkitodo [v2later] update de la photo affichée avec loggedStudent$ plus propre que le hack -->
  <div class="user-change-photo" *ngIf="canChangePhoto">
    <input
      *ngIf="!isApplicationService.isApplication()"
      (change)="avatarService.uploadAvatarDesktop($event)"
      type="file"
      name="avatar"
      class="file-input"
      id="avatar"
      accept=".jpg,.png,.jpeg"
    />

    <label
      *ngIf="!avatarService.loadingUpload"
      (click)="isApplicationService.isApplication() ? avatarService.uploadAvatarMobile() : null"
      matRipple
      [matRippleCentered]="false"
      [matRippleUnbounded]="true"
      [matRippleRadius]="50"
      class="btn-nnki btn-nnki-circular -no-border"
      for="avatar"
    >
      <svg-icon
        src="assets/images/svg-icons/icon-camera.svg"
        [svgStyle]="{ 'width.px': '23' }"
      ></svg-icon>
    </label>

    <app-loader-dots *ngIf="avatarService.loadingUpload"></app-loader-dots>
  </div>
</div>

<div class="user-infos">
  <ng-container *ngIf="student">
    <div class="user-username">
      {{ student.username }}
    </div>
    <div class="user-fac">{{ student.university.name }} - {{ student.graduationName.name }}</div>

    <div *ngIf="languageService.challengeEnabled && challengeScore?.score" class="user-points">
      {{ (challengeScore.score | number : '1.0-0' : 'fr') + ('examens.pts' | translate) }}
    </div>
  </ng-container>

  <div class="spinner_element_container" *ngIf="!student">
    <app-loader-dots></app-loader-dots>
  </div>
</div>
