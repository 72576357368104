import { throwError } from 'rxjs';

export interface ApiError {
  code?: number;
  message: string;
}

export function convertHttpErrorToApiError(error) {
  if (error.error?.code && error.error?.message) {
    return convertSimpleHttpErrorToApiError(error);
  } else if (error.error?.status && error.error?.detail) {
    return convertSimpleHttpError2ToApiError(error);
  } else if (error.status && error.error?.['hydra:description']) {
    return convertComplexHttpErrorToApiError(error);
  } else if (error.error?.status && error.error?.detail) {
    return convertUncatchedHttpErrorToApiError(error);
  } else if (error.status && error.message) {
    return {
      code: error.status,
      message: error.message,
    };
  } else {
    return {
      code: '',
      message: error,
    };
  }
}

function convertSimpleHttpErrorToApiError(error) {
  return {
    code: error.error.code,
    message: error.error.message,
  };
}

function convertSimpleHttpError2ToApiError(error) {
  return {
    code: error.error.status,
    message: error.error.detail,
  };
}

function convertComplexHttpErrorToApiError(error) {
  return {
    code: error.status,
    message: error.error['hydra:description'].replace('\n', '<br>'),
  };
}

function convertUncatchedHttpErrorToApiError(error) {
  return {
    code: error.error.status,
    message: error.error.detail,
  };
}

export function throw404Error() {
  return throwError(() => {
    return {
      status: 404,
      message: `Not found`,
    };
  });
}
